import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import VerifiedBadge from "../Handlers/VerifiedBadge";
import { translate, t } from "react-multi-lang";

const HomePageSuggesstion = (props) => {

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 100,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  // };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    vertical: true,
    arrow: true,
    verticalSwiping: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1195,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Col xl={12} md={12} className="suggest-col">
      <Row>
        <Col sm={12} xl={5} xs={12} md={6} className="mrg-btm-xs">
          <h4 className="suggestions">{t("suggestions")}</h4>
        </Col>
        <Col sm={12} xl={7} xs={12} md={6} className="mrg-btm-xs">
          <div className="controls pull-right ">
            {/* <Link to="#">
              <Image
                src="assets/images/icons/price-tag.svg"
                className="svg-clone"
              />
            </Link>
            <Link to="#">
              <Image
                src="assets/images/icons/refresh.svg"
                className="svg-clone"
              />
            </Link> */}

            {/** <Link
              className="left"
              data-toggle="tooltip"
              data-placement="top"
              title="Prev"
              to="#carousel-example"
              data-slide="prev"
            >
              <Image src="assets/images/icons/left.svg" className="svg-clone" />
            </Link>
            <Link
              className="right"
              data-toggle="tooltip"
              data-placement="top"
              title="Next"
              to="#carousel-example"
              data-slide="next"
            >
              <Image
                src="assets/images/icons/right.svg"
                className="svg-clone mr-0 padding-right-zero"
              />
            </Link> */}
          </div>
        </Col>
        <Col sm={12} xl={12} xs={12} md={12}>
          <div
          // id="carousel-example"
          // className="carousel slide"
          // data-ride="carousel"
          >
            <div className="suggestion-slider">
              <div className="item active">
                <Row className="diplay-block">
                  
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToPros = (state) => ({
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(HomePageSuggesstion));
