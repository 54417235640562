import {
    LIVE_VIDEO_CALL_BROADCAST_START,
    LIVE_VIDEO_CALL_BROADCAST_SUCCESS,
    LIVE_VIDEO_CALL_BROADCAST_FAILURE,
    FETCH_LIVE_VIDEOS_START,
    FETCH_LIVE_VIDEOS_SUCCESS,
    FETCH_LIVE_VIDEOS_FAILURE,
    FETCH_LIVE_VIDEOS_HISTORY_START,
    FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
    FETCH_LIVE_VIDEOS_HISTORY_SUCCESS,
    FETCH_LIVE_VIDEOS_HISTORY_FAILURE,
    JOIN_LIVE_VIDEOS_START,
    JOIN_LIVE_VIDEOS_SUCCESS,
    JOIN_LIVE_VIDEOS_FAILURE,
    FETCH_SINGLE_LIVE_VIDEOS_START,
    FETCH_SINGLE_LIVE_VIDEOS_SUCCESS,
    FETCH_SINGLE_LIVE_VIDEOS_FAILURE,
    LIVE_VIDEOS_PAYMENT_BY_STRIPE_START,
    LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS,
    LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE,
    LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START,
    LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS,
    LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE,
    LIVE_VIDEOS_VIEWER_UPDATE_START,
    LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS,
    LIVE_VIDEOS_VIEWER_UPDATE_FAILURE,
    LIVE_VIDEOS_END_START,
    LIVE_VIDEOS_END_SUCCESS,
    LIVE_VIDEOS_END_FAILURE,
    LIVE_STREAMINGS_ONGOING_START,
    LIVE_STREAMINGS_ONGOING_SUCCESS,
    LIVE_STREAMINGS_ONGOING_FAILURE,
    UPCOMMING_LIVE_STREAMINGS_START,
    UPCOMMING_LIVE_STREAMINGS_SUCCESS,
    UPCOMMING_LIVE_STREAMINGS_FAILURE,
    FETCH_LIVE_VIDEO_MESSAGE_START,
    FETCH_LIVE_VIDEO_MESSAGE_SUCCESS,
    FETCH_LIVE_VIDEO_MESSAGE_FAILURE,
    ADD_LIVE_VIDEO_MESSAGE_CONTENT,
    FETCH_LIVE_VIDEOS_PRODUCTS_START,
    FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS,
    FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE,
    FETCH_LIVE_VIDEO_ORDERS_START,
    FETCH_LIVE_VIDEO_ORDERS_SUCCESS,
    FETCH_LIVE_VIDEO_ORDERS_FAILURE,
    FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START,
    FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS,
    FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_FAILURE,
    LIVE_VIDEOS_START_CALL_START,
    LIVE_VIDEOS_START_CALL_SUCCESS,
    LIVE_VIDEOS_START_CALL_FAILURE,
} from "./ActionConstant";

export function videoCallBroadcastStart(data) {
    return {
        type: LIVE_VIDEO_CALL_BROADCAST_START,
        data,
    };
}

export function videoCallBroadcastSuccess(data) {
    return {
        type: LIVE_VIDEO_CALL_BROADCAST_SUCCESS,
        data,
    };
}

export function videoCallBroadcastFailure(error) {
    return {
        type: LIVE_VIDEO_CALL_BROADCAST_FAILURE,
        error,
    };
}

export function liveVideoStartCallStart(data) {
    return {
        type: LIVE_VIDEOS_START_CALL_START,
        data,
    };
}

export function liveVideoStartCallSuccess(data) {
    return {
        type: LIVE_VIDEOS_START_CALL_SUCCESS,
        data,
    };
}

export function liveVideoStartCallFailure(error) {
    return {
        type: LIVE_VIDEOS_START_CALL_FAILURE,
        error,
    };
}

export function fetchLiveVideosStart(data) {
    return {
        type: FETCH_LIVE_VIDEOS_START,
        data,
    };
}

export function fetchLiveVideosSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEOS_SUCCESS,
        data,
    };
}

export function fetchLiveVideosFailure(error) {
    return {
        type: FETCH_LIVE_VIDEOS_FAILURE,
        error,
    };
}

export function fetchLiveVideosHistoryStart(data) {
    return {
        type: FETCH_LIVE_VIDEOS_HISTORY_START,
        data,
    };
}

export function fetchMoreLiveVideosHistoryStart(data) {
    return {
        type: FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
        data,
    };
}

export function fetchLiveVideosHistorySuccess(data) {
    return {
        type: FETCH_LIVE_VIDEOS_HISTORY_SUCCESS,
        data,
    };
}

export function fetchLiveVideosHistoryFailure(error) {
    return {
        type: FETCH_LIVE_VIDEOS_HISTORY_FAILURE,
        error,
    };
}


export function fetchLiveVideosScheduledOwnerStart(data) {
    return {
        type: FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START,
        data,
    };
}

export function fetchLiveVideosScheduledOwnerSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS,
        data,
    };
}

export function fetchLiveVideosScheduledOwnerFailure(error) {
    return {
        type: FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_FAILURE,
        error,
    };
}

export function joinLiveVideosStart(data) {
    return {
        type: JOIN_LIVE_VIDEOS_START,
        data,
    };
}

export function joinLiveVideosSuccess(data) {
    return {
        type: JOIN_LIVE_VIDEOS_SUCCESS,
        data,
    };
}

export function joinLiveVideosFailure(error) {
    return {
        type: JOIN_LIVE_VIDEOS_FAILURE,
        error,
    };
}



export function fetchSingleLiveVideoStart(data) {
    return {
        type: FETCH_SINGLE_LIVE_VIDEOS_START,
        data,
    };
}

export function fetchSingleLiveVideoSuccess(data) {
    return {
        type: FETCH_SINGLE_LIVE_VIDEOS_SUCCESS,
        data,
    };
}

export function fetchSingleLiveVideoFailure(error) {
    return {
        type: FETCH_SINGLE_LIVE_VIDEOS_FAILURE,
        error,
    };
}

export function livePaymentPaypalStart(data) {
    return {
        type: LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START,
        data,
    };
}

export function livePaymentPaypalSuccess(data) {
    return {
        type: LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS,
        data,
    };
}

export function livePaymentPaypalFailure(error) {
    return {
        type: LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE,
        error,
    };
}

export function livePaymentStripeStart(data) {
    return {
        type: LIVE_VIDEOS_PAYMENT_BY_STRIPE_START,
        data,
    };
}

export function livePaymentStripeSuccess(data) {
    return {
        type: LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS,
        data,
    };
}

export function livePaymentStripeFailure(error) {
    return {
        type: LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE,
        error,
    };
}

export function liveViewerUpdateStart(data) {
    return {
        type: LIVE_VIDEOS_VIEWER_UPDATE_START,
        data,
    };
}

export function liveViewerUpdateSuccess(data) {
    return {
        type: LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS,
        data,
    };
}

export function liveViewerUpdateFailure(error) {
    return {
        type: LIVE_VIDEOS_VIEWER_UPDATE_FAILURE,
        error,
    };
}


export function liveVideoEndStart(data) {
    return {
        type: LIVE_VIDEOS_END_START,
        data,
    };
}

export function liveVideoEndSuccess(data) {
    return {
        type: LIVE_VIDEOS_END_SUCCESS,
        data,
    };
}

export function liveVideoEndFailure(error) {
    return {
        type: LIVE_VIDEOS_END_FAILURE,
        error,
    };
}

export function fetchLiveStreamingsOngoingStart(data) {
    return {
        type: LIVE_STREAMINGS_ONGOING_START,
        data,
    };
}

export function fetchLiveStreamingsOngoingSucccess(data) {
    return {
        type: LIVE_STREAMINGS_ONGOING_SUCCESS,
        data,
    }
}

export function fetchLiveStreamsOngoingFailure(error) {
    return {
        type: LIVE_STREAMINGS_ONGOING_FAILURE,
        error,
    }
}

export function fetchUpcommingLiveStreamingsStart(data) {
    return {
        type: UPCOMMING_LIVE_STREAMINGS_START,
        data,
    }
}

export function fetchUpcommingLiveStreamingsSuccess(data) {
    return {
        type: UPCOMMING_LIVE_STREAMINGS_SUCCESS,
        data,
    }
}

export function fetchUpcommingLiveStreamingsFailure(error) {
    return {
        type: UPCOMMING_LIVE_STREAMINGS_FAILURE,
        error,
    }
}


export function fetchLiveVideoMessageStart(data) {
    return {
        type: FETCH_LIVE_VIDEO_MESSAGE_START,
        data,
    };
}
  
export function fetchLiveVideoMessageSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEO_MESSAGE_SUCCESS,
        data,
    };
  }
  
export function fetchLiveVideoMessageFailure(error) {
    return {
        type: FETCH_LIVE_VIDEO_MESSAGE_FAILURE,
        error,
    };
  }
  
export function addLiveVideoMessageContent(data) {
    return {
        type: ADD_LIVE_VIDEO_MESSAGE_CONTENT,
        data,
    };
}

export function fetchLiveVideosProductsStart(data) {
    return {
        type: FETCH_LIVE_VIDEOS_PRODUCTS_START,
        data,
    };
}

export function fetchLiveVideosProductsSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS,
        data,
    };
}

export function fetchLiveVideosProductsFailure(error) {
    return {
        type: FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE,
        error,
    };
}


export function fetchLiveVideoOrdersStart(data) {
    return {
        type: FETCH_LIVE_VIDEO_ORDERS_START,
        data,
    }
}
  
export function fetchLiveVideoOrdersSuccess(data) {
    return {
        type: FETCH_LIVE_VIDEO_ORDERS_SUCCESS,
        data,
    }
}
  
export function fetchLiveVideoOrdersFailure(error) {
    return {
        type: FETCH_LIVE_VIDEO_ORDERS_FAILURE,
        error,
    }
}
