import React, { useState,useEffect } from "react";
import { Media, Image, Tab, Row, Col, Nav, Form, Button} from "react-bootstrap";
import "./VideoCall.css";
import { Link } from "react-router-dom";
import AgoraLive from "../Sample/AgoraLive";
import {
    fetchSingleLiveVideoStart,
    fetchLiveVideoMessageStart,
    fetchLiveVideosProductsStart,
} from "../../store/actions/LiveVideoAction";

import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import LiveVideoChat from "./LiveVideoChat";
import ProductsList from "./ProductsList";
import config from "react-global-configuration";
import io from "socket.io-client";
import LiveSample from "./LiveSample";

let chatSocket;

const VideoCallLatest = (props) => {

    const [activeSec, setActiveSec] = useState("available");

    const [username, setUsername] = useState("");

    useEffect(() => {
        if (localStorage.getItem("username") != null) {
            setUsername(localStorage.getItem("username"));
        }
        props.dispatch(
            fetchSingleLiveVideoStart({
                live_video_unique_id: props.match.params.live_video_unique_id,
            })
        );
        props.dispatch(
            fetchLiveVideoMessageStart({
                live_video_unique_id: props.match.params.live_video_unique_id,
            })
        );
        props.dispatch(
            fetchLiveVideosProductsStart({
                live_video_unique_id: props.match.params.live_video_unique_id,
            })
        );
    }, []);

    
    const setActiveSection = (event, key) => {
        setActiveSec(key);
        props.dispatch(
            fetchLiveVideosProductsStart({
                live_video_unique_id: props.match.params.live_video_unique_id,
                type: key
            })
        );
    };

    useEffect(() => {
        if(props.liveVideo.data) {
            chatSocketConnect();
        }
    }, [!props.liveVideo.loading]);

    const chatSocketConnect = () => {
        // check the socket url is configured
        let chatSocketUrl = config.get("configData.chat_socket_url");
        if (chatSocketUrl && props.liveVideo.data) {
            chatSocket = io(chatSocketUrl, {
                query: `room: '` + props.liveVideo.data.virtual_id + `'`,
            });
            
            chatSocket.on("livevideo buynow", (newData) => {
                props.dispatch(
                    fetchLiveVideosProductsStart({
                        live_video_unique_id: props.match.params.live_video_unique_id,
                    })
                );
            });
            
            chatSocket.on("livevideo viewerupdate", (newData) => {
                console.log('livevideo viewerupdate');
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div classname="video-call-latest-sec">

                <div className="app-container">
                    <ProductsList liveVideosProducts={props.liveVideosProducts} liveVideo={props.liveVideo} setActiveSection={setActiveSection}/>
                    {props.liveVideo.loading ? (
                        "Loading..."
                    ) : props.liveVideo.data ? 
                        props.liveVideo.data.live_schedule_type == 1 ?
                            <AgoraLive 
                                liveVideoDetails={props.liveVideo.data}
                                isOwner={props.liveVideo.data.is_owner}
                            />
                        : 
                            <LiveSample 
                                liveVideoDetails={props.liveVideo.data}
                                isOwner={props.liveVideo.data.is_owner}
                            />
                    :(
                        ""
                    )}
                    {props.liveVideoChat.loading ? (
                        "Loading..."
                    ) : props.liveVideoChat.data && props.liveVideo.data ? (
                        <LiveVideoChat
                            loadingChatData ={props.liveVideoChat.loading}
                            chatMessages={props.liveVideoChat}
                            liveVideoData={props.liveVideo.data}
                        /> 
                    ) : (
                        ""
                    )} 
                </div>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    liveVideo: state.liveVideo.singleLiveVideo,
    liveVideoChat: state.liveVideo.liveVideoChat,
    liveVideosProducts: state.liveVideo.liveVideosProducts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(VideoCallLatest));
