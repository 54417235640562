import React, { useEffect, useState, useRef } from "react";
import { translate, t } from "react-multi-lang";
import io from "socket.io-client";
import config from "react-global-configuration";
import { connect } from "react-redux";
import { addLiveVideoMessageContent } from "../../store/actions/LiveVideoAction";
import { Link } from "react-router-dom";
import {
  Media,
  Image,
  Tab,
  Row,
  Col,
  Nav,
  Form,
  Button,
} from "react-bootstrap";
import {
  fetchSingleLiveVideoStart,
  fetchLiveVideoMessageStart,
  fetchLiveVideosProductsStart,
} from "../../store/actions/LiveVideoAction";
import { updateAvailabiltyProOwnerStart } from "../../store/actions/ProductOwnerAction";
import LiveProducts from "./LiveProducts";

const ProductsList = (props) => {
  const [activeSec, setActiveSec] = useState("available");

  const [searchKey, setSearchKey] = useState();

  
  const handleChange = (event) => {
    setSearchKey(event.target.value);
    props.dispatch(
      fetchLiveVideosProductsStart({ search_key: event.target.value })
    );
  };


  console.log(props.liveVideosProducts);
  return (
    <>
      <div className="left-side">
        <div className="video-call-product-list-sec">
          <Tab.Container id="left-tabs-example" defaultActiveKey="available">
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Link className="live-back-button"
                      onClick={() => window.location.assign("/")
                      }
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      />
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="available"
                      onClick={(event) =>
                        props.setActiveSection(event, "available")
                      }
                    >
                      {t("available")}
                      <span>
                        (
                        {props.liveVideosProducts.loading
                          ? 0
                          : props.liveVideosProducts.productData
                            .products_available}
                        )
                      </span>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="sold"
                      onClick={(event) =>
                        props.setActiveSection(event, "sold")
                      }
                    >
                      {t("sold")}
                      <span>
                        (
                        {props.liveVideosProducts.loading
                          ? 0
                          : props.liveVideosProducts.productData
                            .products_sold}
                        )
                      </span>
                    </Nav.Link>
                  </Nav.Item>

                  {props.liveVideo.data.is_owner === 0 && (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="purchased"
                        onClick={(event) =>
                          props.setActiveSection(event, "purchased")
                        }
                      >
                        {t("purchased")}
                        <span>
                          (
                          {props.liveVideosProducts.loading
                            ? 0
                            : props.liveVideosProducts.productData
                              .products_purchased}
                          )

                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Col>
              <Col sm={12}>
                {/* {props.liveVideo.data.is_streaming == 1 ?  */}
                <Tab.Content>
                  <Tab.Pane eventKey="available">
                    <div className="product-search-sec">
                      <div className="product-search-area">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          className="product-search-input"
                          value={searchKey}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                        <button className="send-button">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                      <div className="video-product-list-box">
                        {props.liveVideosProducts.loading
                          ? "Loading..."
                          : props.liveVideosProducts.data.products.length > 0
                            ? props.liveVideosProducts.data.products.map(
                              (product, index) =>
                                product.is_outofstock ? (
                                  <LiveProducts
                                    product={product}
                                    liveVideo={props.liveVideo.data}
                                    user={props.liveVideosProducts.productData.user}
                                  />
                                ) : (
                                  ""
                                )
                            )
                            : "No products Available"}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sold">
                    <div className="product-search-sec">
                      <div className="product-search-area">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          className="product-search-input"
                        />
                        <button className="send-button">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                      <div className="video-product-list-box">
                        {props.liveVideosProducts.loading
                          ? "Loading..."
                          : props.liveVideosProducts.data.products.length > 0
                            ? props.liveVideosProducts.data.products.map(
                              (product, index) =>
                                !product.is_outofstock ? (
                                  <div className="video-product-list-card">
                                    <div className="video-product-list-left-sec">
                                      <h4>{product.name}</h4>
                                      <h6>{product.category_name}</h6>
                                      {/* <p>Set Pre-bid</p> */}
                                    </div>
                                    {props.is_streaming === 1 ? ( 
                                    <div className="video-product-list-right-sec">
                                      {props.liveVideo.data.user_id ==
                                        localStorage.getItem("userId") ? (
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            props.dispatch(
                                              updateAvailabiltyProOwnerStart({
                                                user_product_id:
                                                  product.user_product_id,
                                                live_video_id:
                                                  props.liveVideo.data
                                                    .live_video_id,
                                              })
                                            );
                                          }}
                                        >
                                          {t("mark_as_available")}
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                       ) : null }
                                  </div>
                                ) : (
                                  ""
                                )
                            )
                            : "No products Available"}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="purchased" className="purchased">
                    <div className="product-search-sec">
                      <div className="product-search-area">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          className="product-search-input"
                        />
                        <button className="send-button">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                      <div className="video-product-list-box">
                        {props.liveVideosProducts.loading
                          ? "Loading..."
                          : props.liveVideosProducts.productData
                            .purchased_products.length > 0
                            ? props.liveVideosProducts.productData.purchased_products.map(
                              (product, index) => (
                                <div className="video-product-list-card">
                                  <div className="video-product-list-left-sec">
                                    <h4>{product.name}</h4>
                                    <h6>{product.category_name}</h6>
                                    <h4 className="sold-color">
                                      {product.price_formatted}
                                    </h4>
                                  </div>
                                </div>
                              )
                            )
                            : "No products Available"}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                {/* : <p className="mt-3">{t("streaming_not_yet_started")}</p>} */}
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ProductsList));
