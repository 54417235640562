import React, { useState, useEffect } from "react";
import {
    NavDropdown,
    Nav,
    Navbar,
    Container,
    Card,
    Image,
} from "react-bootstrap";
import {
    fetchCategoryVideosStart,
    fetchSubCategoryVideosStart,
} from "../../store/actions/HomeAction";
import {
    fetchCategoriesListStart,
    followCategoriesStart,
    fetchSubCategoriesStart,
} from "../../store/actions/LookUpAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import SingleVideoCard from "./SingleVideoCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SingleFeaturedVideoCard from "./SingleFeaturedVideoCard";
import { fetchOngoingLiveVideosStart } from "../../store/actions/HomeAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { translate, t } from "react-multi-lang";

const NewCategoryCard = (props) => {
    const [activeId, setActiveId] = useState(null);
    const { videos } = props;

    const [skip, setSkip] = useState(0);

    useEffect(() => {
        props.dispatch(fetchOngoingLiveVideosStart({ skip: skip, take: 12 }));
    }, []);

    useEffect(() => {
        setSkip(videos.data.live_videos.length);
    }, [videos.data.live_videos]);

    function toggleActive(id) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    const [isActive, setActive] = useState(true);

    const toggleClass = () => {
        setActive(!isActive);
    };

    const fetchMoreData = () => {
        props.dispatch(fetchOngoingLiveVideosStart({ fetchMore: true, skip: skip, take: 12 }));
    }

    return (
        <>
            <div className="category-card-head">
                {!videos.loading ? (
                    <>
                        <h2>{t("live_shows")}</h2>
                    </>
                ) : null}
            </div>
            {!videos.loading ?
                (videos.data.live_videos && (
                    <>
                        {videos.data.live_videos.length > 0 ? (

                            <InfiniteScroll
                                dataLength={videos.data.live_videos.length}
                                next={fetchMoreData}
                                hasMore={skip < videos.data.total_live_videos}
                                loader={<h4>Loading...</h4>}
                            >
                                <div className="new-category-card-sec">
                                    <div className="category-total-card mtb-1">
                                        {videos.data.live_videos.map((video, i) => (
                                            <SingleVideoCard live={video} key={i} />
                                        ))}
                                    </div>
                                </div>
                            </InfiniteScroll>
                        ) : (
                            <div className="text-center pt-8 h4">{t("no_data_found")}</div>
                        )}
                    </>
                )
                ) : <>
                    <div className="new-card-title pt-8">
                        <h4>
                            <Skeleton width={300} height={40} />
                        </h4>
                    </div>
                    <div className="new-category-list-card">
                        {[...Array(5)].map((e, i) => (
                            <a key={i}>
                                <div className="new-category-card">
                                    <div className="new-card-image">
                                        <div className="thumbnail-card">
                                            <Skeleton className="card-image-loader" />
                                        </div>
                                        <div className="category-profile-content">
                                            <Skeleton className="card-user-image-loader" />
                                            <h5>
                                                <Skeleton />
                                            </h5>
                                        </div>
                                        <h4>
                                            <Skeleton height={30} />
                                        </h4>
                                    </div>
                                    <div className="category-card-link">
                                        <Skeleton />
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </>
            }
        </>
    );
};

const mapStateToPros = (state) => ({
    videos: state.home.ongoingLiveVideos,
});

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
};

export default connect(mapStateToPros, mapDispatchToProps)(translate(NewCategoryCard));
