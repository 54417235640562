import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SideMenu from "./SideMenu";
import HomeContent from "./HomeContent";
import NewCategoryCard from "./NewCategoryCard";
import { fetchCategoriesListStart } from "../../store/actions/LookUpAction";
import LiveCard from "./LiveCard";
import { useLocation } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import { Form, Image, InputGroup } from "react-bootstrap";
import { homeSearchStart } from "../../store/actions/HomeAction";
import { Link, NavLink } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const HomePage = (props) => {
  const [selectedCategory, setSelectedCategory] = useState({
    categoryId: null,
    subCategoryId: null,
  });

  const [isActive, setActive] = useState(true);
  const [openSearchResult, setOpenSearchResult] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  let query = useQuery();

  useEffect(() => {
    let newSelectedCategory = {
      categoryId: null,
      subCategoryId: null,
    };
    newSelectedCategory = query.get("category")
      ? { ...newSelectedCategory, categoryId: query.get("category") }
      : newSelectedCategory;
    newSelectedCategory = query.get("subCategory")
      ? { ...newSelectedCategory, subCategoryId: query.get("subCategory") }
      : newSelectedCategory;
    newSelectedCategory = query.get("live")
      ? { ...newSelectedCategory, live: query.get("live") }
      : newSelectedCategory;
    setSelectedCategory(newSelectedCategory);
  }, [query]);

  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, []);
  const handleSearch = (event) => {
    setOpenSearchResult(true);
    event.preventDefault();
    props.dispatch(homeSearchStart({ search_key: event.currentTarget.value }));
  };

  useEffect(() => {
    if (!props.homeSearch.loading && props.homeSearch.data.display_search == 0) {
      setOpenSearchResult(false);
    }
  }, [!props.homeSearch.loading]);

  return (
    <div>
      <div className="new-category-sec">
        <SideMenu
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          isActive={isActive}
          setActive={setActive}
        />
        <div className="category-right-sec">
          <div className="new-search-input mobile-search-bar">
            <InputGroup>
              <Form.Control
                placeholder="search"
                aria-label="Username"
                name="search_key"
                id="search_key"
                aria-describedby="basic-addon1"
                onChange={(event) => handleSearch(event)}
              />
              <InputGroup.Text id="basic-addon1">
                <Image
                  src={window.location.origin + "/assets/images/search.png"}
                />
              </InputGroup.Text>
            </InputGroup>
            <div
              className={`search-dropdown ${!openSearchResult ? "hide" : ""}`}
            >
              {props.homeSearch.loading ? 'Searching' :
                <ul>
                  <>
                    {props.homeSearch.data.live_videos.length > 0 ?
                      props.homeSearch.data.live_videos.map((live_video) => (
                        <li>
                          <Link to={`/live/${live_video.live_video_unique_id}`}>
                            <div className="search-dropdown-box">
                              <div className="search-dropdown-content">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/icons/video-1.png"
                                  }
                                />
                                <h4>{live_video.title}</h4>
                              </div>
                              <div className="search-live-btn">Live</div>
                            </div>
                          </Link>
                        </li>
                      )) : ''}
                    {props.homeSearch.data.categories.length > 0 ?
                      props.homeSearch.data.categories.map((category) => (
                        <li>
                          <Link
                            to={`/?category=${category.unique_id}`}
                          >
                            <div className="search-dropdown-box">
                              <div className="search-dropdown-content">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/icons/paper-money.png"
                                  }
                                />
                                <h4>{category.name}</h4>
                              </div>
                            </div>
                          </Link>
                        </li>
                      )) : ''}
                    {props.homeSearch.data.sub_categories.length > 0 ?
                      props.homeSearch.data.sub_categories.map((sub_category) => (
                        <li>
                          <Link to={`/?subCategory=${sub_category.unique_id}`}>
                            <div className="search-dropdown-box">
                              <div className="search-dropdown-content">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/featured/featured-1.png"
                                  }
                                />
                                <h4>{sub_category.name}</h4>
                              </div>
                            </div>
                          </Link>
                        </li>
                      )) : ''}
                    {props.homeSearch.data.users.length > 0 ?
                      props.homeSearch.data.users.map((user) => (
                        <li>
                          <Link to={`/${user.user_unique_id}`}>
                            <div className="search-dropdown-box">
                              <div className="search-dropdown-content">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/images/user.png"
                                  }
                                />
                                <h4>{user.name}</h4>
                              </div>
                            </div>
                          </Link>
                        </li>
                      )) : ''}
                  </>
                </ul>
              }
            </div>
          </div>
          <div className="mobile-nav-filter-sec">
            <a href="#" onClick={toggleClass}>
              {t("categories")}
            </a>
          </div>
          {selectedCategory.live !== null &&
            selectedCategory.live === "true" ? (
            <LiveCard />
          ) : (
            <>
              {selectedCategory.categoryId === null &&
                selectedCategory.subCategoryId === null ? (
                <HomeContent setSelectedCategory={setSelectedCategory} />
              ) : (
                <NewCategoryCard
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  categoriesList: state.lookUp.categoriesList,
  ongoingLiveVideos: state.home.ongoingLiveVideos,
  followingCategories: state.home.followingCategories,
  followingCategoriesLiveVideos: state.home.followingCategoriesLiveVideos,
  exploreCategoriesLiveVideos: state.home.exploreCategoriesLiveVideos,
  recentCategories: state.home.recentCategories,
  homeSearch: state.home.homeSearch,
});
const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(mapStateToPros, mapDispatchToProps)(translate(HomePage));
