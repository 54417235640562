import React from "react";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";

const NotificationTabSec = (props) => {
  return (
    <div className="tabbable-line notify-sec">
      <ul className="nav nav-tabs" role="tablist">
        <Media
          as="li"
          role="presentation"
          className={props.activeSec === "notify-all" ? "active" : ""}
        >
          <Link
            to="#Section1"
            aria-controls="home"
            role="tab"
            data-toggle="tab"
            onClick={(event) => props.changeSection(event, "notify-all")}
          >
            <span>
              <Image src="assets/images/icons/all.svg" className="svg-clone" />
            </span>
            {t("all")}
          </Link>
        </Media>
      </ul>
    </div>
  );
};

export default translate(NotificationTabSec);
