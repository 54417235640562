import React, { Component } from "react";
import AuthHeader from "./Header/AuthHeader";
import { Notify } from "react-redux-notify";
import LatestFooter from "./Footer/LatestFooter";

class VideoCallLayout extends Component {
  state = {};
  render() {
    return (
      <body>
        <Notify position="TopRight" />
        <div className="videocall-wrapper">
          {React.cloneElement(this.props.children)}
        </div>
      </body>
    );
  }
}

export default VideoCallLayout;
