import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Media,
} from "react-bootstrap";
import "./LatestFooter.css";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";

const LatestFooter = (props) => {
  return (
    <>
      <div className="latest-footer-sec">
        {/* <Container>
          <Row>
            <Col md={8}>
              <div className="demo-footer-sec">
                <h5>No Credit Card Rquired</h5>
                <h3>Get a Demo of FansForX today!</h3>
                <Form></Form>
              </div>
            </Col>
          </Row>
        </Container> */}
        <footer className="footer-section latest-footer-box">
          <Container>
            <div className="footer-cta pt-5 pb-5">
              <Row>
                {configuration.get("configData.contact_address") ? (
                  <Col xl={4} md={4} className="mb-30">
                    <div className="single-cta resp-mrg-btn-xs">
                      <i className="fas fa-map-marker-alt"></i>
                      <div className="cta-text">
                        <h4>{t("find_us")}</h4>
                        <span>
                          {configuration.get("configData.contact_address")}
                        </span>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                {configuration.get("configData.contact_mobile") ? (
                  <Col xl={4} md={4} className="mb-30">
                    <div className="single-cta footer-aligin-center resp-mrg-btn-xs">
                      <i className="fas fa-phone"></i>
                      <div className="cta-text">
                        <h4>{t("call_us")}</h4>
                        <span>
                          {configuration.get("configData.contact_mobile")}
                        </span>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                {configuration.get("configData.contact_email") ? (
                  <Col xl={4} md={4} className="mb-30">
                    <div className="single-cta footer-aligin-last">
                      <i className="far fa-envelope-open"></i>
                      <div className="cta-text">
                        <h4>{t("mail_us")}</h4>
                        <span>
                          {configuration.get("configData.contact_email")}
                        </span>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
            <div className="footer-content pt-5 pb-5">
              <Row>
                <Col xl={5} lg={5} className="mb-30">
                  <div className="footer-widget">
                    <div className="footer-logo">
                      <Link to="#">
                        <Image
                          className="footer-logo"
                          src={configuration.get("configData.site_logo")}
                        />
                      </Link>
                    </div>
                    <div className="footer-text">
                      <p>{configuration.get("configData.tag_name")}</p>
                    </div>
                    <div className="footer-social-icon">
                      <span>{t("follow_us")}</span>
                      <ul className="footer-social-link-sec list-unstyled">
                        <Media as="li">
                          <Link
                            to={configuration.get("configData.facebook_link")}
                          >
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link
                            to={configuration.get("configData.twitter_link")}
                          >
                            <i className="fab fa-twitter"></i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link
                            to={configuration.get("configData.instagram_link")}
                          >
                            <i className="fab fa-instagram"></i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link
                            to={configuration.get("configData.linkedin_link")}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link
                            to={configuration.get("configData.pinterest_link")}
                          >
                            <i className="fab fa-pinterest"></i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link
                            to={configuration.get("configData.youtube_link")}
                          >
                            <i className="fab fa-youtube"></i>
                          </Link>
                        </Media>
                        <Media as="li">
                          <Link
                            to={configuration.get("configData.snapchat_link")}
                          >
                            <i className="fab fa-snapchat"></i>
                          </Link>
                        </Media>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xl={2} lg={2} className="mb-30">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>{configuration.get("configData.site_name")}</h3>
                    </div>
                    <ul className="list-unstyled">
                      {configuration
                        .get("configData.footer_pages1")
                        .map((static_page, index) => (
                          <Media
                            as="li"
                            key={"sp-" + static_page.static_page_unique_id}
                          >
                            <Link
                              to={`/page/${static_page.static_page_unique_id}`}
                              key={static_page.static_page_unique_id}
                            >
                              {static_page.title}
                            </Link>
                          </Media>
                        ))}
                    </ul>
                  </div>
                </Col>
                <Col xl={2} lg={2} className="mb-50">
                  <div className="footer-widget">
                    <div className="footer-widget">
                      <div className="footer-widget-heading">
                        <h3>{t("discover")}</h3>
                      </div>
                      <ul className="list-unstyled">
                        {configuration
                          .get("configData.footer_pages2")
                          .map((static_page, index) => (
                            <Media
                              as="li"
                              key={"sp-" + static_page.static_page_unique_id}
                            >
                              <Link
                                to={`/page/${static_page.static_page_unique_id}`}
                                key={static_page.static_page_unique_id}
                              >
                                {static_page.title}
                              </Link>
                            </Media>
                          ))}
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3}>
                  {/* <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>{t("lauguage")}</h3>
                    </div>
                    <div className="select-lang-drop-down">
                      <select className="form-control mw-200 mb-3" name="lang">
                        <option
                          value="en"
                          // selected={
                          //     localStorage.getItem("lang") == "en" ? true : false
                          // }
                        >
                          {t("english")}
                        </option>
                        <option
                          value="es"
                          // selected={
                          //     localStorage.getItem("lang") == "es" ? true : false
                          // }
                        >
                          {t("spanish")}
                        </option>
                      </select>
                    </div>
                  </div> */}
                </Col>
              </Row>
            </div>
          </Container>
          <div className="copyright-area">
            <Container>
              <Row>
                <Col xl={12} lg={12} className="text-center">
                  <div className="copyright-text">
                    <p>{configuration.get("configData.copyright_content")}</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </footer>
      </div>
    </>
  );
};

export default (translate(LatestFooter));
