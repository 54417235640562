import { all, fork } from "redux-saga/effects";

import UserSaga from "./UserSaga";
import ChangePasswordSaga from "./ChangePasswordSaga";
import CardsSaga from "./CardsSaga";
import BankAccountSaga from "./BankAccountSaga";
import KycDocumentSaga from "./KycDocumentSaga";
import WalletSaga from "./WalletSaga";
import TransactionSaga from "./TransactionSaga";
import WithDrawSaga from "./WithDrawSaga";
import PageSaga from "./PageSaga";
import ErrorSaga from "./ErrorSaga";
import FollowSaga from "./FollowSaga";
import VerificationDocumentSaga from "./VerificationDocumentSaga";
import BookmarkSaga from "./BookmarkSaga";
import HomeSaga from "./HomeSaga";
import OtherUserSaga from "./OtherUserSaga";
import NotificationSaga from "./NotificationSaga";
import LiveVideoSaga from "./LiveVideoSaga";
import ReferralSaga from "./ReferralSaga";
import ProductsSaga from './ProductsSaga';
import ProductOwnerSaga from './ProductOwnerSaga';
import LookUpSaga from './LookUpSaga';
import DeliveryAddressSaga from './DeliveryAddressSaga';
import OrderSaga from './OrderSaga';

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(ChangePasswordSaga)]);
  yield all([fork(CardsSaga)]);
  yield all([fork(BankAccountSaga)]);
  yield all([fork(KycDocumentSaga)]);
  yield all([fork(WalletSaga)]);
  yield all([fork(TransactionSaga)]);
  yield all([fork(WithDrawSaga)]);
  yield all([fork(ErrorSaga)]);
  yield all([fork(PageSaga)]);
  yield all([fork(FollowSaga)]);
  yield all([fork(VerificationDocumentSaga)]);
  yield all([fork(BookmarkSaga)]);
  yield all([fork(HomeSaga)]);
  yield all([fork(OtherUserSaga)]);
  yield all([fork(NotificationSaga)]);
  yield all([fork(LiveVideoSaga)]);
  yield all([fork(ReferralSaga)]);
  yield all([fork(ProductsSaga)]);
  yield all([fork(LookUpSaga)]);
  yield all([fork(DeliveryAddressSaga)]);
  yield all([fork(OrderSaga)]);
  yield all([fork(ProductOwnerSaga)]);
}
