import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./BecomeAContentCreator.css";
import {
  editUserDetails,
  // updateUserSubscriptionStart,
} from "../../store/actions/UserAction";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";

const Step5 = (props) => {
  const [profileInputData, setProfileInputData] = useState({});

  useEffect(() => {
    if (props.user.data.content_creator_step === 0) {
      const notificationMessage = getErrorNotificationMessage(
        t("upload_doc_message")
      );
      props.dispatch(createNotification(notificationMessage));
      props.jumpToStep(0);
    } else if (props.user.data.content_creator_step === 1) {
      const notificationMessage = getErrorNotificationMessage(
        t("doc_verification_pending_message")
      );
      props.dispatch(createNotification(notificationMessage));
      props.jumpToStep(2);
    } else if (props.user.data.content_creator_step === 2) {
      const notificationMessage = getErrorNotificationMessage(
        t("update_billing_details_message")
      );
      props.dispatch(createNotification(notificationMessage));
      props.jumpToStep(3);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    // if (Object.keys(profileInputData).length > 0)
    //   props.dispatch(updateUserSubscriptionStart(profileInputData));
    // else props.dispatch(updateUserSubscriptionStart());
  };

  return (
    <>
      <div className="payout-competed">
        <Row className="justify-content-md-center">
          <Col md={4}>
            <h4>{t("congratulations")}</h4>
            <div className="payout-competed-img-sec">
              <Image
                className="payout-competed-img"
                src={
                  window.location.origin + "/assets/images/congratulation.svg"
                }
              />
            </div>
            <p>
            {t("congratulations_seller_note")}{" "}
              <Link to="/dashboard">{t("dashboard")}</Link> {t("page_to_edit_your_settings")}
            </p>
          </Col>
        </Row>
      </div>
      <Button className="finish-button">{t("finish")}</Button>

      {/* <div className="step-4-content-sec">
				<div className="step-4-info">
						<h4>Setup Subscribers Fee</h4>
						<p>Set fee for people who want to subscribe to your profile. Pricing can be made in <span>3 different ways: Monthly, Yearly </span></p>
						<p>These charges will be renewed automatically when they expire.</p>
						<ul className="step-4-list list-unstyled">
								<Media as="li">
										<i className="fas fa-angle-double-right mr-2"></i> If you block your subscribers or if they block you, subscriptions will be automatically canceled.
								</Media>
								<Media as="li">
										<i className="fas fa-angle-double-right mr-2"></i> You can activate or deactivate your subscription pricing. After the fees you earn while active, become passive, your subscriptions continue until your member cancels.
								</Media>
								<Media as="li">
										<i className="fas fa-angle-double-right mr-2"></i> Changing the fee will not change the price of previous subscribers.
								</Media>
						</ul>
				</div>
				<div className="step-4-subscription-fee-sec">
						<div className="step-4-subscription-flex-data">
								<div className="step-4-subscription-content-sec">
										<h5>Monthly Subscription fee</h5>
										<p>Payments are made monthly by the subscriber.</p>
										<InputGroup>
												<InputGroup.Prepend>
													<InputGroup.Text>
														{configuration.get("configData.currency")}
													</InputGroup.Text>
												</InputGroup.Prepend>
												<FormControl aria-label="Amount (to the nearest dollar)" 
												id="monthly_amount"
												type="number"
												step="any"
												min="0"
												placeholder=""
												name="monthly_amount"
												className="form-control edit-reset"
												disabled={
													localStorage.getItem("is_subscription_enabled") == 1
														? false
														: true
												}
												defaultValue={props.user.data.monthly_amount}
												onChange={(event) => {
													props.dispatch(
														editUserDetails(
															event.currentTarget.name,
															event.currentTarget.value
														)
													);
												}}/>
												<InputGroup.Append>
														<InputGroup.Text>Monthly</InputGroup.Text>
												</InputGroup.Append>
										</InputGroup>
								</div>
						</div>
						<div className="step-4-subscription-flex-data">
								<div className="step-4-subscription-content-sec">
										<h5>Yearly Subscription fee</h5>
										<p>Payments are made yearly by the subscriber.</p>
										<InputGroup>
												<InputGroup.Prepend>
												<InputGroup.Text>
														{configuration.get("configData.currency")}
													</InputGroup.Text>
												</InputGroup.Prepend>
												<FormControl aria-label="Amount (to the nearest dollar)" 
													id="yearly_amount"
													type="number"
													step="any"
													min="0"
													placeholder=""
													name="yearly_amount"
													className="form-control edit-reset"
													disabled={
														localStorage.getItem("is_subscription_enabled") == 1
															? false
															: true
													}
													defaultValue={props.user.data.yearly_amount}
													onChange={(event) => {
														props.dispatch(
															editUserDetails(
																event.currentTarget.name,
																event.currentTarget.value
															)
														);
													}}/>
												<InputGroup.Append>
														<InputGroup.Text>Yearly</InputGroup.Text>
												</InputGroup.Append>
										</InputGroup>
								</div>
						</div>
						<div className="edit-save">
							<Button
								className="save-btn"
								onClick={handleSubmit}
								disabled={props.profileInputData.buttonDisable}
							>
								{props.profileInputData.loadingButtonContent !== null
									? props.profileInputData.loadingButtonContent
									: t("submit")}
							</Button>
						</div>
				</div>
			</div> */}
    </>
  );
};

const mapStateToPros = (state) => ({
  profileInputData: state.users.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step5));
