import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Media,
  Image,
} from "react-bootstrap";
import "./Orders.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ordersListForOthersStart, fetchUserProductsForOwnerStart } from "../../../store/actions/ProductsAction";
import { cancelOrderStart } from "../../../store/actions/OrderAction";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../NoDataFound/NoDataFound";
import ProductHeader from "../Product/ProductHeader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


const OrderList = (props) => {
  const [searchKey, setSearchKey] = useState();


  useEffect(() => {
    props.dispatch(ordersListForOthersStart());
  }, []);

  const cancelOrder = (event, order_id) => {
    event.preventDefault();
    if (
      window.confirm(
        t("delete_product_confirmation")
      )
    )
    
    props.dispatch(cancelOrderStart({ order_id: order_id }));
  };

  const handleChange = (event) => {
    setSearchKey(event.target.value);
    props.dispatch(
      ordersListForOthersStart({ search_key: event.target.value })
    );
  };
  

  return (
    <>
      <div className="order-list-sec">
        <Container>
          <h2>{t("orders_list")}</h2>
          <div className="ecom-navbar">
            <ProductHeader />
          </div>

          <Row>

            <Col md={12}>

              <div className="table-wrap">
              <Form>
                  <Row>
                    <Col md={9}></Col>
                    <Col md={3} className="text-right">
                      <div className="form-group explore-location-dropdown mb-4">
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control edit-reset"
                          value={searchKey}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        ></input>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <Table responsive="md">
                  <thead className="thead-primary">
                    <tr>
                      <th>{t("products")}</th>
                      <th>{t("order_id")}</th>
                      <th>{t("shipping_address")}</th>
                      <th>{t("phone_number")}</th>
                      <th>{t("amount")}</th>
                      <th>{t("status")}</th>
                      {/* <th className="text-centre">{t("action")}</th> */}
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  {props.ordersListForOthers.loading ? (
                    <tbody>
                      {[...Array(2)].map((e, i) => (
                        <tr>
                          <td><Skeleton className="img" /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (props.ordersListForOthers.data.total > 0 ? (
                    <tbody>
                      {props.ordersListForOthers.data.orders.map(
                        (order, index) => (
                          <>
                            <tr className="alert" role="alert" key={index}>
                              <td>
                                <div className="order-list-flex">
                                  <div className="orderer-product">
                                    {order.order_product.map(
                                      (product, index) => (
                                        <>
                                          {product.user_product_details ? (
                                            <div
                                              className={`order-list-product ${order.total_products > 1
                                                ? "mb-3"
                                                : ""
                                                }`}
                                              key={index}
                                            >
                                              <div
                                                className="img"
                                                style={{
                                                  backgroundImage: `url(${product.user_product_details.picture})`,
                                                }}
                                              ></div>
                                              <div className="email">
                                                <span>
                                                  {
                                                    product
                                                      .user_product_details
                                                      .name
                                                  }{" "}
                                                </span>
                                                <span>
                                                  {t("quantity")} :{" "}
                                                  {product.quantity}
                                                </span>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="quantity">{order.unique_id}</td>
                              {order.delivery_address ?
                                <>
                                  <td className="address">
                                    {order.delivery_address.landmark}, <br />
                                    {order.delivery_address.address}-
                                    {order.delivery_address.pincode} <br />
                                  </td>
                                  <td>{order.delivery_address.contact_number}</td>
                                </>
                                : <><td className="address"></td><td></td></>
                              }
                              <td className="quantity">
                                {order.total_formatted}
                              </td>
                              <td className="quantity">
                                {order.order_status}
                              </td>
                              <td className="text-right">
                                <Button
                                  type="button"
                                  className="order-view-btn mr-3"
                                  href={`/order-view/${order.unique_id}`}
                                >
                                 {t("view")}
                                </Button>
                                {order.order_btn_status.cancel_btn_status ?
                                  <Button
                                    type="button"
                                    className="order-view-btn mt-3"
                                    onClick={(event) => cancelOrder(event, order.id)}
                                  >
                                    {t("cancel")}
                                  </Button>
                                  : ''}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  ) : (
                    <div>
                      <NoDataFound></NoDataFound>
                    </div>
                  ))}
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  ordersListForOthers: state.userProducts.ordersListForOthers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(OrderList));
