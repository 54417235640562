import {
    FETCH_DELIVERY_ADDRESS_START,
    FETCH_DELIVERY_ADDRESS_SUCCESS,
    FETCH_DELIVERY_ADDRESS_FAILURE,
    SAVE_DELIVERY_ADDRESS_START,
    SAVE_DELIVERY_ADDRESS_SUCCESS,
    SAVE_DELIVERY_ADDRESS_FAILURE,
    DELETE_DELIVERY_ADDRESS_START,
    DELETE_DELIVERY_ADDRESS_SUCCESS,
    DELETE_DELIVERY_ADDRESS_FAILURE,
    SET_DEFAULT_DELIVERY_ADDRESS_START,
    SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS,
    SET_DEFAULT_DELIVERY_ADDRESS_FAILURE,
} from '../actions/ActionConstant';

const initialState = {
    deliveryAddress: {
        data: {},
        loading: true,
        error: false,
    },
    deliveryAddressSave: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: "",
        buttonDisable: false
    },
    deliveryAddressDelete: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: "",
        buttonDisable: false
    },
    defaultDeliveryAddress: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: "",
    }
};

const DeliveryAddressReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DELIVERY_ADDRESS_START:
            return {
                ...state,
                deliveryAddress: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                deliveryAddress: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_DELIVERY_ADDRESS_FAILURE:
            return {
                ...state,
                deliveryAddress: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case SAVE_DELIVERY_ADDRESS_START:
            return {
                ...state,
                deliveryAddressSave: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case SAVE_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                deliveryAddressSave: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case SAVE_DELIVERY_ADDRESS_FAILURE:
            return {
                ...state,
                deliveryAddressSave: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case DELETE_DELIVERY_ADDRESS_START:
            return {
                ...state,
                deliveryAddressDelete: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case DELETE_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                deliveryAddressDelete: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case DELETE_DELIVERY_ADDRESS_FAILURE:
            return {
                ...state,
                deliveryAddressDelete: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case SET_DEFAULT_DELIVERY_ADDRESS_START:
            return {
                ...state,
                defaultDeliveryAddress: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                defaultDeliveryAddress: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case SET_DEFAULT_DELIVERY_ADDRESS_FAILURE:
            return {
                ...state,
                defaultDeliveryAddress: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        default:
            return state;
    }
}

export default DeliveryAddressReducer;