import {
    FETCH_ORDER_LIST_START,
    FETCH_ORDER_LIST_SUCCESS,
    FETCH_ORDER_LIST_FAILURE,
    ORDER_LIST_FOR_SELLER_START,
    ORDER_LIST_FOR_SELLER_SUCCESS,
    ORDER_LIST_FOR_SELLER_FAILURE,
    FETCH_ORDER_DETAILS_START,
    FETCH_ORDER_DETAILS_SUCCESS,
    FETCH_ORDER_DETAILS_FAILURE,
    CREATE_ORDER_BY_WALLET_START,
    CREATE_ORDER_BY_WALLET_SUCCESS,
    CREATE_ORDER_BY_WALLET_FAILURE,
    CREATE_ORDER_BY_STRIPE_START,
    CREATE_ORDER_BY_STRIPE_SUCCESS,
    CREATE_ORDER_BY_STRIPE_FAILURE,
    UPDATE_ORDER_STATUS_START,
    UPDATE_ORDER_STATUS_SUCCESS,
    UPDATE_ORDER_STATUS_FAILURE,
    CANCEL_ORDER_START,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILURE,
    FETCH_ORDER_PAYMENT_LIST_START,
    FETCH_ORDER_PAYMENT_LIST_SUCCESS,
    FETCH_ORDER_PAYMENT_LIST_FAILURE,
    ORDERS_VIEW_FOR_OTHERS_START,
    ORDERS_VIEW_FOR_OTHERS_SUCCESS,
    ORDERS_VIEW_FOR_OTHERS_FAILURE,
} from './ActionConstant';

export function fetchOrderListStart(data) {
    return {
        type: FETCH_ORDER_LIST_START,
        data,
    };
}

export function fetchOrderListSuccess(data) {
    return {
        type: FETCH_ORDER_LIST_SUCCESS,
        data,
    };
}

export function fetchOrderListFailure(error) {
    return {
        type: FETCH_ORDER_LIST_FAILURE,
        error,
    }
}

export function orderListForSellerStart(data) {
    return {
        type: ORDER_LIST_FOR_SELLER_START,
        data,
    };
}

export function orderListForSellerSuccess(data) {
    return {
        type: ORDER_LIST_FOR_SELLER_SUCCESS,
        data,
    };
}

export function orderListForSellerFailure(error) {
    return {
        type: ORDER_LIST_FOR_SELLER_FAILURE,
        error,
    };
}

export function fetchOrderDetailsStart(data) {
    return {
        type: FETCH_ORDER_DETAILS_START,
        data,
    };
}

export function fetchOrderDetailsSuccess(data) {
    return {
        type: FETCH_ORDER_DETAILS_SUCCESS,
        data,
    };
}

export function fetchOrderDetailsFailure(error) {
    return {
        type: FETCH_ORDER_DETAILS_FAILURE,
        error,
    };
}

export function createOrderByWalletStart(data) {
    return {
        type: CREATE_ORDER_BY_WALLET_START,
        data,
    };
}

export function createOrderByWalletSuccess(data) {
    return {
        type: CREATE_ORDER_BY_WALLET_SUCCESS,
        data,
    };
}

export function createOrderByWalletFailure(error) {
    return {
        type: CREATE_ORDER_BY_WALLET_FAILURE,
        error
    };
}

export function createOrderByStripeStart(data) {
    return {
        type: CREATE_ORDER_BY_STRIPE_START,
        data,
    };
}

export function createOrderByStripeSuccess(data) {
    return {
        type: CREATE_ORDER_BY_STRIPE_SUCCESS,
        data,
    };
}

export function createOrderByStripeFailure(error) {
    return {
        type: CREATE_ORDER_BY_STRIPE_FAILURE,
        error,
    };
}

export function updateOrderStatusStart(data) {
    return {
        type: UPDATE_ORDER_STATUS_START,
        data,
    };
}

export function updateOrderStatusSuccess(data) {
    return {
        type: UPDATE_ORDER_STATUS_SUCCESS,
        data,
    };
}

export function updateOrderStatusFailure(error) {
    return {
        type: UPDATE_ORDER_STATUS_FAILURE,
        error,
    };
}

export function cancelOrderStart(data) {
    return {
        type: CANCEL_ORDER_START,
        data,
    };
}

export function cancelOrderSuccess(data) {
    return {
        type: CANCEL_ORDER_SUCCESS,
        data,
    };
}

export function cancelOrderFailure(error) {
    return {
        type: CANCEL_ORDER_FAILURE,
        error,
    }
}

export function fetchOrderPaymentListStart(data) {
    return {
        type: FETCH_ORDER_PAYMENT_LIST_START,
        data,
    };
}

export function fetchOrderPaymentListSuccess(data) {
    return {
        type: FETCH_ORDER_PAYMENT_LIST_SUCCESS,
        data,
    };
}

export function fetchOrderPaymentListFailure(data) {
    return {
        type: FETCH_ORDER_PAYMENT_LIST_FAILURE,
        data,
    }
}

export function ordersViewForOthersStart(data) {
    return {
        type: ORDERS_VIEW_FOR_OTHERS_START,
        data,
    };
}
  
export function ordersViewForOthersSuccess(data) {
    return {
        type: ORDERS_VIEW_FOR_OTHERS_SUCCESS,
        data,
    };
}
  
export function ordersViewForOthersFailure(error) {
    return {
        type: ORDERS_VIEW_FOR_OTHERS_FAILURE,
        error,
    };
}