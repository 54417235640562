import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_LISTS_DETAILS_START,
  SEARCH_USER_START,
  FETCH_TRENDING_USERS_START,
  FETCH_ONGOING_LIVE_VIDEOS_START,
  FETCH_FOLLOWING_CATEGORIES_START,
  FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_START,
  FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_START,
  FETCH_RECENT_CATEGORIES_START,
  FETCH_CATEGORY_VIDEOS_START,
  FETCH_SUB_CATEGORY_VIDEOS_START,
  LIVE_VIDEO_BOOKMARK_SAVE_START,
  FETCH_BOOKMARKED_VIDEOS_START,
  HOME_SEARCH_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  searchUserFailure,
  searchUserSuccess,
  fetchListsDetailsSuccess,
  fetchListsDetailsFailure,
  fetchTrendingUsersFailure,
  fetchTrendingUsersSuccess,
  fetchOngoingLiveVideosSuccess,
  fetchOngoingLiveVideosFailure,
  fetchFollowingCategoriesSuccess,
  fetchFollowingCategoriesFailure,
  fetchFollowingCategoriesLiveVideosSuccess,
  fetchFollowingCategoriesLiveVideosFailure,
  fetchExploreCategoriesLiveVideoSuccess,
  fetchExploreCategoriesLiveVideoFailure,
  fetchRecentCategoriesSuccess,
  fetchRecentCategoriesFailure,
  fetchCategoryVideosSuccess,
  fetchCategoryVideosFailure,
  fetchSubCategoryVideosSuccess,
  fetchSubCategoryVideosFailure,
  liveVideoBookmarkSaveSuccess,
  liveVideoBookmarkSaveFailure,
  fetchBookmarkedVideosSuccess,
  fetchBookmarkedVideosFailure,
  homeSearchSuccess,
  homeSearchFailure,
} from "../actions/HomeAction";

function* searchUserAPI() {
  try {
    const inputData = yield select((state) => state.home.searchUser.inputData);
    const response = yield api.postMethod("users_search", inputData);
    if (response.data.success) {
      yield put(searchUserSuccess(response.data.data));
    } else {
      yield put(searchUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(searchUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchTrendingUsersAPI() {
  try {
    const inputData = yield select((state) => state.home.trendingUsers.inputData);
    const response = yield api.postMethod("trending_users", inputData);
    if (response.data.success) {
      yield put(fetchTrendingUsersSuccess(response.data.data));
    } else {
      yield put(fetchTrendingUsersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchTrendingUsersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchListsAPI() {
  try {
    const response = yield api.postMethod("lists_index");
    if (response.data.success) {
      yield put(fetchListsDetailsSuccess(response.data.data));
    } else {
      yield put(fetchListsDetailsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchListsDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchOngoingLiveVideosAPI(action) {
  try {
    const response = yield api.postMethod("ongoing_live_videos", action.data);
    if (response.data.success) {
      yield put(fetchOngoingLiveVideosSuccess(response.data.data));
    } else {
      yield put(fetchOngoingLiveVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchOngoingLiveVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchFollowingCategoriesAPI() {
  try {
    const response = yield api.postMethod("following_categories_list");
    if (response.data.success) {
      yield put(fetchFollowingCategoriesSuccess(response.data.data));
    } else {
      yield put(fetchFollowingCategoriesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchFollowingCategoriesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchFollowingCategoriesLiveVideosAPI() {
  try {
    const response = yield api.postMethod("following_category_videos");
    if (response.data.success) {
      yield put(fetchFollowingCategoriesLiveVideosSuccess(response.data.data));
    } else {
      yield put(fetchFollowingCategoriesLiveVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchFollowingCategoriesLiveVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchExploreCategoriesLiveVideosAPI() {
  try {
    const response = yield api.postMethod("explore_categories");
    if (response.data.success) {
      yield put(fetchExploreCategoriesLiveVideoSuccess(response.data.data));
    } else {
      yield put(fetchExploreCategoriesLiveVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchExploreCategoriesLiveVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchRecentCategoriesAPI() {
  try {
    const response = yield api.postMethod("recent_categories");
    if (response.data.success) {
      yield put(fetchRecentCategoriesSuccess(response.data.data));
    } else {
      yield put(fetchRecentCategoriesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchRecentCategoriesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchCategoryVideosAPI(action) {
  try {
    const response = yield api.postMethod("category_videos", action.data);
    if (response.data.success) {
      yield put(fetchCategoryVideosSuccess(response.data.data));
    } else {
      yield put(fetchCategoryVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchCategoryVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSubCategoryVideosAPI(action) {
  try {
    const response = yield api.postMethod("sub_category_videos", action.data);
    if (response.data.success) {
      yield put(fetchSubCategoryVideosSuccess(response.data.data));
    } else {
      yield put(fetchSubCategoryVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSubCategoryVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideoBookmarkSaveAPI(action) {
  try {
    const response = yield api.postMethod(
      "live_video_bookmarks_save",
      action.data
    );
    if (response.data.success) {
      yield put(liveVideoBookmarkSaveSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(liveVideoBookmarkSaveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(liveVideoBookmarkSaveFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchBookmarkedVideosAPI(action) {
  try {
    const response = yield api.postMethod("live_video_bookmarks", action.data);
    if (response.data.success) {
      yield put(fetchBookmarkedVideosSuccess(response.data.data));
    } else {
      yield put(fetchBookmarkedVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchBookmarkedVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* homeSearchAPI() {
  try {
    const inputData = yield select((state) => state.home.homeSearch.inputData);
    const response = yield api.postMethod("home_search", inputData);
    if (response.data.success) {
      yield put(homeSearchSuccess(response.data.data));
    } else {
      yield put(homeSearchFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(homeSearchSuccess(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(SEARCH_USER_START, searchUserAPI)]);
  yield all([yield takeLatest(FETCH_LISTS_DETAILS_START, fetchListsAPI)]);
  yield all([yield takeLatest(FETCH_TRENDING_USERS_START, fetchTrendingUsersAPI)]);
  yield all([yield takeLatest(FETCH_ONGOING_LIVE_VIDEOS_START, fetchOngoingLiveVideosAPI)]);
  yield all([yield takeLatest(FETCH_FOLLOWING_CATEGORIES_START, fetchFollowingCategoriesAPI)]);
  yield all([yield takeLatest(FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_START, fetchFollowingCategoriesLiveVideosAPI)])
  yield all([yield takeLatest(FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_START, fetchExploreCategoriesLiveVideosAPI)]);
  yield all([yield takeLatest(FETCH_RECENT_CATEGORIES_START, fetchRecentCategoriesAPI)]);
  yield all([yield takeLatest(FETCH_CATEGORY_VIDEOS_START, fetchCategoryVideosAPI)]);
  yield all([yield takeLatest(FETCH_SUB_CATEGORY_VIDEOS_START, fetchSubCategoryVideosAPI)]);
  yield all([yield takeLatest(LIVE_VIDEO_BOOKMARK_SAVE_START, liveVideoBookmarkSaveAPI)]);
  yield all([yield takeLatest(FETCH_BOOKMARKED_VIDEOS_START, fetchBookmarkedVideosAPI)]);
  yield all([yield takeLatest(HOME_SEARCH_START, homeSearchAPI)]);
}
