import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Media,
  Image,
} from "react-bootstrap";
import "../Ecom/Orders/Orders.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchLiveVideoOrdersStart } from "../../store/actions/LiveVideoAction";
import { useParams } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../NoDataFound/NoDataFound";
import ProductHeader from "../Ecom/Product/ProductHeader";
import { cancelOrderStart,updateOrderStatusStart } from "../../store/actions/OrderAction";

const LiveVideoOrders = (props) => {
  const { live_video_id } = useParams();

  useEffect(() => {
    props.dispatch(fetchLiveVideoOrdersStart({ live_video_id: live_video_id }));
  }, []);

  
  const cancelOrder = (event, order_id) => {
    event.preventDefault();
    props.dispatch(cancelOrderStart({order_id:order_id}));
};

const updateOrderStatus = (event, order_id,status) => {
    event.preventDefault();
    props.dispatch(updateOrderStatusStart({order_id:order_id,status:status}));
};
  
  return (
    <>
      <div className="order-list-sec">
        <Container>
          <h2>{t("orders_list")}</h2>
          <div className="ecom-navbar">
            <ProductHeader />
          </div>
          {props.liveVideoOrders.loading ? (
            "Loading..."
          ) : (
            <>
              {props.liveVideoOrders.orderData.total ? (
                <Row>
                  <Col md={12}>
                    <div className="table-wrap">
                      <Form>
                        <Row>
                          <Col md={9}>
                            <h2>
                              {t("showing_orders_for")}{" "}
                              {props.liveVideoOrders.orderData.live_video.title}
                            </h2>
                          </Col>
                          <Col md={3} className="text-right">
                            <div className="form-group explore-location-dropdown mb-4">
                              <input
                                type="text"
                                placeholder="Search"
                                className="form-control edit-reset"
                              ></input>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      <Table responsive="md" responsive="sm">
                        <thead className="thead-primary">
                          <tr>
                            <th>{t("product")}</th>
                            <th>{t("shipping_address")}</th>
                            <th>{t("phone_number")}</th>
                            <th>{t("amount")}</th>
                            <th>{t("quantity")}</th>
                            <th>{t("status")}</th>
                            <th className="text-right">{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.liveVideoOrders.data.products.map(
                            (order, index) => (
                              <>
                                <tr className="alert" role="alert" key={index}>
                                  <td>
                                    <div className="order-list-flex">
                                      <div className="orderer-product">
                                        {order.order_product ? (
                                          <div
                                            className={`order-list-product`}
                                          >
                                            <div
                                              className="img"
                                              style={{
                                                backgroundImage: `url(${order.order_product.user_product_details.picture})`,
                                              }}
                                            ></div>
                                            <div>
                                              <span>
                                                {
                                                  order.order_product.product_name
                                                }{" "}
                                              </span>
                                              <span>
                                                {t("quantity")} :{" "}
                                                {order.order_product.quantity}
                                              </span>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="address">
                                    {order.delivery_address ? 
                                    <>
                                    {order.delivery_address.landmark}, <br />
                                    {order.delivery_address.address}-
                                    {order.delivery_address.pincode} <br />
                                    </>: ""}
                                  </td>
                                  <td>
                                    {order.delivery_address ? order.delivery_address.contact_number : ''}
                                  </td>
                                  <td className="quantity">
                                    {order.total_formatted}
                                  </td>
                                  <td>{order.order_product ? order.order_product.quantity : 0}</td>
                                  <td>
                                    {order.order_status}
                                  </td>
                                  <td className="text-right">
                                    <Button
                                      type="button"
                                      className="order-view-btn mr-3"
                                      href={`/order-view/${order.unique_id}`}
                                    >
                                     {t("view")}
                                    </Button>
                                    {order.order_btn_status.cancel_btn_status ? 
                                      <Button
                                        type="button"
                                        className="order-view-btn mt-3"
                                        onClick={(event) => cancelOrder(event, order.id)}
                                      >
                                       {t("cancel")}
                                      </Button>
                                    : ''}
                                    {order.order_btn_status.shipped_btn_status ? 
                                      <Button
                                        type="button"
                                        className="order-view-btn mt-3"
                                        onClick={(event) => updateOrderStatus(event, order.id,2)}
                                      >
                                       {t("mark_as_shipped")}
                                      </Button>
                                    : ''}
                                    {order.order_btn_status.delivered_btn_status ? 
                                      <Button
                                        type="button"
                                        className="order-view-btn mt-3"
                                        onClick={(event) => updateOrderStatus(event, order.id,3)}
                                      >
                                       {t("mark_as_delivered")}
                                      </Button>
                                    : ''}
                                  </td>
                                </tr>
                              </>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              ) : (
                <div>
                  <NoDataFound></NoDataFound>
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideoOrders: state.liveVideo.liveVideoOrders,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LiveVideoOrders));
