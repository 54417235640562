import React from "react";
import configuration from "react-global-configuration";
import { Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

const ThemeHeader = () => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  return (
    <div className="header-nav-center" id="header">
      <Container>
        <nav className="navbar navbar-expand-lg navbar-light px-sm-0">
          <Link className="navbar-brand" to="/">
            <Image
              className="landing-logo"
              src={configuration.get("configData.site_logo")}
            />
          </Link>
          <button
            className="navbar-toggle collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navigationbar"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div
            className="collapse navbar-collapse navbar-ex1-collapse"
            id="navigationbar"
          >
            <ul className="navbar-nav ml-auto nav-pills">
              {!userId || !accessToken ? (
                <>
                  <li className="nav-item">
                    <Link
                      className="nav-link latest-header-btn-outline"
                      to="/login"
                    >
                      {t("login")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/signup" className="nav-link latest-header-btn">
                      {t("register")}
                      <small className="pl-2">
                        <i className="fa fa-long-arrow-alt-right"></i>
                      </small>
                    </Link>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <Link to="/" className="nav-link latest-header-btn">
                    {t("home")}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </Container>
    </div>
  );
};

export default translate(ThemeHeader);
