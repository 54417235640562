import React from "react";
import { Link } from "react-router-dom";
import { Image, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";

const FanTabSec = (props) => {
  return (
    <ul className="nav nav-tabs following-tab-sec" role="tablist">
      <Media
        as="li"
        role="presentation"
        className={props.activeSec === "active-sec" ? "active" : ""}
      >
        <Link
          to="#active"
          aria-controls="home"
          role="tab"
          data-toggle="tab"
          onClick={(event) => props.changeSection(event, "active-sec")}
        >
          <Image
            src="assets/images/icons/tick-circle.svg"
            className="svg-clone"
          />
          <span className="tab-nav-item"> {t("active")} </span>
        </Link>
      </Media>
    </ul>
  );
};

export default translate(FanTabSec);
