import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {
    fetchFollowingCategoriesStart,
    fetchFollowingCategoryLiveVideoStart,
    fetchOngoingLiveVideosStart,
    fetchExploreCategoriesLiveVideoStart,
    fetchRecentCategoriesStart,
    resetAllVideoList,
} from "../../store/actions/HomeAction";
import {
    NavDropdown,
    Nav,
    Navbar,
    Container,
    Card,
    Image,
} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LiveCardList from "./LiveCardList";
import CategoryThumbnailList from "./CategoryThumbnailList";
import CategoriesLiveCardList from "./CategoriesLiveCardList";

const HomeContent = (props) => {
    const [activeId, setActiveId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        props.dispatch(fetchExploreCategoriesLiveVideoStart());
        props.dispatch(fetchRecentCategoriesStart());
        props.dispatch(fetchOngoingLiveVideosStart());
        if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
            props.dispatch(fetchFollowingCategoriesStart());
            props.dispatch(fetchFollowingCategoryLiveVideoStart());
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
        return () => {
            props.dispatch(resetAllVideoList());
        }
    }, []);

    function toggleActive(id) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <div className="new-category-card-sec">
            {!isLoading &&
                <CategoryThumbnailList
                    title={"Categories You Follow"}
                    categories={props.followingCategories}
                />
            }
            <LiveCardList
                title="Live show"
                liveVideo={props.ongoingLiveVideos}
            />
            {!isLoading && !props.followingCategoriesLiveVideos.loading &&
                props.followingCategoriesLiveVideos.data
                    .category_live_videos &&
                props.followingCategoriesLiveVideos.data.category_live_videos.map(
                    (category, i) => (
                        <CategoriesLiveCardList
                            title={category.name}
                            categoryUniqueId={category.unique_id}
                            liveVideos={category.live_videos}
                        />
                    )
                )}
            <CategoryThumbnailList
                title={"Explore Categories"}
                categories={props.categoriesList}
            />
            {!props.exploreCategoriesLiveVideos.loading &&
                props.exploreCategoriesLiveVideos.data.category_live_videos &&
                props.exploreCategoriesLiveVideos.data.category_live_videos.map(
                    (category, i) => (
                        <CategoriesLiveCardList
                            title={category.name}
                            categoryUniqueId={category.unique_id}
                            liveVideos={category.live_videos}
                        />
                    )
                )}
            <CategoryThumbnailList
                title={"New Categories"}
                categories={props.recentCategories}
            />
            {/* <LiveCardList
              title="Scheduled Live"
              liveVideo={props.ongoingLiveVideos}
            /> */}
        </div>
    );
}

const mapStateToPros = (state) => ({
    categoriesList: state.lookUp.categoriesList,
    ongoingLiveVideos: state.home.ongoingLiveVideos,
    followingCategories: state.home.followingCategories,
    followingCategoriesLiveVideos: state.home.followingCategoriesLiveVideos,
    exploreCategoriesLiveVideos: state.home.exploreCategoriesLiveVideos,
    recentCategories: state.home.recentCategories,
});
const mapDispatchToProps = (dispatch) => {
    return { dispatch };
};

export default connect(mapStateToPros, mapDispatchToProps)(HomeContent);