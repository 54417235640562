import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { liveVideoBookmarkSaveStart } from '../../store/actions/HomeAction';
import LazyLoad from 'react-lazyload';
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";

const SingleVideoCard = (props) => {
    const { live } = props;
    // const [bookmarked, setBookmarked] = useState(false);
    // const [bookmarkCount, setBookmarkCount] = useState(live.total_bookmarks);

    const bookmarkVideo = (e, videoId) => {
        if (e.stopPropagation) {
            // Stop propagation
            e.stopPropagation();
            // Stop default action
            e.preventDefault();
        }
        if (localStorage.getItem('userId') && localStorage.getItem('accessToken')) {
            props.dispatch(liveVideoBookmarkSaveStart({ live_video_id: videoId }));
        } else {
            const notificationMessage = getErrorNotificationMessage("Please Login to add bookmark");
            props.dispatch(createNotification(notificationMessage));
        }
    }

    // useEffect(() => {
    //     if (!props.bookmarkedVideos.loading && props.bookmarkedVideos.data.live_video_bookmarks) {
    //         let filteredVideos = props.bookmarkedVideos.data.live_video_bookmarks.filter((elem) => elem.live_video_unique_id === live.live_video_unique_id);
    //         if (filteredVideos.length > 0) {
    //             setBookmarked(true);
    //             setBookmarkCount(filteredVideos[0].total_bookmarks);
    //         } else {
    //             setBookmarked(false);
    //             setBookmarkCount(live.total_bookmarks);
    //         }
    //     }
    // }, [props.bookmarkedVideos]);

    return (

        <div className="new-category-card">
            <div className="new-card-image">
                <Link to={`/live/${live.live_video_unique_id}`}>
                    <div className="thumbnail-card">
                        <LazyLoad height={200} once>
                            <img
                                src={live.preview_file}
                                alt=""
                                className="card-thumbnail-img"
                            />
                        </LazyLoad>
                        <div className="card-head-icon">
                            <div className="card-head-img">
                                {live.live_schedule_type === 1 ? <>
                                    <img
                                        src={window.location.origin + "/assets/images/new-home-page/card-head-icon.svg"}
                                        alt="" />
                                    <h5>{live.viewer_cnt}</h5>
                                </> :
                                    <div className="slider-top-content-new">{live.schedule_time_formatted}</div>}
                            </div>
                            <div className="card-top-img" onClick={e => bookmarkVideo(e, live.live_video_id)}>
                                {live.is_video_bookmarked ?
                                    <img
                                        src={window.location.origin + "/assets/images/new-home-page/card-top-active-icon.svg"}
                                        alt=""
                                    />
                                    : <img
                                        src={window.location.origin + "/assets/images/new-home-page/card-top-icon.svg"}
                                        alt=""
                                    />
                                }
                                <h5>{live.total_bookmarks}</h5>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to={`/${live.user_unique_id}`}>
                    <div className="category-profile-content">
                        <img
                            src={live.user_picture}
                            alt={live.user_displayname}
                        />
                        <h5>{live.user_displayname}</h5>
                    </div>
                </Link>
                <h4 className='live-title'>{live.title}</h4>
            </div>
            <Link to={`/category/` + live.category_unique_id}>
                <div className="category-card-link">
                    <a>{live.category_name}</a>
                </div>
            </Link>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
};

const mapStateToPros = (state) => ({
    bookmarkedVideos: state.liveVideo.bookmarkedVideos,
    liveBookmarkSave: state.liveVideo.liveBookmarkSave,
});

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(SingleVideoCard);