import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CategoryThumbnailList = (props) => {

    return (<>
        {!props.categories.loading ?
            <>
                {props.categories.data.categories && props.categories.data.categories.length > 0 &&
                    <div className="new-card-head pt-8">
                        <h3>{props.title}</h3>
                        <div className="category-tab-card">
                            <ul>
                                {props.categories.data.categories.map((category, i) =>
                                    <li key={i} className="category-thumb">
                                        {category.name && category.name !== "" &&
                                            <Link to={`/?category=${category.unique_id}`}>
                                                {category.name}
                                            </Link>
                                        }
                                        {category.category_name && category.category_name !== "" &&
                                            <Link to={`/?category=${category.category_unique_id}`} >
                                                {category.category_name}
                                            </Link>
                                        }
                                        {category.sub_category_name && category.sub_category_name !== "" &&
                                            <Link to={`/?subCategory=${category.sub_category_unique_id}`}>
                                                {category.sub_category_name}
                                            </Link>
                                        }
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                }
            </>
            : (!props.categories.error &&
                // <div className="new-card-head">
                //     <h3><Skeleton width={300} height={40} /></h3>
                //     <div className="category-tab-card">
                //         {[...Array(5)].map((e, i) =>
                //             <Skeleton key={i} className="thumbnail-loader" />
                //         )}
                //     </div>
                // </div>
                ''
            )
        }
    </>)
}

export default CategoryThumbnailList;