export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const FETCH_PAYMENTS_START = "FETCH_PAYMENTS_START";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

export const FETCH_CONTENT_CREATOR_DASHBOARD_START = "FETCH_CONTENT_CREATOR_DASHBOARD_START";
export const FETCH_CONTENT_CREATOR_DASHBOARD_SUCCESS = "FETCH_CONTENT_CREATOR_DASHBOARD_SUCCESS";
export const FETCH_CONTENT_CREATOR_DASHBOARD_FAILURE = "FETCH_CONTENT_CREATOR_DASHBOARD_FAILURE";

// Cards constants

export const FETCH_CARD_DETAILS_START = "FETCH_CARD_DETAILS_START";
export const FETCH_CARD_DETAILS_SUCCESS = "FETCH_CARD_DETAILS_SUCCESS";
export const FETCH_CARD_DETAILS_FAILURE = "FETCH_CARD_DETAILS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

// Bank account

export const ADD_BANK_ACCOUNT_START = "ADD_BANK_ACCOUNT_START";
export const ADD_BANK_ACCOUNT_CONTENT_CREATOR_FLOW_START = "ADD_BANK_ACCOUNT_CONTENT_CREATOR_FLOW_START";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const GET_BANK_ACCOUNT_START = "GET_BANK_ACCOUNT_START";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_FAILURE = "GET_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT_START = "DELETE_BANK_ACCOUNT_START";
export const DELETE_BANK_ACCOUNT_CONTENT_CREATOR_FLOW_START = "DELETE_BANK_ACCOUNT_CONTENT_CREATOR_FLOW_START";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";

export const ADD_BANK_ACCOUNT_DATA = "ADD_BANK_ACCOUNT_DATA";

export const FETCH_SINGLE_BANK_ACCOUNT_START =
  "FETCH_SINGLE_BANK_ACCOUNT_START";
export const FETCH_SINGLE_BANK_ACCOUNT_SUCCESS =
  "FETCH_SINGLE_BANK_ACCOUNT_SUCCESS";
export const FETCH_SINGLE_BANK_ACCOUNT_FAILURE =
  "FETCH_SINGLE_BANK_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BANK_ACCOUNT_START =
  "MAKE_DEFAULT_BANK_ACCOUNT_START";
export const MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS =
  "MAKE_DEFAULT_BANK_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BANK_ACCOUNT_FAILURE =
  "MAKE_DEFAULT_BANK_ACCOUNT_FAILURE";

export const GET_KYC_DOCUMENT_START = "GET_KYC_DOCUMENT_START";
export const GET_KYC_DOCUMENT_SUCCESS = "GET_KYC_DOCUMENT_SUCCESS";
export const GET_KYC_DOCUMENT_FAILURE = "GET_KYC_DOCUMENT_FAILURE";

export const ADD_KYC_DOCUMENT_START = "ADD_KYC_DOCUMENT_START";
export const ADD_KYC_DOCUMENT_SUCCESS = "ADD_KYC_DOCUMENT_SUCCESS";
export const ADD_KYC_DOCUMENT_FAILURE = "ADD_KYC_DOCUMENT_FAILURE";

// Wallet constants

export const FETCH_WALLET_DETAILS_START = "FETCH_WALLET_DETAILS_START";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const ADD_MONEY_VIA_CARD_START = "ADD_MONEY_VIA_CARD_START";
export const ADD_MONEY_VIA_CARD_SUCCESS = "ADD_MONEY_VIA_CARD_SUCCESS";
export const ADD_MONEY_VIA_CARD_FAILURE = "ADD_MONEY_VIA_CARD_FAILURE";

export const ADD_MONEY_VIA_BANK_START = "ADD_MONEY_VIA_BANK_START";
export const ADD_MONEY_VIA_BANK_SUCCESS = "ADD_MONEY_VIA_BANK_SUCCESS";
export const ADD_MONEY_VIA_BANK_FAILURE = "ADD_MONEY_VIA_BANK_FAILURE";

export const ADD_MONEY_VIA_PAYPAL_START = "ADD_MONEY_VIA_PAYPAL_START";
export const ADD_MONEY_VIA_PAYPAL_SUCCESS = "ADD_MONEY_VIA_PAYPAL_SUCCESS";
export const ADD_MONEY_VIA_PAYPAL_FAILURE = "ADD_MONEY_VIA_PAYPAL_FAILURE";

// Transaction constants.

export const FETCH_ALL_TRANSACTION_START = "FETCH_ALL_TRANSACTION_START";
export const FETCH_ALL_TRANSACTION_SUCCESS = "FETCH_ALL_TRANSACTION_SUCCESS";
export const FETCH_ALL_TRANSACTION_FAILURE = "FETCH_ALL_TRANSACTION_FAILURE";

export const FETCH_SENT_PAYMENT_TRANSACTION_START =
  "FETCH_SENT_PAYMENT_TRANSACTION_START";
export const FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_SENT_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_SENT_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_RECEIVED_PAYMENT_TRANSACTION_START =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_START";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_DEPOSIT_TRANSACTION_START =
  "FETCH_DEPOSIT_TRANSACTION_START";
export const FETCH_DEPOSIT_TRANSACTION_SUCCESS =
  "FETCH_DEPOSIT_TRANSACTION_SUCCESS";
export const FETCH_DEPOSIT_TRANSACTION_FAILURE =
  "FETCH_DEPOSIT_TRANSACTION_FAILURE";

// Invoice constants.

export const SAVE_BUSINESS_DETAILS_START = "SAVE_BUSINESS_DETAILS_START";
export const SAVE_BUSINESS_DETAILS_SUCCESS = "SAVE_BUSINESS_DETAILS_SUCCESS";
export const SAVE_BUSINESS_DETAILS_FAILURE = "SAVE_BUSINESS_DETAILS_FAILURE";

export const FETCH_BUSINESS_DETAILS_START = "FETCH_BUSINESS_DETAILS_START";
export const FETCH_BUSINESS_DETAILS_SUCCESS = "FETCH_BUSINESS_DETAILS_SUCCESS";
export const FETCH_BUSINESS_DETAILS_FAILURE = "FETCH_BUSINESS_DETAILS_FAILURE";

// Withdraw constants

export const SEND_WITHDRAW_REQUEST_START = "SEND_WITHDRAW_REQUEST_START";
export const SEND_WITHDRAW_REQUEST_SUCCESS = "SEND_WITHDRAW_REQUEST_SUCCESS";
export const SEND_WITHDRAW_REQUEST_FAILURE = "SEND_WITHDRAW_REQUEST_FAILURE";

export const FETCH_WITHDRAWALS_START = "FETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";

export const CANCEL_WITHDRAW_REQUEST_START = "CANCEL_WITHDRAW_REQUEST_START";
export const CANCEL_WITHDRAW_REQUEST_SUCCESS =
  "CANCEL_WITHDRAW_REQUEST_SUCCESS";
export const CANCEL_WITHDRAW_REQUEST_FAILURE =
  "CANCEL_WITHDRAW_REQUEST_FAILURE";

export const SEARCH_WITHDRAWALS_START = "SEARCH_WITHDRAWALS_START";
export const SEARCH_WITHDRAWALS_SUCCESS = "SEARCH_WITHDRAWALS_SUCCESS";
export const SEARCH_WITHDRAWALS_FAILURE = "SEARCH_WITHDRAWALS_FAILURE";

export const FETCH_SINGLE_WITHDRAWALS_START = "FETCH_SINGLE_WITHDRAWALS_START";
export const FETCH_SINGLE_WITHDRAWALS_SUCCESS =
  "FETCH_SINGLE_WITHDRAWALS_SUCCESS";
export const FETCH_SINGLE_WITHDRAWALS_FAILURE =
  "FETCH_SINGLE_WITHDRAWALS_FAILURE";

// Dispute constants

export const SEND_DISPUTE_REQUEST_START = "SEND_DISPUTE_REQUEST_START";
export const SEND_DISPUTE_REQUEST_SUCCESS = "SEND_DISPUTE_REQUEST_SUCCESS";
export const SEND_DISPUTE_REQUEST_FAILURE = "SEND_DISPUTE_REQUEST_FAILURE";

export const FETCH_DISPUTES_START = "FETCH_DISPUTES_START";
export const FETCH_DISPUTES_SUCCESS = "FETCH_DISPUTES_SUCCESS";
export const FETCH_DISPUTES_FAILURE = "FETCH_DISPUTES_FAILURE";

export const FETCH_OPENED_CASE_START = "FETCH_OPENED_CASE_START";
export const FETCH_OPENED_CASE_SUCCESS = "FETCH_OPENED_CASE_SUCCESS";
export const FETCH_OPENED_CASE_FAILURE = "FETCH_OPENED_CASE_FAILURE";

export const FETCH_CLOSED_CASE_START = "FETCH_CLOSED_CASE_START";
export const FETCH_CLOSED_CASE_SUCCESS = "FETCH_CLOSED_CASE_SUCCESS";
export const FETCH_CLOSED_CASE_FAILURE = "FETCH_CLOSED_CASE_FAILURE";

export const GET_SINGLE_DISPUTE_MESSAGES_START =
  "GET_SINGLE_DISPUTE_MESSAGES_START";
export const GET_SINGLE_DISPUTE_MESSAGES_SUCCESS =
  "GET_SINGLE_DISPUTE_MESSAGES_SUCCESS";
export const GET_SINGLE_DISPUTE_MESSAGES_FAILURE =
  "GET_SINGLE_DISPUTE_MESSAGES_FAILURE";

export const SEND_DISPUTE_MESSAGE_START = "SEND_DISPUTE_MESSAGE_START";
export const SEND_DISPUTE_MESSAGE_SUCCESS = "SEND_DISPUTE_MESSAGE_SUCCESS";
export const SEND_DISPUTE_MESSAGE_FAILURE = "SEND_DISPUTE_MESSAGE_FAILURE";

export const CANCEL_DISPUTE_REQUEST_START = "CANCEL_DISPUTE_REQUEST_START";
export const CANCEL_DISPUTE_REQUEST_SUCCESS = "CANCEL_DISPUTE_REQUEST_SUCCESS";
export const CANCEL_DISPUTE_REQUEST_FAILURE = "CANCEL_DISPUTE_REQUEST_FAILURE";

export const FETCH_SINGLE_DISPUTE_START = "FETCH_SINGLE_DISPUTE_START";
export const FETCH_SINGLE_DISPUTE_SUCCESS = "FETCH_SINGLE_DISPUTE_SUCCESS";
export const FETCH_SINGLE_DISPUTE_FAILURE = "FETCH_SINGLE_DISPUTE_FAILURE";

// Registeration verify.

export const REGISTER_VERIFY_START = "REGISTER_VERIFY_START";
export const REGISTER_VERIFY_SUCCESS = "REGISTER_VERIFY_SUCCESS";
export const REGISTER_VERIFY_FAILURE = "REGISTER_VERIFY_FAILURE";

export const REGISTER_VERIFY_RESEND_START = "REGISTER_VERIFY_RESEND_START";
export const REGISTER_VERIFY_RESEND_SUCCESS = "REGISTER_VERIFY_RESEND_SUCCESS";
export const REGISTER_VERIFY_RESEND_FAILURE = "REGISTER_VERIFY_RESEND_FAILURE";

// Pages

export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

// Notification on or off

export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Product owner constant

export const SAVE_PRO_OWNER_START = "SAVE_PRO_OWNER_START";
export const SAVE_PRO_OWNER_SUCCESS = "SAVE_PRO_OWNER_SUCCESS";
export const SAVE_PRO_OWNER_FAILURE = "SAVE_PRO_OWNER_FAILURE";

export const FETCH_SINGLE_PRO_OWNER_START = "FETCH_SINGLE_PRO_OWNER_START";
export const FETCH_SINGLE_PRO_OWNER_SUCCESS = "FETCH_SINGLE_PRO_OWNER_SUCCESS";
export const FETCH_SINGLE_PRO_OWNER_FAILURE = "FETCH_SINGLE_PRO_OWNER_FAILURE";

export const SET_VISIBILITY_PRO_OWNER_START = "SET_VISIBILITY_PRO_OWNER_START";
export const SET_VISIBILITY_PRO_OWNER_SUCCESS =
  "SET_VISIBILITY_PRO_OWNER_SUCCESS";
export const SET_VISIBILITY_PRO_OWNER_FAILURE =
  "SET_VISIBILITY_PRO_OWNER_FAILURE";

export const UPDATE_AVAILABILITY_PRO_OWNER_START =
  "UPDATE_AVAILABILITY_PRO_OWNER_START";
export const UPDATE_AVAILABILITY_PRO_OWNER_SUCCESS =
  "UPDATE_AVAILABILITY_PRO_OWNER_SUCCESS";
export const UPDATE_AVAILABILITY_PRO_OWNER_FAILURE =
  "UPDATE_AVAILABILITY_PRO_OWNER_FAILURE";

export const FETCH_PRODUCTS_PRO_OWNER_START = "FETCH_PRODUCTS_PRO_OWNER_START";
export const FETCH_PRODUCTS_PRO_OWNER_SUCCESS =
  "FETCH_PRODUCTS_PRO_OWNER_SUCCESS";
export const FETCH_PRODUCTS_PRO_OWNER_FAILURE =
  "FETCH_PRODUCTS_PRO_OWNER_FAILURE";

export const FETCH_PRO_CATE_PRO_OWNER_START = "FETCH_PRO_CATE_PRO_OWNER_START";
export const FETCH_PRO_CATE_PRO_OWNER_SUCCESS =
  "FETCH_PRO_CATE_PRO_OWNER_SUCCESS";
export const FETCH_PRO_CATE_PRO_OWNER_FAILURE =
  "FETCH_PRO_CATE_PRO_OWNER_FAILURE";

export const FETCH_PRO_SUBCATE_PRO_OWNER_START =
  "FETCH_PRO_SUBCATE_PRO_OWNER_START";
export const FETCH_PRO_SUBCATE_PRO_OWNER_SUCCESS =
  "FETCH_PRO_SUBCATE_PRO_OWNER_SUCCESS";
export const FETCH_PRO_SUBCATE_PRO_OWNER_FAILURE =
  "FETCH_PRO_SUBCATE_PRO_OWNER_FAILURE";

export const PRO_SEARCH_PRO_OWNER_START = "PRO_SEARCH_PRO_OWNER_START";
export const PRO_SEARCH_PRO_OWNER_SUCCESS = "PRO_SEARCH_PRO_OWNER_SUCCESS";
export const PRO_SEARCH_PRO_OWNER_FAILURE = "PRO_SEARCH_PRO_OWNER_FAILURE";

export const FETCH_PRO_IMAGE_PRO_OWNER_START =
  "FETCH_PRO_IMAGE_PRO_OWNER_START";
export const FETCH_PRO_IMAGE_PRO_OWNER_SUCCESS =
  "FETCH_PRO_IMAGE_PRO_OWNER_SUCCESS";
export const FETCH_PRO_IMAGE_PRO_OWNER_FAILURE =
  "FETCH_PRO_IMAGE_PRO_OWNER_FAILURE";

export const SAVE_PRO_IMAGE_PRO_OWNER_START = "SAVE_PRO_IMAGE_PRO_OWNER_START";
export const SAVE_PRO_IMAGE_PRO_OWNER_SUCCESS =
  "SAVE_PRO_IMAGE_PRO_OWNER_SUCCESS";
export const SAVE_PRO_IMAGE_PRO_OWNER_FAILURE =
  "SAVE_PRO_IMAGE_PRO_OWNER_FAILURE";

export const DELETE_PRO_IMAGE_PRO_OWNER_START =
  "DELETE_PRO_IMAGE_PRO_OWNER_START";
export const DELETE_PRO_IMAGE_PRO_OWNER_SUCCESS =
  "DELETE_PRO_IMAGE_PRO_OWNER_SUCCESS";
export const DELETE_PRO_IMAGE_PRO_OWNER_FAILURE =
  "DELETE_PRO_IMAGE_PRO_OWNER_FAILURE";

export const FETCH_EXPLORE_START = "FETCH_EXPLORE_START";
export const FETCH_EXPLORE_SUCCESS = "FETCH_EXPLORE_SUCCESS";
export const FETCH_EXPLORE_FAILURE = "FETCH_EXPLORE_FAILURE";

export const FETCH_REPORT_REASON_START = " FETCH_REPORT_REASON_START";
export const FETCH_REPORT_REASON_SUCCESS = " FETCH_REPORT_REASON_SUCCESS";
export const FETCH_REPORT_REASON_FAILURE = " FETCH_REPORT_REASON_FAILURE";

// Follow constants

export const FOLLOW_USER_START = "FOLLOW_USER_START";
export const FOLLOW_USER_SUCCESS = "FOLLOW_USER_SUCCESS";
export const FOLLOW_USER_FAILURE = "FOLLOW_USER_FAILURE";

export const UNFOLLOW_USER_START = "UNFOLLOW_USER_START";
export const UNFOLLOW_USER_SUCCESS = "UNFOLLOW_USER_SUCCESS";
export const UNFOLLOW_USER_FAILURE = "UNFOLLOW_USER_FAILURE";

export const FETCH_FOLLOWERS_START = "FETCH_FOLLOWERS_START";
export const FETCH_FOLLOWERS_SUCCESS = "FETCH_FOLLOWERS_SUCCESS";
export const FETCH_FOLLOWERS_FAILURE = "FETCH_FOLLOWERS_FAILURE";

export const FETCH_ACTIVE_FOLLOWERS_START = "FETCH_ACTIVE_FOLLOWERS_START";
export const FETCH_ACTIVE_FOLLOWERS_SUCCESS = "FETCH_ACTIVE_FOLLOWERS_SUCCESS";
export const FETCH_ACTIVE_FOLLOWERS_FAILURE = "FETCH_ACTIVE_FOLLOWERS_FAILURE";

export const FETCH_EXPIRED_FOLLOWERS_START = "FETCH_EXPIRED_FOLLOWERS_START";
export const FETCH_EXPIRED_FOLLOWERS_SUCCESS =
  "FETCH_EXPIRED_FOLLOWERS_SUCCESS";
export const FETCH_EXPIRED_FOLLOWERS_FAILURE =
  "FETCH_EXPIRED_FOLLOWERS_FAILURE";

export const FETCH_FOLLOWING_START = "FETCH_FOLLOWING_START";
export const FETCH_FOLLOWING_SUCCESS = "FETCH_FOLLOWING_SUCCESS";
export const FETCH_FOLLOWING_FAILURE = "FETCH_FOLLOWING_FAILURE";

export const FETCH_ACTIVE_FOLLOWING_START = "FETCH_ACTIVE_FOLLOWING_START";
export const FETCH_ACTIVE_FOLLOWING_SUCCESS = "FETCH_ACTIVE_FOLLOWING_SUCCESS";
export const FETCH_ACTIVE_FOLLOWING_FAILURE = "FETCH_ACTIVE_FOLLOWING_FAILURE";

export const FETCH_EXPIRED_FOLLOWING_START = "FETCH_EXPIRED_FOLLOWING_START";
export const FETCH_EXPIRED_FOLLOWING_SUCCESS =
  "FETCH_EXPIRED_FOLLOWING_SUCCESS";
export const FETCH_EXPIRED_FOLLOWING_FAILURE =
  "FETCH_EXPIRED_FOLLOWING_FAILURE";

// Document verification

export const FETCH_VERI_DOCUMENT_START = "FETCH_VERI_DOCUMENT_START";
export const FETCH_VERI_DOCUMENT_SUCCESS = "FETCH_VERI_DOCUMENT_SUCCESS";
export const FETCH_VERI_DOCUMENT_FAILURE = "FETCH_VERI_DOCUMENT_FAILURE";

export const SAVE_VERI_DOC_START = "SAVE_VERI_DOC_START";
export const SAVE_VERI_DOC_SUCCESS = "SAVE_VERI_DOC_SUCCESS";
export const SAVE_VERI_DOC_FAILURE = "SAVE_VERI_DOC_FAILURE";

export const DEL_VERI_DOC_START = "DEL_VERI_DOC_START";
export const DEL_VERI_DOC_SUCCESS = "DEL_VERI_DOC_SUCCESS";
export const DEL_VERI_DOC_FAILURE = "DEL_VERI_DOC_FAILURE";

export const VERI_STATUS_CHECK_START = "VERI_STATUS_CHECK_START";
export const VERI_STATUS_CHECK_SUCCESS = "VERI_STATUS_CHECK_SUCCESS";
export const VERI_STATUS_CHECK_FAILURE = "VERI_STATUS_CHECK_FAILURE";

// Bookmark Management constant

export const FETCH_BOOKMARKS_START = "FETCH_BOOKMARKS_START";
export const FETCH_BOOKMARKS_SUCCESS = "FETCH_BOOKMARKS_SUCCESS";
export const FETCH_BOOKMARKS_FAILURE = "FETCH_BOOKMARKS_FAILURE";

export const FETCH_BOOKMARKS_PHOTO_START = "FETCH_BOOKMARKS_PHOTO_START";
export const FETCH_BOOKMARKS_PHOTO_SUCCESS = "FETCH_BOOKMARKS_PHOTO_SUCCESS";
export const FETCH_BOOKMARKS_PHOTO_FAILURE = "FETCH_BOOKMARKS_PHOTO_FAILURE";

export const SAVE_BOOKMARK_START = "SAVE_BOOKMARK_START";
export const SAVE_BOOKMARK_SUCCESS = "SAVE_BOOKMARK_SUCCESS";
export const SAVE_BOOKMARK_FAILURE = "SAVE_BOOKMARK_FAILURE";

export const DELETE_BOOKMARK_START = "DELETE_BOOKMARK_START";
export const DELETE_BOOKMARK_SUCCESS = "DELETE_BOOKMARK_SUCCESS";
export const DELETE_BOOKMARK_FAILURE = "DELETE_BOOKMARK_FAILURE";

// Home constants

export const SEARCH_USER_START = "SEARCH_USER_START";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE";

export const FETCH_TRENDING_USERS_START = "FETCH_TRENDING_USERS_START";
export const FETCH_TRENDING_USERS_SUCCESS = "FETCH_TRENDING_USERS_SUCCESS";
export const FETCH_TRENDING_USERS_FAILURE = "FETCH_TRENDING_USERS_FAILURE";

export const FETCH_LISTS_DETAILS_START = "FETCH_LISTS_DETAILS_START";
export const FETCH_LISTS_DETAILS_SUCCESS = "FETCH_LISTS_DETAILS_SUCCESS";
export const FETCH_LISTS_DETAILS_FAILURE = "FETCH_LISTS_DETAILS_FAILURE";

/// Model single profile

export const FETCH_SINGLE_USER_PROFILE_START =
  "FETCH_SINGLE_USER_PROFILE_START";
export const FETCH_SINGLE_USER_PROFILE_SUCCESS =
  "FETCH_SINGLE_USER_PROFILE_SUCCESS";
export const FETCH_SINGLE_USER_PROFILE_FAILURE =
  "FETCH_SINGLE_USER_PROFILE_FAILURE";

// Notification constant

export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const SAVE_BLOCK_USER_START = "SAVE_BLOCK_USER_START";
export const SAVE_BLOCK_USER_SUCCESS = "SAVE_BLOCK_USER_SUCCESS";
export const SAVE_BLOCK_USER_FAILURE = "SAVE_BLOCK_USER_FAILURE";

export const FETCH_BLOCK_USERS_START = "FETCH_BLOCK_USERS_START";
export const FETCH_BLOCK_USERS_SUCCESS = "FETCH_BLOCK_USERS_SUCCESS";
export const FETCH_BLOCK_USERS_FAILURE = "FETCH_BLOCK_USERS_FAILURE";

export const USER_VERIFY_BADGE_STATUS_START = "USER_VERIFY_BADGE_STATUS_START";
export const USER_VERIFY_BADGE_STATUS_SUCCESS =
  "USER_VERIFY_BADGE_STATUS_SUCCESS";
export const USER_VERIFY_BADGE_STATUS_FAILURE =
  "USER_VERIFY_BADGE_STATUS_FAILURE";

// Categories constants

export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FETCH_SUB_CATEGORIES_START = "FETCH_SUB_CATEGORIES_START";
export const FETCH_SUB_CATEGORIES_SUCCESS = "FETCH_SUB_CATEGORIES_SUCCESS";
export const FETCH_SUB_CATEGORIES_FAILURE = "FETCH_SUB_CATEGORIES_FAILURE";

export const FETCH_CATEGORIES_LIST_START = "FETCH_CATEGORIES_LIST_START";
export const FETCH_CATEGORIES_LIST_SUCCESS = "FETCH_CATEGORIES_LIST_SUCCESS";
export const FETCH_CATEGORIES_LIST_FALURE = "FETCH_CATEGORIES_LIST_FALURE";

export const UPLOAD_ASSET_DETAILS = "UPLOAD_ASSET_DETAILS";

export const FETCH_CHAT_ASSETS_START = "FETCH_CHAT_ASSETS_START";
export const FETCH_CHAT_ASSETS_SUCCESS = "FETCH_CHAT_ASSETS_SUCCESS";
export const FETCH_CHAT_ASSETS_FAILURE = "FETCH_CHAT_ASSETS_FAILURE";
// Reset Password
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const USERNAME_VALIDATION_START = "USERNAME_VALIDATION_START";
export const USERNAME_VALIDATION_SUCCESS = "USERNAME_VALIDATION_SUCCESS";
export const USERNAME_VALIDATION_FAILURE = "USERNAME_VALIDATION_FAILURE";

export const LIVE_VIDEO_CALL_BROADCAST_START = "LIVE_VIDEO_CALL_BROADCAST_START";
export const LIVE_VIDEO_CALL_BROADCAST_SUCCESS = "LIVE_VIDEO_CALL_BROADCAST_SUCCESS";
export const LIVE_VIDEO_CALL_BROADCAST_FAILURE = "LIVE_VIDEO_CALL_BROADCAST_FAILURE";

export const LIVE_VIDEOS_START_CALL_START = "LIVE_VIDEOS_START_CALL_START";
export const LIVE_VIDEOS_START_CALL_SUCCESS = "LIVE_VIDEOS_START_CALL_SUCCESS";
export const LIVE_VIDEOS_START_CALL_FAILURE = "LIVE_VIDEOS_START_CALL_FAILURE";

export const FETCH_LIVE_VIDEOS_START = "FETCH_LIVE_VIDEOS_START";
export const FETCH_LIVE_VIDEOS_SUCCESS = "FETCH_LIVE_VIDEOS_SUCCESS";
export const FETCH_LIVE_VIDEOS_FAILURE = "FETCH_LIVE_VIDEOS_FAILURE";

export const FETCH_LIVE_VIDEOS_HISTORY_START = "FETCH_LIVE_VIDEOS_HISTORY_START";
export const FETCH_LIVE_VIDEOS_HISTORY_SUCCESS = "FETCH_LIVE_VIDEOS_HISTORY_SUCCESS";
export const FETCH_LIVE_VIDEOS_HISTORY_FAILURE = "FETCH_LIVE_VIDEOS_HISTORY_FAILURE";
export const FETCH_MORE_LIVE_VIDEOS_HISTORY_START = "FETCH_MORE_LIVE_VIDEOS_HISTORY_START";

export const FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START = "FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START";
export const FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS = "FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_SUCCESS";
export const FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_FAILURE = "FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_FAILURE";

export const JOIN_LIVE_VIDEOS_START = "JOIN_LIVE_VIDEOS_START";
export const JOIN_LIVE_VIDEOS_SUCCESS = "JOIN_LIVE_VIDEOS_SUCCESS";
export const JOIN_LIVE_VIDEOS_FAILURE = "JOIN_LIVE_VIDEOS_FAILURE";

export const FETCH_SINGLE_LIVE_VIDEOS_START = "FETCH_SINGLE_LIVE_VIDEOS_START";
export const FETCH_SINGLE_LIVE_VIDEOS_SUCCESS = "FETCH_SINGLE_LIVE_VIDEOS_SUCCESS";
export const FETCH_SINGLE_LIVE_VIDEOS_FAILURE = "FETCH_SINGLE_LIVE_VIDEOS_FAILURE";


export const LIVE_VIDEOS_PAYMENT_BY_STRIPE_START = "LIVE_VIDEOS_PAYMENT_BY_STRIPE_START";
export const LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS = "LIVE_VIDEOS_PAYMENT_BY_STRIPE_SUCCESS";
export const LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE = "LIVE_VIDEOS_PAYMENT_BY_STRIPE_FAILURE";

export const LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START = "LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START";
export const LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS = "LIVE_VIDEOS_PAYMENT_BY_PAYPAL_SUCCESS";
export const LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE = "LIVE_VIDEOS_PAYMENT_BY_PAYPAL_FAILURE";

export const LIVE_VIDEOS_VIEWER_UPDATE_START = "LIVE_VIDEOS_VIEWER_UPDATE_START";
export const LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS = "LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS";
export const LIVE_VIDEOS_VIEWER_UPDATE_FAILURE = "LIVE_VIDEOS_VIEWER_UPDATE_FAILURE";

export const LIVE_VIDEOS_END_START = "LIVE_VIDEOS_END_START";
export const LIVE_VIDEOS_END_SUCCESS = "LIVE_VIDEOS_END_SUCCESS";
export const LIVE_VIDEOS_END_FAILURE = "LIVE_VIDEOS_END_FAILURE";

export const LIVE_VIDEO_BOOKMARK_SAVE_START = "LIVE_VIDEO_BOOKMARK_SAVE_START";
export const LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS = "LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS";
export const LIVE_VIDEO_BOOKMARK_SAVE_FAILURE = "LIVE_VIDEO_BOOKMARK_SAVE_FAILURE";


// one to one live streaming

export const GET_REFERRAL_START = "GET_REFERRAL_START";
export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS";
export const GET_REFERRAL_FAILURE = "GET_REFERRAL_FAILURE";

export const REFERRAL_VALIDATION_START = "REFERRAL_VALIDATION_START";
export const REFERRAL_VALIDATION_SUCCESS = "REFERRAL_VALIDATION_SUCCESS";
export const REFERRAL_VALIDATION_FAILURE = "REFERRAL_VALIDATION_FAILURE";


export const FETCH_USER_CATEGORY_LIST_START = "FETCH_USER_CATEGORY_LIST_START";
export const FETCH_USER_CATEGORY_LIST_SUCCESS = "FETCH_USER_CATEGORY_LIST_SUCCESS";
export const FETCH_USER_CATEGORY_LIST_FAILURE = "FETCH_USER_CATEGORY_LIST_FAILURE";

export const FETCH_CONTENT_CREATOR_LIST_START = "FETCH_CONTENT_CREATOR_LIST_START";
export const FETCH_CONTENT_CREATOR_LIST_SUCCESS = "FETCH_CONTENT_CREATOR_LIST_SUCCESS";
export const FETCH_CONTENT_CREATOR_LIST_FAILURE = "FETCH_CONTENT_CREATOR_LIST_FAILURE";

// E-Comm
export const FETCH_USER_PRODUCTS_START = "FETCH_USER_PRODUCTS_START";
export const FETCH_USER_PRODUCTS_SUCCESS = "FETCH_USER_PRODUCTS_SUCCESS";
export const FETCH_USER_PRODUCTS_FAILURE = "FETCH_USER_PRODUCTS_FAILURE";

export const USER_PRODUCTS_SAVE_START = "USER_PRODUCTS_SAVE_START";
export const USER_PRODUCTS_SAVE_SUCCESS = "USER_PRODUCTS_SAVE_SUCCESS";
export const USER_PRODUCTS_SAVE_FAILURE = "USER_PRODUCTS_SAVE_FAILURE";

export const FETCH_USER_SINGLE_PRODUCT_START = "FETCH_USER_SINGLE_PRODUCT_START";
export const FETCH_USER_SINGLE_PRODUCT_SUCCESS = "FETCH_USER_SINGLE_PRODUCT_SUCCESS";
export const FETCH_USER_SINGLE_PRODUCT_FAILURE = "FETCH_USER_SINGLE_PRODUCT_FAILURE";

export const DELETE_USER_PRODUCT_START = "DELETE_USER_PRODUCT_START";
export const DELETE_USER_PRODUCT_SUCCESS = "DELETE_USER_PRODUCT_SUCCESS";
export const DELETE_USER_PRODUCT_FAILURE = "DELETE_USER_PRODUCT_FAILURE";

export const SET_USER_PRODUCT_VISIBILITY_START = "SET_USER_PRODUCT_VISIBILITY_START";
export const SET_USER_PRODUCT_VISIBILITY_SUCCESS = "SET_USER_PRODUCT_VISIBILITY_SUCCESS";
export const SET_USER_PRODUCT_VISIBILITY_FAILURE = "SET_USER_PRODUCT_VISIBILITY_FAILURE";

export const UPDATE_USER_PRODUCT_AVAILABILITY_START = "UPDATE_USER_PRODUCT_AVAILABILITY_START";
export const UPDATE_USER_PRODUCT_AVAILABILITY_SUCCESS = "UPDATE_USER_PRODUCT_AVAILABILITY_SUCCESS";
export const UPDATE_USER_PRODUCT_AVAILABILITY_FAILURE = "UPDATE_USER_PRODUCT_AVAILABILITY_FAILURE";

export const FETCH_PRODUCT_CATEGORIES_START = "FETCH_PRODUCT_CATEGORIES_START";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAILURE = "FETCH_PRODUCT_CATEGORIES_FAILURE";

export const FETCH_PRODUCT_SUB_CATEGORIES_START = "FETCH_PRODUCT_SUB_CATEGORIES_START";
export const FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS = "FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_SUB_CATEGORIES_FAILURE = "FETCH_PRODUCT_SUB_CATEGORIES_FAILURE";

export const FETCH_CART_LIST_START = "FETCH_CART_LIST_START";
export const FETCH_CART_LIST_SUCCESS = "FETCH_CART_LIST_SUCCESS";
export const FETCH_CART_LIST_FAILURE = "FETCH_CART_LIST_FAILURE";

export const SAVE_CART_DETAILS_START = "SAVE_CART_DETAILS_START";
export const SAVE_CART_DETAILS_SUCCESS = "SAVE_CART_DETAILS_SUCCESS";
export const SAVE_CART_DETAILS_FAILURE = "SAVE_CART_DETAILS_FAILURE";

export const REMOVE_CART_DETAILS_START = "REMOVE_CART_DETAILS_START";
export const REMOVE_CART_DETAILS_SUCCESS = "REMOVE_CART_DETAILS_SUCCESS";
export const REMOVE_CART_DETAILS_FAILURE = "REMOVE_CART_DETAILS_FAILURE";

export const USER_PRODUCTS_SEARCH_START = "USER_PRODUCTS_SEARCH_START";
export const USER_PRODUCTS_SEARCH_SUCCESS = "USER_PRODUCTS_SEARCH_SUCCESS";
export const USER_PRODUCTS_SEARCH_FAILURE = "USER_PRODUCTS_SEARCH_FAILURE";

export const FETCH_USER_PRODUCT_PICTURES_START = "FETCH_USER_PRODUCT_PICTURES_START";
export const FETCH_USER_PRODUCT_PICTURES_SUCCESS = "FETCH_USER_PRODUCT_PICTURES_SUCCESS";
export const FETCH_USER_PRODUCT_PICTURES_FAILURE = "FETCH_USER_PRODUCT_PICTURES_FAILURE";

export const USER_PRODUCT_PICTURES_SAVE_START = "USER_PRODUCT_PICTURES_SAVE_START";
export const USER_PRODUCT_PICTURES_SAVE_SUCCESS = "USER_PRODUCT_PICTURES_SAVE_SUCCESS";
export const USER_PRODUCT_PICTURES_SAVE_FAILURE = "USER_PRODUCT_PICTURES_SAVE_FAILURE";

export const USER_PRODUCT_PICTURES_DELETE_START = "USER_PRODUCT_PICTURES_DELETE_START";
export const USER_PRODUCT_PICTURES_DELETE_SUCCESS = "USER_PRODUCT_PICTURES_DELETE_SUCCESS";
export const USER_PRODUCT_PICTURES_DELETE_FAILURE = "USER_PRODUCT_PICTURES_DELETE_FAILURE";

export const FETCH_HOME_CATEGORIES_START = "FETCH_HOME_CATEGORIES_START";
export const FETCH_HOME_CATEGORIES_SUCCESS = "FETCH_HOME_CATEGORIES_SUCCESS";
export const FETCH_HOME_CATEGORIES_FAILURE = "FETCH_HOME_CATEGORIES_FAILURE";

export const LIVE_STREAMINGS_ONGOING_START = "LIVE_STREAMINGS_ONGOING_START";
export const LIVE_STREAMINGS_ONGOING_SUCCESS = "LIVE_STREAMINGS_ONGOING_SUCCESS";
export const LIVE_STREAMINGS_ONGOING_FAILURE = "LIVE_STREAMINGS_ONGOING_FAILURE";

export const UPCOMMING_LIVE_STREAMINGS_START = "UPCOMMING_LIVE_STREAMINGS_START";
export const UPCOMMING_LIVE_STREAMINGS_SUCCESS = "UPCOMMING_LIVE_STREAMINGS_SUCCESS";
export const UPCOMMING_LIVE_STREAMINGS_FAILURE = "UPCOMMING_LIVE_STREAMINGS_FAILURE";

export const FETCH_HOME_FEATURED_SELLERS_START = "FETCH_HOME_FEATURED_SELLERS";
export const FETCH_HOME_FEATURED_SELLERS_SUCCESS = "FETCH_HOME_FEATURED_SELLERS_SUCCESS";
export const FETCH_HOME_FEATURED_SELLERS_FAILURE = "FETCH_HOME_FEATURED_SELLERS";

export const ORDERS_LIST_FOR_OTHERS_START = "ORDERS_LIST_FOR_OTHERS_START";
export const ORDERS_LIST_FOR_OTHERS_SUCCESS = "ORDERS_LIST_FOR_OTHERS_SUCCESS";
export const ORDERS_LIST_FOR_OTHERS_FAILURE = "ORDERS_LIST_FOR_OTHERS_FAILURE";

export const FETCH_DELIVERY_ADDRESS_START = "FETCH_DELIVERY_ADDRESS_START";
export const FETCH_DELIVERY_ADDRESS_SUCCESS = "FETCH_DELIVERY_ADDRESS_SUCCESS";
export const FETCH_DELIVERY_ADDRESS_FAILURE = "FETCH_DELIVERY_ADDRESS_FAILURE";

export const SAVE_DELIVERY_ADDRESS_START = "SAVE_DELIVERY_ADDRESS_START";
export const SAVE_DELIVERY_ADDRESS_SUCCESS = "SAVE_DELIVERY_ADDRESS_SUCCESS";
export const SAVE_DELIVERY_ADDRESS_FAILURE = "SAVE_DELIVERY_ADDRESS_FAILURE";

export const DELETE_DELIVERY_ADDRESS_START = "DELETE_DELIVERY_ADDRESS_START";
export const DELETE_DELIVERY_ADDRESS_SUCCESS = "DELETE_DELIVERY_ADDRESS_SUCCESS";
export const DELETE_DELIVERY_ADDRESS_FAILURE = "DELETE_DELIVERY_ADDRESS_FAILURE";

export const SET_DEFAULT_DELIVERY_ADDRESS_START = "SET_DEFAULT_DELIVERY_ADDRESS_START";
export const SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS = "SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS";
export const SET_DEFAULT_DELIVERY_ADDRESS_FAILURE = "SET_DEFAULT_DELIVERY_ADDRESS_FAILURE";

export const FETCH_ORDER_LIST_START = "FETCH_ORDER_LIST_START";
export const FETCH_ORDER_LIST_SUCCESS = "FETCH_ORDER_LIST_SUCCESS";
export const FETCH_ORDER_LIST_FAILURE = "FETCH_ORDER_LIST_FAILURE";

export const ORDER_LIST_FOR_SELLER_START = "ORDER_LIST_FOR_SELLER_START";
export const ORDER_LIST_FOR_SELLER_SUCCESS = "ORDER_LIST_FOR_SELLER_SUCCESS";
export const ORDER_LIST_FOR_SELLER_FAILURE = "ORDER_LIST_FOR_SELLER_FAILURE";

export const FETCH_ORDER_DETAILS_START = "FETCH_ORDER_DETAILS_START";
export const FETCH_ORDER_DETAILS_SUCCESS = "FETCH_ORDER_DETAILS_SUCCESS";
export const FETCH_ORDER_DETAILS_FAILURE = "FETCH_ORDER_DETAILS_FAILURE";

export const CREATE_ORDER_BY_WALLET_START = "CREATE_ORDER_BY_WALLET";
export const CREATE_ORDER_BY_WALLET_SUCCESS = "CREATE_ORDER_BY_WALLET_SUCCESS";
export const CREATE_ORDER_BY_WALLET_FAILURE = "CREATE_OrDER_BY_WALLET_FAILURE";

export const CREATE_ORDER_BY_STRIPE_START = "CREATE_ORDER_BY_STRIPE_START";
export const CREATE_ORDER_BY_STRIPE_SUCCESS = "CREATE_ORDER_BY_STRIPE_SUCCESS";
export const CREATE_ORDER_BY_STRIPE_FAILURE = "CREATE_ORDER_BY_STRIPE_FAILURE";

export const UPDATE_ORDER_STATUS_START = "UPDATE_ORDER_START";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE";

export const CANCEL_ORDER_START = "CANCEL_ORDER_START";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const FETCH_ORDER_PAYMENT_LIST_START = "FETCH_ORDER_PAYMENT_LIST_START";
export const FETCH_ORDER_PAYMENT_LIST_SUCCESS = "FETCH_ORDER_PAYMENT_LIST_SUCCESS";
export const FETCH_ORDER_PAYMENT_LIST_FAILURE = "FETCH_ORDER_PAYMENT_LIST_FAILURE";

export const FOLLOW_CATEGORIES_START = "FOLLOW_CATEGORIES_START";
export const FOLLOW_CATEGORIES_SUCCESS = "FOLLOW_CATEGORIES_SUCCESS";
export const FOLLOW_CATEGORIES_FAILURE = "FOLLOW_CATEGORIES_FAILURE";


export const FETCH_ECOMM_HOME_START = "FETCH_ECOMM_HOME_START";
export const FETCH_ECOMM_HOME_SUCCESS = "FETCH_ECOMM_HOME_SUCCESS";
export const FETCH_ECOMM_HOME_FAILURE = "FETCH_ECOMM_HOME_FAILURE";

export const FETCH_LIVE_VIDEO_MESSAGE_START = "FETCH_LIVE_VIDEO_MESSAGE_START";
export const FETCH_LIVE_VIDEO_MESSAGE_SUCCESS = "FETCH_LIVE_VIDEO_MESSAGE_SUCCESS";
export const FETCH_LIVE_VIDEO_MESSAGE_FAILURE = "FETCH_LIVE_VIDEO_MESSAGE_FAILURE";

export const ADD_LIVE_VIDEO_MESSAGE_CONTENT = "ADD_LIVE_VIDEO_MESSAGE_CONTENT";
export const FETCH_ONGOING_LIVE_VIDEOS_START = "FETCH_ONGOING_LIVE_VIDEOS_START";
export const FETCH_ONGOING_LIVE_VIDEOS_SUCCESS = "FETCH_ONGOING_LIVE_VIDEOS_SUCCESS";
export const FETCH_ONGOING_LIVE_VIDEOS_FAILURE = "FETCH_ONGOING_LIVE_VIDEOS_FAILURE";

export const FETCH_FOLLOWING_CATEGORIES_START = "FETCH_FOLLOWING_CATEGORIES_START";
export const FETCH_FOLLOWING_CATEGORIES_SUCCESS = "FETCH_FOLLOWING_CATEGORIES_SUCCESS";
export const FETCH_FOLLOWING_CATEGORIES_FAILURE = "FETCH_FOLLOWING_CATEGORIES_FAILURE";

export const FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_START = "FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_START";
export const FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_SUCCESS = "FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_SUCCESS";
export const FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_FAILURE = "FETCH_FOLLOWING_CATEGORIES_LIVE_VIDEOS_FAILURE";

export const FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_START = "FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_START";
export const FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_SUCCESS = "FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_SUCCESS";
export const FETCH_EXPLORE_CATEGORIES_LIVE_VIDEOS_FAILURE = "FETCH_EXPOLRE_CATEGORIES_LIVE_VIDEOS_FAILURE";

export const FETCH_RECENT_CATEGORIES_START = "FETCH_RECENT_CATEGORIES_START";
export const FETCH_RECENT_CATEGORIES_SUCCESS = "FETCH_RECENT_CATEGORIES_SUCCESS";
export const FETCH_RECENT_CATEGORIES_FAILURE = "FETCH_RECENT_CATEGORIES_FAILURE";

export const FETCH_CATEGORY_VIDEOS_START = "FETCH_CATEGORY_VIDEOS_START";
export const FETCH_CATEGORY_VIDEOS_SUCCESS = "FETCH_CATEGORY_VIDEOS_SUCCESS";
export const FETCH_CATEGORY_VIDEOS_FAILURE = "FETCH_CATEGORY_VIDEOS_FAILURE";

export const FETCH_SUB_CATEGORY_VIDEOS_START = "FETCH_SUB_CATEGORY_VIDEOS_START";
export const FETCH_SUB_CATEGORY_VIDEOS_SUCCESS = "FETCH_SUB_CATEGORY_VIDEOS_SUCCESS";
export const FETCH_SUB_CATEGORY_VIDEOS_FAILURE = "FETCH_SUB_CATEGORY_VIDEOS_FAILURE";

export const FETCH_LIVE_VIDEOS_PRODUCTS_START = "FETCH_LIVE_VIDEOS_PRODUCTS_START";
export const FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS = "FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS";
export const FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE = "FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE";

export const FETCH_BOOKMARKED_VIDEOS_START = "FECTH_BOOKMARKED_VIDEOS_START";
export const FETCH_BOOKMARKED_VIDEOS_SUCCESS = "FETCH_BOOKMARKED_VIDOES_SUCCESS";
export const FETCH_BOOKMARKED_VIDEOS_FAILURE = "FETCH_BOOKMARKED_VIDEOS_FAILURE";

export const ORDERS_VIEW_FOR_OTHERS_START = "ORDERS_VIEW_FOR_OTHERS_START";
export const ORDERS_VIEW_FOR_OTHERS_SUCCESS = "ORDERS_VIEW_FOR_OTHERS_SUCCESS";
export const ORDERS_VIEW_FOR_OTHERS_FAILURE = "ORDERS_VIEW_FOR_OTHERS_FAILURE";

export const FETCH_USER_PRODUCTS_FOR_OWNER_START = "FETCH_USER_PRODUCTS_FOR_OWNER_START";
export const FETCH_USER_PRODUCTS_FOR_OWNER_SUCCESS = "FETCH_USER_PRODUCTS_FOR_OWNER_SUCCESS";
export const FETCH_USER_PRODUCTS_FOR_OWNER_FAILURE = "FETCH_USER_PRODUCTS_FOR_OWNER_FAILURE";
export const FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START ="FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START";

export const FETCH_SINGLE_PRODUCT_ORDERS_START = "FETCH_SINGLE_PRODUCT_ORDERS_START";
export const FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS = "FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS";
export const FETCH_SINGLE_PRODUCT_ORDERS_FAILURE = "FETCH_SINGLE_PRODUCT_ORDERS_FAILURE";

export const USER_PRODUCT_VIEW_FOR_OTHERS_START =
  "USER_PRODUCT_VIEW_FOR_OTHERS_START";
export const USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS =
  "USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS";
export const USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE =
  "USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE";

export const FETCH_LIVE_VIDEO_ORDERS_START = "FETCH_LIVE_VIDEO_ORDERS_START";
export const FETCH_LIVE_VIDEO_ORDERS_SUCCESS = "FETCH_LIVE_VIDEO_ORDERS_SUCCESS";
export const FETCH_LIVE_VIDEO_ORDERS_FAILURE = "FETCH_LIVE_VIDEO_ORDERS_FAILURE";

export const RESET_ALL_VIDEO_LIST = "RESET_ALL_VIDEO_LIST";

export const FETCH_OTHER_MODEL_PRODUCT_LIST_START = "FETCH_OTHER_MODEL_PRODUCT_LIST_START";
export const FETCH_OTHER_MODEL_PRODUCT_LIST_SUCCESS = "FETCH_OTHER_MODEL_PRODUCT_LIST_SUCCESS";
export const FETCH_OTHER_MODEL_PRODUCT_LIST_FAILURE = "FETCH_OTHER_MODEL_PRODUCT_LIST_FAILURE";

export const ORDERS_PAYMENT_BY_WALLET_START = "ORDERS_PAYMENT_BY_WALLET_START";
export const ORDERS_PAYMENT_BY_WALLET_SUCCESS = "ORDERS_PAYMENT_BY_WALLET_SUCCESS";
export const ORDERS_PAYMENT_BY_WALLET_FAILURE = "ORDERS_PAYMENT_BY_WALLET_FAILURE";

export const ORDERS_PAYMENT_BY_CARD_START = "ORDERS_PAYMENT_BY_CARD_START";
export const ORDERS_PAYMENT_BY_CARD_SUCCESS = "ORDERS_PAYMENT_BY_CARD_SUCCESS";
export const ORDERS_PAYMENT_BY_CARD_FAILURE = "ORDERS_PAYMENT_BY_CARD_FAILURE";

export const ORDERS_PAYMENT_BY_PAYPAL_START = "ORDERS_PAYMENT_BY_PAYPAL_START";
export const ORDERS_PAYMENT_BY_PAYPAL_SUCCESS = "ORDERS_PAYMENT_BY_PAYPAL_SUCCESS";
export const ORDERS_PAYMENT_BY_PAYPAL_FAILURE = "ORDERS_PAYMENT_BY_PAYPAL_FAILURE";

export const HOME_SEARCH_START = "HOME_SEARCH_START";
export const HOME_SEARCH_SUCCESS = "HOME_SEARCH_SUCCESS";
export const HOME_SEARCH_FAILURE = "HOME_SEARCH_FAILURE";
