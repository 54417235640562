import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Button, Image, Input } from "react-bootstrap";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";

import {
  editUserDetails,
  fetchUserDetailsStart,
  updateUserDetailsStart,
  usernameValidationStart,
} from "../../../store/actions/UserAction";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../../Constant/constants";
import CropImageModal from "./CropImageModal";

const EditProfileCard = (props) => {
  const [profileInputData, setProfileInputData] = useState({});

  const [image, setImage] = useState({
    picture: "",
    cover: "",
  });

  const [cropModalFlag, setCropModalFlag] = useState({
    flag: false,
    image: "",
    width: "",
    height: "",
    shape: "",
    type: "",
    fileType: "",
    fileName: "",
  });

  const [isProfile,setIsProfile] = useState(false);

  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  const handleUsernameValidation = (event, username, value) => {
    props.dispatch(editUserDetails(username, value));
    props.dispatch(usernameValidationStart({ username: value }));
  };

  const handleChangeImage = (event) => {
    if (event.currentTarget.type === "file") {
      // setProfileInputData({
      //   ...profileInputData,
      //   [event.currentTarget.name]: event.currentTarget.files[0],
      // });

      const currentfileType = event.currentTarget.files[0].type;
      const currentfileName = event.currentTarget.files[0].name;

      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      if (event.currentTarget.name === "cover") {
        reader.onloadend = () => {
          setIsProfile(false);
          //setImage({ ...image, cover: reader.result });

          // do not change width and height its in aspect ratio 1920 * 500 , provice aspect ratio for different size

          setCropModalFlag({
            ...cropModalFlag,
            image: reader.result,
            width: 95,
            height: 25,
            shape: "rect",
            flag: true,
            type: "cover",
            fileType: currentfileType,
            fileName: currentfileName,
          });
        };
      }

      if (event.currentTarget.name === "picture") {
        reader.onloadend = () => {
          setIsProfile(true);
          //setImage({ ...image, picture: reader.result });

          // do not change width and height its in aspect ratio 300 * 300 , provice aspect ratio for different size

          setCropModalFlag({
            ...cropModalFlag,
            image: reader.result,
            width: 1,
            height: 1,
            shape: "round",
            flag: true,
            type: "picture",
            fileType: currentfileType,
            fileName: currentfileName,
          });
        };
      }

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (Object.keys(profileInputData).length > 0)
      props.dispatch(updateUserDetailsStart(profileInputData));
    else props.dispatch(updateUserDetailsStart());
  };

  let autocomplete;

  const renderAutoComplete = () => {
    const { google } = props;
    if (!google) {
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(autocomplete, {
      types: ["geocode"],
    });

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;
      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );

      props.dispatch(editUserDetails("address", full_address));
      props.dispatch(
        editUserDetails("latitude", place.geometry.location.lat())
      );
      props.dispatch(
        editUserDetails("longitude", place.geometry.location.lng())
      );
    });
  };

  const closeCropModal = () => {
    setCropModalFlag({
      flag: false,
      image: "",
      width: "",
      height: "",
      shape: "",
      cropedProfileImage: "",
      cropedCoverImage: "",
      type: "",
      fileType: "",
      fileName: "",
    });
  };

  return (
    <>
      {props.profile.loading ? (
        t("loading")
      ) : (
        <div
          role="tabpanel"
          className={
            props.activeSec === "profile-card"
              ? "tab-pane fade in active"
              : "tab-pane fade"
          }
          id="Section2"
        >
          <div className="profile-post-area">
            <div className="bookmarkes-list bookmarks-right-side border-btm-none default-cursor">
              <div className="pull-left">
                <h3>{t("edit_profile")}</h3>
                {/* <p className="small-text">{t("change_photo")}</p> */}
              </div>
              <div className="pull-right"></div>
            </div>
          </div>
          <div className="edit-profile-photo">
            <div className="profile large">
              <div className="cover">
                <Image
                  src={
                    image.cover === "" ? props.profile.data.cover : image.cover
                  }
                />

                <div className="layer">
                  <div className="loader"></div>
                </div>
                <a className="image-wrapper" href="#">
                  <Form id="coverForm" action="#">
                    <Form.Control
                      className="hidden-input"
                      id="changeCover"
                      type="file"
                      name="cover"
                      accept="image/*"
                      onChange={handleChangeImage}
                    />
                    <Form.Label
                      className="btn gradient-btn editProfile gradientcolor uploadCover mb-0"
                      for="changeCover"
                      title="Change cover"
                    >
                      {t("upload_cover_image")}
                    </Form.Label>
                  </Form>
                </a>
              </div>
              <div className="user-info">
                <div className="profile-pic">
                  <Image
                    src={
                      image.picture === ""
                        ? props.profile.data.picture
                        : image.picture
                    }
                  />
                  <div className="layer">
                    <div className="loader"></div>
                  </div>
                  <a className="image-wrapper" href="#">
                    <Form id="profile-img" action="#">
                      <Form.Control
                        className="hidden-input"
                        id="changePicture"
                        type="file"
                        accept="image/*"
                        name="picture"
                        onChange={handleChangeImage}
                      />
                      <Form.Label
                        className="edit"
                        for="changePicture"
                        type="file"
                        title="Change picture"
                      ></Form.Label>
                    </Form>
                  </a>
                </div>
                <a className="btn graditentBorderBtn editProfile">
                  <Form id="profilePictureForm" action="#">
                    <Form.Control
                      className="hidden-input"
                      id="changePicture"
                      type="file"
                      name="picture"
                      onChange={handleChangeImage}
                    />
                    <Form.Label
                      className="gradientBorderColor mb-0"
                      for="changePicture"
                      type="file"
                      title="Change picture"
                    >
                      {t("upload_profile_photo")}
                    </Form.Label>
                  </Form>
                </a>
              </div>
            </div>
            <p className="inuput-help">{t("upload_profile_photo_para")}</p>
          </div>
          <div
            className="edit-input-wrapper"
            data-vv-delay="1000"
            data-vv-as="username"
          >
            <Form.Label className="edit-input-label">
              {t("username")}{" "}
            </Form.Label>
            <div className="">
              <Form.Control
                id="username"
                type="text"
                placeholder=""
                name="username"
                value={props.profile.data.username}
                className="form-control edit-reset"
                onChange={(event) =>
                  handleUsernameValidation(
                    event,
                    event.currentTarget.name,
                    event.currentTarget.value
                  )
                }
                isValid={props.validation.isValid}
                isInvalid={props.validation.isInValid}
              />
              {props.validation.isInValid ? (
                <Form.Control.Feedback type="invalid">
                  {t("username_error")}
                </Form.Control.Feedback>
              ) : (
                ""
              )}
              {props.validation.isValid ? (
                <Form.Control.Feedback>{t("looks_good")}</Form.Control.Feedback>
              ) : (
                ""
              )}
            </div>
            <p className="input-help">
              {window.location.origin + "/" + props.profile.data.username}
            </p>
          </div>
          <div
            className="edit-input-wrapper"
            data-vv-delay="1000"
            data-vv-as="Display Name"
          >
            <Form.Label className="edit-input-label">
              {t("display_name")}
            </Form.Label>
            <div className="">
              <Form.Control
                id="name"
                type="text"
                placeholder=""
                defaultValue={props.profile.data.name}
                name="name"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="default_payment_method"
          >
            <Form.Label className="edit-input-label">
              {t("default_payment_method")}{" "}
              {/* <span className="edit-input-optional">({t("optional")})</span> */}
            </Form.Label>
            <div className="">
              <select
                className="form-control mw-200 mb-3"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
                name="default_payment_method"
                defaultValue={props.profile.data.default_payment_method}
              >
                <option
                  value="WALLET"
                  selected={
                    props.profile.data.default_payment_method == "WALLET"
                      ? true
                      : false
                  }
                >
                  {t("wallet")}
                </option>
                <option
                  value="CARD"
                  selected={
                    props.profile.data.default_payment_method == "CARD"
                      ? true
                      : false
                  }
                >
                  {t("card")}
                </option>
                {/* <option
                  value="PAYPAL"
                  selected={
                    props.profile.data.default_payment_method == "PAYPAL"
                      ? true
                      : false
                  }
                >
                  {t("paypal")}
                </option> */}
                {/* <option
                  value="CCBILL"
                  selected={
                    props.profile.data.default_payment_method == "CCBILL"
                      ? true
                      : false
                  }
                >
                  {t("ccbill")}
                </option> */}
              </select>
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="online_status"
          >
            <Form.Label className="edit-input-label">
              {t("online_status")}{" "}
              <span className="edit-input-optional">
                {/* ({t("optional")}) */}
              </span>
            </Form.Label>
            <div className="">
              <select
                className="form-control mw-200 mb-3"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
                name="is_online_status"
                defaultValue={props.profile.data.is_online_status}
              >
                <option
                  value="1"
                  selected={
                    props.profile.data.is_online_status == 1 ? true : false
                  }
                >
                  {t("show")}
                </option>
                <option
                  value="0"
                  selected={
                    props.profile.data.is_online_status == 0 ? true : false
                  }
                >
                  {t("hide")}
                </option>
              </select>
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="gender"
          >
            <Form.Label className="edit-input-label">
              {t("gender")}{" "}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <select
                className="form-control mw-200 mb-3"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
                name="gender"
                defaultValue={props.profile.data.gender}
              >
                <option
                  value="rather-not-select"
                  selected={
                    props.profile.data.gender == "rather-not-select"
                      ? true
                      : false
                  }
                >
                  {t("rather_not_select")}
                </option>
                <option
                  value="male"
                  selected={props.profile.data.gender == "male" ? true : false}
                >
                  {t("male")}
                </option>
                <option
                  value="female"
                  selected={
                    props.profile.data.gender == "female" ? true : false
                  }
                >
                  {t("female")}
                </option>
                <option
                  value="others"
                  selected={
                    props.profile.data.gender == "others" ? true : false
                  }
                >
                  {t("others")}
                </option>
              </select>
            </div>
          </div>

          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="description"
          >
            <Form.Label className="edit-input-label">
              {t("about")}{" "}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit-description"
                as="textarea"
                rows={5}
                autocomplete="off"
                placeholder=""
                value={props.profile.data.about}
                name="about"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          {/* <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="Address"
          >
            <Form.Label className="edit-input-label">
              {t("location")}{" "}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit-address"
                type="text"
                value={props.profile.data.address}
                placeholder={t("location")}
                onFocus={renderAutoComplete}
                ref={(ref) => (autocomplete = ref)}
                name="address"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div> */}
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="Website"
          >
            <Form.Label className="edit-input-label">
              {t("website_url")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit-website"
                type="text"
                autocomplete="off"
                value={props.profile.data.website}
                placeholder={t("website_url")}
                name="website"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="Amazon Wishlist"
          >
            <Form.Label className="edit-input-label">
              {t("amazon_wishlist")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit-amazon-wishlist"
                type="text"
                autocomplete="off"
                value={props.profile.data.amazon_wishlist}
                placeholder={t("amazon_wishlist")}
                name="amazon_wishlist"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="Instagram Link"
          >
            <Form.Label className="edit-input-label" for="edit_instagram_link">
              {t("instagaram_link")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit_instagram_link"
                type="text"
                autocomplete="off"
                value={props.profile.data.instagram_link}
                placeholder={t("instagaram_link")}
                name="instagram_link"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="Facebook Link"
          >
            <Form.Label className="edit-input-label" for="edit_facebook_link">
              {t("facebook_link")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit_facebook_link"
                type="text"
                autocomplete="off"
                value={props.profile.data.facebook_link}
                placeholder={t("facebook_link")}
                name="facebook_link"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>

          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="Twitter Link"
          >
            <Form.Label className="edit-input-label" for="edit_twitter_link">
              {t("twitter_link")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit_twitter_link"
                type="text"
                autocomplete="off"
                value={props.profile.data.twitter_link}
                placeholder={t("twitter_link")}
                name="twitter_link"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="Snapchat Link"
          >
            <Form.Label className="edit-input-label" for="edit_snapchat_link">
              {t("snapchat_link")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit_snapchat_link"
                type="text"
                autocomplete="off"
                value={props.profile.data.snapchat_link}
                placeholder={t("snapchat_link")}
                name="snapchat_link"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="Linkedin Link"
          >
            <Form.Label className="edit-input-label" for="edit_linkedin_link">
              {t("linkedin_link")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit_linkedin_link"
                type="text"
                autocomplete="off"
                value={props.profile.data.linkedin_link}
                placeholder={t("linkedin_link")}
                name="linkedin_link"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="PINTEREST Link"
          >
            <Form.Label className="edit-input-label" for="edit_pinterest_link">
              {t("pinterest_link")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit_pinterest_link"
                type="text"
                autocomplete="off"
                value={props.profile.data.pinterest_link}
                placeholder={t("pinterest_link")}
                name="pinterest_link"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="YOUTUBE Link"
          >
            <Form.Label className="edit-input-label" for="edit_youtube_link">
              {t("youtube_link")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit_youtube_link"
                type="text"
                autocomplete="off"
                value={props.profile.data.youtube_link}
                placeholder={t("youtube_link")}
                name="youtube_link"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>
          <div
            className="edit-input-wrapper disabled"
            data-vv-delay="1000"
            data-vv-as="TWITCH Link"
          >
            <Form.Label className="edit-input-label" for="edit_twitch_link">
              {t("twitch_link")}
              <span className="edit-input-optional">({t("optional")})</span>
            </Form.Label>
            <div className="">
              <Form.Control
                id="edit_twitch_link"
                type="text"
                autocomplete="off"
                value={props.profile.data.twitch_link}
                placeholder={t("twitch_link")}
                name="twitch_link"
                className="form-control edit-reset"
                onChange={(event) => {
                  props.dispatch(
                    editUserDetails(
                      event.currentTarget.name,
                      event.currentTarget.value
                    )
                  );
                }}
              />
            </div>
          </div>

          <div className="edit-save">
            <Button
              className="save-btn"
              onClick={handleSubmit}
              disabled={props.profileInputData.buttonDisable}
            >
              {props.profileInputData.loadingButtonContent !== null
                ? props.profileInputData.loadingButtonContent
                : t("submit")}
            </Button>
          </div>
        </div>
      )}
      <CropImageModal
        image={cropModalFlag.image}
        modalFlag={cropModalFlag.flag}
        cropModalFlag={cropModalFlag}
        closeModal={closeCropModal}
        setImage={setImage}
        imageState={image}
        setProfileInputData={setProfileInputData}
        profileInputData={profileInputData}
        isProfile={isProfile}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  profileInputData: state.users.profileInputData,
  validation: state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const connector = connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(EditProfileCard));

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(connector);
