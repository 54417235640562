import React from "react";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import { Row, Col, Image, Container, Media, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { translate, t, setLanguage } from "react-multi-lang";

const ThemeFooter = () => {
    const handleChangeLang = ({ currentTarget: input }) => {
        console.log(input.value);
        setLanguage(input.value);
        localStorage.setItem("lang", input.value);
        // window.location.reload();
    };

    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    return (
        <>
            <footer className="whatnot-footer-sec">
                <Container>
                    <Row>
                        <Col
                            sm={6}
                            md={6}
                            lg={4}
                            xl={3}
                            className="resp-mrg-btn-xs resp resp-btm-lg"
                        >
                            <div className="whatnot-footer-content">
                                <Image
                                    className="what-not-footer-logo mb-4"
                                    // src={configuration.get("configData.site_logo")}
                                    src={
                                        window.location.origin + "/assets/images/anjan-white-logo.svg"
                                    }
                                />
                                {/* <p>Keep connect with us! Follow us on any of these platforms</p> */}
                                <ul className="whatnot-footer-social-link-sec list-unstyled">
                                    {configuration.get("configData.facebook_link") && (
                                        <Media as="li">
                                            <a
                                                href={configuration.get("configData.facebook_link")}
                                                target="_blank"
                                            >
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </Media>
                                    )}
                                    {configuration.get("configData.twitter_link") && (
                                        <Media as="li">
                                            <a
                                                href={configuration.get("configData.twitter_link")}
                                                target="_blank"
                                            >
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </Media>
                                    )}
                                    {configuration.get("configData.instagram_link") && (
                                        <Media as="li">
                                            <a
                                                href={configuration.get("configData.instagram_link")}
                                                target="_blank"
                                            >
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </Media>
                                    )}
                                    {configuration.get("configData.linkedin_link") && (
                                        <Media as="li">
                                            <a
                                                href={configuration.get("configData.linkedin_link")}
                                                target="_blank"
                                            >
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </Media>
                                    )}
                                    {configuration.get("configData.pinterest_link") && (
                                        <Media as="li">
                                            <a
                                                href={configuration.get("configData.pinterest_link")}
                                                target="_blank"
                                            >
                                                <i className="fab fa-pinterest"></i>
                                            </a>
                                        </Media>
                                    )}
                                    {configuration.get("configData.youtube_link") && (
                                        <Media as="li">
                                            <a
                                                href={configuration.get("configData.youtube_link")}
                                                target="_blank"
                                            >
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </Media>
                                    )}
                                    {configuration.get("configData.snapchat_link") && (
                                        <Media as="li">
                                            <a
                                                href={configuration.get("configData.snapchat_link")}
                                                target="_blank"
                                            >
                                                <i className="fab fa-snapchat"></i>
                                            </a>
                                        </Media>
                                    )}
                                </ul>
                            </div>
                        </Col>
                        {configuration.get("configData.footer_pages1") && (
                            <Col
                                sm={6}
                                md={6}
                                lg={2}
                                xl={2}
                                className="resp-mrg-btn-xs resp-btm-lg"
                            >
                                <div className="whatnot-footer-content">
                                    <h3>About</h3>
                                    <ul className="whatnot-footer-widget list-unstyled text-capitalize">
                                        {configuration
                                            .get("configData.footer_pages1")
                                            .map((staticPage, i) => (
                                                <li>
                                                    <a
                                                        href={`${window.location.origin}/page/${staticPage.static_page_unique_id}`}
                                                    >
                                                        {staticPage.title}
                                                    </a>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </Col>
                        )}
                        <Col sm={6} md={6} lg={2} xl={2} className="resp-mrg-btn-xs">
                            <div className="whatnot-footer-content">
                                <h3>More Info</h3>
                                <ul className="whatnot-footer-widget list-unstyled text-capitalize">
                                    {configuration
                                        .get("configData.footer_pages2")
                                        .map((staticPage, i) => (
                                            <li>
                                                <a
                                                    href={`${window.location.origin}/page/${staticPage.static_page_unique_id}`}
                                                >
                                                    {staticPage.title}
                                                </a>
                                            </li>
                                        ))}
                                    <li>
                                        {/* <div className="new-dropdown-language pt-2">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <div className="nav-end-dropdown">
                            <img
                              src="assets/images/united-kingdom.png"
                              alt=""
                              className="country-img"
                            />
                            English
                            <div className="new-down-icon">
                              <img
                                src="assets/images/downward-arrow.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">Greek</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Russian
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Spanish
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                                        <div className="select-lang-drop-down">
                                            <select
                                                className="lang-toggle form-control mw-200 mt-2"
                                                name="lang"
                                                onChange={handleChangeLang}
                                                defaultValue={localStorage.getItem("lang")}
                                            >
                                                <option
                                                    value="en"
                                                // selected={
                                                //     localStorage.getItem("lang") == "en" ? true : false
                                                // }
                                                >
                                                    {t("english")}
                                                </option>
                                                <option
                                                    value="es"
                                                // selected={
                                                //     localStorage.getItem("lang") == "es" ? true : false
                                                // }
                                                >
                                                    {t("spanish")}
                                                </option>
                                            </select>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        {!userId || !accessToken ? (
                            <Col sm={6} md={6} lg={2} xl={2}>
                                <div className="whatnot-footer-content">
                                    <h3>Links</h3>
                                    <ul className="whatnot-footer-widget list-unstyled">
                                        <li>
                                            <Link to="/login">Log In</Link>
                                        </li>
                                        <li>
                                            <Link to="/signup">Sign Up</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        ) : null}
                        <Col sm={6} md={6} lg={2} xl={3}>
                            <div className="footer-contact-info">
                                <div className="whatnot-footer-content">
                                    <h3>Contact Info</h3>
                                </div>
                                <div className="footer-contact-list">
                                    <img src={"/assets/images/icons/location.svg"}></img>
                                    <p>{configuration.get("configData.contact_address")}</p>
                                </div>
                                <div className="footer-contact-list">
                                    <img src={window.location.origin + "/assets/images/icons/telephone.svg"}></img>
                                    <p>{configuration.get("configData.contact_mobile")}</p>
                                </div>
                                <div className="footer-contact-list">
                                    <img src={window.location.origin + "/assets/images/icons/mail.svg"}></img>
                                    <p>{configuration.get("configData.contact_email")}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <div className="py-3 sub-footer bg-dark">
                <Container>
                    <Row className="d-flex">
                        <Col lg={12} sm={12} className="mt-2 mb-2 text-center text-white">
                            <p>{configuration.get("configData.copyright_content")}</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    categoryList: state.lookUp.categories,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(ThemeFooter));
