import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Media,
  Image,
} from "react-bootstrap";
import {
  liveViewerUpdateStart,
  liveVideoStartCallStart,
} from "../../store/actions/LiveVideoAction";
import configuration from "react-global-configuration";
import Counter from "../Utils/Counter";
AgoraRTC.setLogLevel(4);
const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
const $ = window.$;

const LiveSample = (props) => {
  return (
    <>
      <div className="app-main">
        <div className="video-call-wrapper">
          <div className="video-participant">
            <div className="participant-actions">
              <ul className="list-unstyled participant-notify">
                <Media as="li">
                  {props.liveVideo.data.formatted_schedule_time}{" "}
                </Media>
                <Media as="li">
                  <i className="fas fa-eye mr-2"></i>
                  {props.liveVideo.data.viewer_cnt}
                </Media>
                {props.isOwner ? (
                  <Media as="li">
                    <Link
                      to="#"
                      className="live-start-now"
                      onClick={() => {
                        props.dispatch(
                          liveVideoStartCallStart({
                            live_video_id: props.liveVideo.data.live_video_id,
                          })
                        );
                      }}
                    >
                      {t("start_now")}
                    </Link>
                  </Media>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <img
              className="blur-image"
              src={props.liveVideo.data.preview_file}
            ></img>

            <Counter
              preText={"Starts In "}
              targetDate={props.liveVideo.data.schedule_time}
            />
            <Link
              to={`/` + props.liveVideo.data.user_unique_id}
              className="name-tag"
            >
              {props.liveVideo.data.user_displayname}
            </Link>
            <div
              id="agora_local"
              style={{ width: "100%", height: "100%", position: "relative" }}
            />
          </div>
        </div>
      </div>
      <div className="agora-card">
        <div className="button-group mt-3 mb-3">
          {/* {props.isOwner ? 
              <>
                <div className="live-action-icon-sec">
                  <ul className="list-unstyled live-action-flex">
                    <Media as="li">
                      <Link to="#" 
                        onClick={() =>
                          leaveCall()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/end-stream.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                    <Media as="li" id="mute-audio">
                      <Link to="#"
                      onClick={() =>
                        muteAudio()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/audio.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                    <Media as="li" id="unmute-audio" style={{display: "none"}}>
                      <Link to="#"
                      onClick={() =>
                        muteAudio()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/no-audio.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                    <Media as="li" id="mute-video">
                      <Link to="#"
                      onClick={() =>
                        muteVideo()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/video.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                    <Media as="li" id="unmute-video" style={{display: "none"}}>
                      <Link to="#"
                      onClick={() =>
                        muteVideo()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/mute-video.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                  </ul>
                </div>
              </>
                :
                <div className="live-action-icon-sec">
                  <ul className="list-unstyled live-action-flex">
                    <Media as="li">
                      <Link to="#" 
                        onClick={() =>
                          leaveCall()
                      }>
                        <Image
                          src={
                            window.location.origin + "/assets/images/icons/end-stream.png"
                          }
                          className="action-live-icon"
                        />
                      </Link>
                    </Media>
                  </ul>
                </div>
              } */}
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideo: state.liveVideo.singleLiveVideo,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LiveSample));
