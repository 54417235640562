import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import config from "react-global-configuration";
import io from "socket.io-client";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  videoCallBroadcastFailure,
  videoCallBroadcastSuccess,
  fetchLiveVideosSuccess,
  fetchLiveVideosFailure,
  fetchLiveVideosHistorySuccess,
  fetchLiveVideosHistoryFailure,
  fetchSingleLiveVideoSuccess,
  fetchSingleLiveVideoFailure,
  joinLiveVideosSuccess,
  joinLiveVideosFailure,
  livePaymentPaypalSuccess,
  livePaymentPaypalFailure,
  livePaymentStripeSuccess,
  livePaymentStripeFailure,
  liveViewerUpdateSuccess,
  liveViewerUpdateFailure,
  liveVideoEndSuccess,
  liveVideoEndFailure,
  fetchLiveStreamsOngoingFailure,
  fetchLiveStreamingsOngoingSucccess,
  fetchUpcommingLiveStreamingsSuccess,
  fetchUpcommingLiveStreamingsFailure,
  fetchLiveVideoMessageFailure,
  fetchLiveVideoMessageSuccess,
  fetchLiveVideosProductsSuccess,
  fetchLiveVideosProductsFailure,
  fetchBookmarkedVideosStart,
  fetchLiveVideoOrdersFailure,
  fetchLiveVideoOrdersSuccess,
  fetchLiveVideosScheduledOwnerSuccess,
  fetchLiveVideosScheduledOwnerFailure,
  liveVideoStartCallSuccess,
  liveVideoStartCallFailure,
} from "../actions/LiveVideoAction";
import {
  LIVE_VIDEO_CALL_BROADCAST_START,
  FETCH_LIVE_VIDEOS_START,
  FETCH_LIVE_VIDEOS_HISTORY_START,
  FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
  JOIN_LIVE_VIDEOS_START,
  FETCH_SINGLE_LIVE_VIDEOS_START,
  LIVE_VIDEOS_PAYMENT_BY_STRIPE_START,
  LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START,
  LIVE_VIDEOS_VIEWER_UPDATE_START,
  LIVE_VIDEOS_END_START,
  LIVE_STREAMINGS_ONGOING_START,
  UPCOMMING_LIVE_STREAMINGS_START,
  FETCH_LIVE_VIDEO_MESSAGE_START,
  FETCH_LIVE_VIDEOS_PRODUCTS_START,
  FETCH_LIVE_VIDEO_ORDERS_START,
  FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START,
  LIVE_VIDEOS_START_CALL_START,
} from "../actions/ActionConstant";

function* liveVideoSaveAPI(action) {
  try {
    const response = yield api.postMethod(
      "live_videos_broadcast_create",
      action.data
    );
    if (response.data.success) {
      yield put(videoCallBroadcastSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      if (action.data.live_schedule_type == 1) {
        window.location.assign(
          window.location.origin +
          "/live/" +
          response.data.data.live_video_unique_id
        );
      } else {
        window.location.assign(window.location.origin + "/live-videos");
      }
    } else {
      yield put(videoCallBroadcastFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(videoCallBroadcastFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideosAPI() {
  try {
    const skipCount = yield select((state) => state.liveVideo.liveVideos.skip);
    const response = yield api.postMethod("live_videos", {
      skip: skipCount,
    });
    if (response.data.success) {
      yield put(fetchLiveVideosSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLiveVideosFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideosHistoryAPI(action) {
  try {
    const skipCount = yield select(
      (state) => state.liveVideo.liveVideosHistory.skip
    );
    const response = yield api.postMethod("live_videos_owner_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideosHistorySuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLiveVideosHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideosScheduledOwnerAPI(action) {
  try {
    const skipCount = yield select(
      (state) => state.liveVideo.liveVideosScheduledOwner.skip
    );
    const response = yield api.postMethod("live_videos_scheduled_owner", {
      ...action.data,
      skip: skipCount,
    });
    if (response.data.success) {
      yield put(fetchLiveVideosScheduledOwnerSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosScheduledOwnerFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLiveVideosScheduledOwnerFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleLiveVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.singleLiveVideo.inputData
    );
    const response = yield api.postMethod("live_videos_view", inputData);
    if (response.data.success) {
      yield put(fetchSingleLiveVideoSuccess(response.data.data));
    } else {
      yield put(fetchSingleLiveVideoFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));

      setInterval(() => {
        window.location.assign("/");
      }, 500);
    }
  } catch (error) {
    yield put(fetchSingleLiveVideoFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveStripeAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveStripe.inputData
    );
    const response = yield api.postMethod(
      "live_videos_payment_by_card",
      inputData
    );
    if (response.data.success) {
      yield put(livePaymentStripeSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign(
        window.location.origin +
        "/live/" +
        response.data.data.live_video_unique_id
      );
    } else {
      yield put(livePaymentStripeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(livePaymentStripeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* livePaypalAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.livePaypal.inputData
    );
    const response = yield api.postMethod(
      "live_videos_payment_by_paypal",
      inputData
    );
    if (response.data.success) {
      yield put(livePaymentPaypalSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      window.location.assign(
        window.location.origin +
        "/live/" +
        response.data.data.live_video_unique_id
      );
    } else {
      yield put(livePaymentPaypalFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(livePaymentPaypalFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveViewerUpdateAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveViewerUpdate.inputData
    );
    const response = yield api.postMethod(
      "live_videos_viewer_update",
      inputData
    );
    if (response.data.success) {
      yield put(liveViewerUpdateSuccess(response.data.data));
      // const notificationMessage = getSuccessNotificationMessage(
      //   response.data.message
      // );
      // yield put(createNotification(notificationMessage));
      let chatSocket;
      let chatSocketUrl = config.get("configData.chat_socket_url");
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + response.data.data.virtual_id + `'`,
      });

      chatSocket.emit("livevideo viewerupdate", response.data.data.virtual_id);
    } else {
      yield put(liveViewerUpdateFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(liveViewerUpdateFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveEndAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveEnd.inputData
    );
    const response = yield api.postMethod(
      "live_videos_broadcast_stop",
      inputData
    );
    if (response.data.success) {
      yield put(liveVideoEndSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      window.location.assign(window.location.origin + "/live-videos-history");
    } else {
      yield put(liveVideoEndFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(liveVideoEndFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveStartAPI(action) {
  try {
    const response = yield api.postMethod(
      "live_videos_broadcast_start",
      action.data
    );
    if (response.data.success) {
      yield put(liveVideoStartCallSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.reload();
    } else {
      yield put(liveVideoStartCallFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(liveVideoStartCallFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveSteamingsOngoingAPI() {
  try {
    const response = yield api.postMethod("live_streamings_ongoing");
    if (response.data.success) {
      yield put(fetchLiveStreamingsOngoingSucccess(response.data.data));
    } else {
      yield put(fetchLiveStreamsOngoingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLiveStreamsOngoingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* upcommingLiveStreamingsAPI() {
  try {
    const response = yield api.postMethod("upcoming_live_streamings");
    if (response.data.success) {
      yield put(fetchUpcommingLiveStreamingsSuccess(response.data.data));
    } else {
      yield put(fetchUpcommingLiveStreamingsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchUpcommingLiveStreamingsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideoChatAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveVideoChat.inputData
    );
    const response = yield api.postMethod(
      "live_video_chat_messages",
      inputData
    );
    if (response.data.success) {
      yield put(fetchLiveVideoMessageSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideoMessageFailure(response.data.error));
      // const notificationMessage = getErrorNotificationMessage(
      //   response.data.error
      // );
      // yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchLiveVideoMessageFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideosProductAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveVideosProducts.inputData
    );
    const skipCount = yield select(
      (state) => state.liveVideo.liveVideosProducts.skip
    );
    const response = yield api.postMethod("live_video_products_list", {
      ...inputData,
      skip: skipCount,
    });
    if (response.data.success) {
      yield put(fetchLiveVideosProductsSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosProductsFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchLiveVideosProductsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchLiveVideoOrdersAPI(action) {
  try {
    const skipCount = yield select(
      (state) => state.liveVideo.liveVideoOrders.skip
    );
    const response = yield api.postMethod("live_videos_orders_list", {
      ...action.data,
      skip: skipCount,
    });
    if (response.data.success) {
      yield put(fetchLiveVideoOrdersSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideoOrdersFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchLiveVideoOrdersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(LIVE_VIDEO_CALL_BROADCAST_START, liveVideoSaveAPI),
  ]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEOS_START, liveVideosAPI)]);
  yield all([
    yield takeLatest(FETCH_LIVE_VIDEOS_HISTORY_START, liveVideosHistoryAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_MORE_LIVE_VIDEOS_HISTORY_START, liveVideosHistoryAPI),
  ]);
  yield all([
    yield takeLatest(
      FETCH_LIVE_VIDEOS_SCHEDULED_OWNER_START,
      liveVideosScheduledOwnerAPI
    ),
  ]);
  // yield all([yield takeLatest(JOIN_LIVE_VIDEOS_START, joinLiveVideosAPI)]);
  yield all([
    yield takeLatest(FETCH_SINGLE_LIVE_VIDEOS_START, fetchSingleLiveVideoAPI),
  ]);
  yield all([
    yield takeLatest(LIVE_VIDEOS_PAYMENT_BY_STRIPE_START, liveStripeAPI),
  ]);
  yield all([
    yield takeLatest(LIVE_VIDEOS_PAYMENT_BY_PAYPAL_START, livePaypalAPI),
  ]);
  yield all([
    yield takeLatest(LIVE_VIDEOS_VIEWER_UPDATE_START, liveViewerUpdateAPI),
  ]);
  yield all([yield takeLatest(LIVE_VIDEOS_END_START, liveEndAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_START_CALL_START, liveStartAPI)]);
  yield all([
    yield takeLatest(LIVE_STREAMINGS_ONGOING_START, liveSteamingsOngoingAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_LIVE_VIDEO_MESSAGE_START, liveVideoChatAPI),
  ]);
  yield all([
    yield takeLatest(
      UPCOMMING_LIVE_STREAMINGS_START,
      upcommingLiveStreamingsAPI
    ),
  ]);
  yield all([
    yield takeLatest(FETCH_LIVE_VIDEOS_PRODUCTS_START, liveVideosProductAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_LIVE_VIDEO_ORDERS_START, fetchLiveVideoOrdersAPI),
  ]);
}
