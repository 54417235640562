import {
    FETCH_ORDER_LIST_START,
    FETCH_ORDER_LIST_SUCCESS,
    FETCH_ORDER_LIST_FAILURE,
    ORDER_LIST_FOR_SELLER_START,
    ORDER_LIST_FOR_SELLER_SUCCESS,
    ORDER_LIST_FOR_SELLER_FAILURE,
    FETCH_ORDER_DETAILS_START,
    FETCH_ORDER_DETAILS_SUCCESS,
    FETCH_ORDER_DETAILS_FAILURE,
    CREATE_ORDER_BY_WALLET_START,
    CREATE_ORDER_BY_WALLET_SUCCESS,
    CREATE_ORDER_BY_WALLET_FAILURE,
    CREATE_ORDER_BY_STRIPE_START,
    CREATE_ORDER_BY_STRIPE_SUCCESS,
    CREATE_ORDER_BY_STRIPE_FAILURE,
    UPDATE_ORDER_STATUS_START,
    UPDATE_ORDER_STATUS_SUCCESS,
    UPDATE_ORDER_STATUS_FAILURE,
    CANCEL_ORDER_START,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILURE,
    FETCH_ORDER_PAYMENT_LIST_START,
    FETCH_ORDER_PAYMENT_LIST_SUCCESS,
    FETCH_ORDER_PAYMENT_LIST_FAILURE,
    ORDERS_VIEW_FOR_OTHERS_START,
    ORDERS_VIEW_FOR_OTHERS_SUCCESS,
	ORDERS_VIEW_FOR_OTHERS_FAILURE,
} from '../actions/ActionConstant';

const initialState = {
    orderList: {
        data: {},
        loading: true,
        error: false,
    },
    orderListForSeller: {
        data: {},
        loading: true,
        error: false,
    },
    orderDetails: {
        data: {},
        loading: true,
        error: false,
    },
    createOrderWallet: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    createOrderStripe: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    updateOrderStatus: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    cancelOrder: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    orderPayments: {
        data: {},
        loading: true,
        error: false,
    },
    ordersViewForOthers : {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent : "",
        buttonDisable : false
    },
}

const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDER_LIST_START:
            return {
                ...state,
                orderList: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case FETCH_ORDER_LIST_SUCCESS:
            return {
                ...state,
                orderList: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case FETCH_ORDER_LIST_FAILURE:
            return {
                ...state,
                orderList: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case ORDER_LIST_FOR_SELLER_START:
            return {
                ...state,
                orderListForSeller: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case ORDER_LIST_FOR_SELLER_SUCCESS:
            return {
                ...state,
                orderListForSeller: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case ORDER_LIST_FOR_SELLER_FAILURE:
            return {
                ...state,
                orderListForSeller: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case FETCH_ORDER_DETAILS_START:
            return {
                ...state,
                orderDetails: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case FETCH_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                orderDetails: {
                    data: action.data,
                    loading: true,
                    error: false,
                }
            };
        case FETCH_ORDER_DETAILS_FAILURE:
            return {
                ...state,
                orderDetails: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case CREATE_ORDER_BY_WALLET_START:
            return {
                ...state,
                createOrderWallet: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case CREATE_ORDER_BY_WALLET_SUCCESS:
            return {
                ...state,
                createOrderWallet: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CREATE_ORDER_BY_WALLET_FAILURE:
            return {
                ...state,
                createOrderWallet: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CREATE_ORDER_BY_STRIPE_START:
            return {
                ...state,
                createOrderStripe: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case CREATE_ORDER_BY_STRIPE_SUCCESS:
            return {
                ...state,
                createOrderStripe: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CREATE_ORDER_BY_STRIPE_FAILURE:
            return {
                ...state,
                createOrderStripe: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case UPDATE_ORDER_STATUS_START:
            return {
                ...state,
                updateOrderStatus: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case UPDATE_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                updateOrderStatus: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case UPDATE_ORDER_STATUS_FAILURE:
            return {
                ...state,
                updateOrderStatus: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CANCEL_ORDER_START:
            return {
                ...state,
                cancelOrder: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                cancelOrder: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CANCEL_ORDER_FAILURE:
            return {
                ...state,
                cancelOrder: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case FETCH_ORDER_PAYMENT_LIST_START:
            return {
                ...state,
                orderPayments: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case FETCH_ORDER_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                orderPayments: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case FETCH_ORDER_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                orderPayments: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case ORDERS_VIEW_FOR_OTHERS_START:
            return {
                ...state,
                ordersViewForOthers: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent : "Uploading....",
                    buttonDisable : true
                },
            };
        case ORDERS_VIEW_FOR_OTHERS_SUCCESS:
            return {
                ...state,
                ordersViewForOthers: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent : "",
                    buttonDisable : false
                },
            };
        case ORDERS_VIEW_FOR_OTHERS_FAILURE:
            return {
                ...state,
                ordersViewForOthers: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent : "",
                    buttonDisable : false
                },
            };
        default:
            return state;
    }
}

export default OrderReducer;