import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import AuthLayout from "../layouts/AuthLayout";
import EditProfile from "../Accounts/Profile/EditProfile";
import ProfileIndex from "../Accounts/Profile/ProfileIndex";
import NotFoundIndex from "../NotFound/NotFoundIndex";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import LandingPageLoader from "../Loader/LandingPageLoader";
import EmptyLayout from "../layouts/EmptyLayout";
import VideoCallLayout from "../layouts/VideoCallLayout";
import LandingPageIndex from "../LandingPageIndex/LandingPageIndex";
import HomePageIndex from "../Home/HomePageIndex";
import BookmarksIndex from "../Bookmarks/BookmarksIndex";
import BookmarkPhoto from "../Bookmarks/BookmarkPhoto";
import FollowingIndex from "../Accounts/FansFollowing/Following/FollowingIndex";
import ListIndex from "../Accounts/List/ListIndex";
import NotificationIndex from "../Notification/NotificationIndex";
import PaymentsIndex from "../Accounts/Payments/PaymentsIndex";
import BankingIndex from "../Accounts/Payments/BankingIndex";
import CardsIndex from "../Accounts/Payments/CardsIndex";
import AddBankIndex from "../Accounts/Payments/AddBankIndex";
import Logout from "../Accounts/Logout";
import Wallet from "../Wallet/Wallet";
import BillingAccountIndex from "../Accounts/Payments/BillingAccountIndex";
import DocumentUploadIndex from "../DocumentUpload/DocumentUploadIndex";
import StaticPage from "../StaticPage/StaticPage";
import FanIndex from "../Accounts/FansFollowing/Fans/FanIndex";
import ResetPassword from "../LandingPageIndex/ResetPassword";
import UploadProfilePicture from "../Accounts/Profile/UploadProfilePicture";
import LiveStreamingPost from "../LiveStreaming/LiveStreamingPost";
import SingleProfile from "../Accounts/Profile/SingleProfile";
import DashboardContentCreator from "../DashboardContentCreator/DashboardContentCreator";
import BecomeAContentCreatorIndex from "../BecomeAContentCreator/BecomeAContentCreatorIndex";
import EcomIndex from "../Ecom/EcomIndex";
import EcomCartIndex from "../Ecom/EcomCartIndex";
import EcomPaymentIndex from "../Ecom/EcomPaymentIndex";
import EcomPaymentMethod from "../Ecom/EcomPaymentMethod";
import EcomCategoryIndex from "../Ecom/EcomCategoryIndex";
import SingleProduct from "../Ecom/Product/SingleProduct";
import OrderList from "../Ecom/Orders/OrderList";
import SellerOrderList from "../Ecom/Orders/SellerOrderList";
import OrderView from "../Ecom/Orders/OrderView";
import OrderTransaction from "../Ecom/Orders/OrderTransaction";
import AddProduct from "../Ecom/Product/AddProduct";
import EditProduct from "../Ecom/Product/EditProduct";
import ProductList from "../Ecom/Product/ProductList";
import VerificationIndex from "../Verification/VerificationIndex";

import UserLiveVideosIndex from "../LiveVideos/UserLiveVideosIndex";
import LiveVideosIndex from "../LiveVideos/LiveVideosIndex";
import SingleLiveVideosIndex from "../LiveVideos/SingleLiveVideosIndex";
import JoinLiveVideoIndex from "../LiveVideos/JoinLiveVideoIndex";
import ReferralsIndex from "../Referrals/ReferralsIndex";
import BlockedUserIndex from "../Accounts/BlockedUser/BlockedUserIndex";

import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ForgotPassword from "../Auth/ForgotPassword";
import VideoCallLatest from "../VideoCallLatest/VideoCallLatest";
import CreateVideoCallLatest from "../VideoCallLatest/CreateVideoCallLatest";
import NewLandingPage from "../NewLandingPage/NewLandingPage";
import NewLandingLayout from "../layouts/NewLandingLayout";
import HomePage from "../Whynot/HomePage";
import NewProfile from "../Whynot/NewProfile";
import DeliveryAddressIndex from "../Accounts/DeliveryAddress/DeliveryAddressIndex";
import AddDeliveryAddress from "../Accounts/DeliveryAddress/AddDeliveryAddress";

import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import StoriesSliderModal from "../Home/StoriesSliderModal";
import ScrollToTop from "../helper/ScrollToTop";
import CategoryCard from "../Whynot/CategoryCard";
import SingleProductOrders from "../Ecom/Product/SingleProductOrders";
import ProductGallery from "../Ecom/Product/ProductGallery";
import LiveVideoOrders from "../LiveVideos/LiveVideoOrders";

setTranslations({ en, es });

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  componentDidMount() {
    this.fetchConfig();
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    // console.log(userLanguage);
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      // console.log(configValue.data);
      this.setState({ configLoading: false });
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }

    $("#google_analytics").html(
      configuration.get("configData.google_analytics")
    );

    $("#header_scripts").html(configuration.get("configData.header_scripts"));

    $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>{/* <HomeLoader></HomeLoader> */}</div>
      );
    }
    return (
      <>
        <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            // sizes="16x16"
          />
        </Helmet>
        <ScrollToTop />
        <Switch>
          <AppRoute
            path={"/"}
            component={HomePage}
            exact
            layout={NewLandingLayout}
          />

          <Route exact path="/home">
            <Redirect to="/" />
          </Route>

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/home-old"}
            component={HomePageIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/bookmarks"}
            component={BookmarksIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/bookmark-photo"}
            component={BookmarkPhoto}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/following"}
            component={FollowingIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/fans"}
            component={FanIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/blocked-users"}
            component={BlockedUserIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/list"}
            component={ListIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-profile"}
            component={EditProfile}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile-old"}
            component={ProfileIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/notification"}
            component={NotificationIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payments"}
            component={PaymentsIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/billing-accounts"}
            component={BillingAccountIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/delivery-address"}
            component={DeliveryAddressIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-delivery-address"}
            component={AddDeliveryAddress}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/cards"}
            component={CardsIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-bank"}
            component={AddBankIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/banking"}
            component={BankingIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/wallet"}
            component={Wallet}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/document-upload"}
            component={DocumentUploadIndex}
            layout={MainLayout}
          />

          <AppRoute
            path={"/page/:title"}
            component={StaticPage}
            layout={MainLayout}
          />

          <AppRoute
            path={"/reset-password/:token"}
            component={ResetPassword}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/upload-profile-picture"}
            component={UploadProfilePicture}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-streaming-post"}
            component={LiveStreamingPost}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/single-profile"}
            component={SingleProfile}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-videos-history"}
            component={LiveVideosIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-video/:live_video_unique_id"}
            component={SingleLiveVideosIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-videos"}
            component={UserLiveVideosIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/join/:live_video_unique_id"}
            component={JoinLiveVideoIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/referrals"}
            component={ReferralsIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/dashboard"}
            component={DashboardContentCreator}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/become-a-seller"}
            component={BecomeAContentCreatorIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom/:user_id"}
            component={EcomIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom-cart"}
            component={EcomCartIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom-payment"}
            component={EcomPaymentIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom-payment-method"}
            component={EcomPaymentMethod}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/ecom-category"}
            component={EcomCategoryIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/single-product/:product_unique_id"}
            component={SingleProduct}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-product"}
            component={AddProduct}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-product/:user_product_id"}
            component={EditProduct}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/product-list"}
            component={ProductList}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/order-list"}
            component={OrderList}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/seller-orders"}
            component={SellerOrderList}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/order-view/:id"}
            component={OrderView}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/view-order/:u_id/:id"}
            component={SingleProductOrders}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/product-gallery/:u_id/:id"}
            component={ProductGallery}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/order-transaction"}
            component={OrderTransaction}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/verification"}
            component={VerificationIndex}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/login"}
            component={LoginIndex}
            layout={AuthLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/signup"}
            component={RegisterIndex}
            layout={AuthLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/forgot-password"}
            component={ForgotPassword}
            layout={AuthLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live/:live_video_unique_id"}
            component={VideoCallLatest}
            layout={VideoCallLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/go-live"}
            component={CreateVideoCallLatest}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/live-orders/:live_video_id"}
            component={LiveVideoOrders}
            layout={MainLayout}
          />

          {/* <PrivateRoute
            authentication={this.state.authentication}
            path={"/home"}
            component={HomePage}
            layout={NewLandingLayout}
          /> */}

          <AppRoute
            path={"/category/:categoryId?/:subcategoryId?"}
            component={CategoryCard}
            layout={NewLandingLayout}
          />
          <AppRoute
            path={"/profile"}
            component={NewProfile}
            layout={NewLandingLayout}
          />

          {/* <PrivateRoute
            authentication={this.state.authentication}
            path={"/new-landing-page"}
            component={NewLandingPage}
            layout={NewLandingLayout}
          /> */}

          {/* Dont move this route to top */}
          <AppRoute
            authentication={this.state.authentication}
            path={"/:username"}
            component={SingleProfile}
            layout={MainLayout}
          />
          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}

export default App;
