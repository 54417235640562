import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SingleVideoCard from "./SingleVideoCard";
import { translate, t } from "react-multi-lang";

const CategoriesLiveCardList = (props) => {
  return (
    <>
      {props.liveVideos && props.liveVideos.length > 0 &&
        <>
          <div className="new-card-title pt-8">
            <h4>{props.title}</h4>
            <Link to={`/?category=${props.categoryUniqueId}`}>
             {t("show_all")}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
                </svg>
              </span>
            </Link>
          </div>
          <div className="new-category-list-card">
            {props.liveVideos.map((live, i) => (
              <SingleVideoCard live={live} key={i} />
            ))}
          </div>
        </>
      }
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(null, mapDispatchToProps)(translate(CategoriesLiveCardList));
