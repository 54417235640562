import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import {
  fetchUserDetailsStart,
} from "../../store/actions/UserAction";
import { translate, t } from "react-multi-lang";
import ProfileLoader from "../Loader/ProfileLoader";
import {
  Image,
  Container,
} from "react-bootstrap";
import VerifiedBadgeNoShadow from "../Handlers/VerifiedBadgeNoShadow";
import { Link } from "react-router-dom";
import {
  fetchLiveVideosHistoryStart,
} from "../../store/actions/LiveVideoAction";

const NewProfile = (props) => {

  const [badgeStatus, setBadgeStatus] = useState(0);

  useEffect(() => {
    if (props.profile.loading) {
      props.dispatch(fetchUserDetailsStart());
      setBadgeStatus(localStorage.getItem("is_verified_badge"));
    }
    props.dispatch(fetchLiveVideosHistoryStart());
  }, []);

  return (
    <div>
      {props.profile.loading ? (
        <ProfileLoader></ProfileLoader>
      ) : (
        <section className="new-profile-page">
          <section className="new-profile-sec">
            <div className="new-profile-banner">
              <div className="new-profile-img">
                <Image
                  src={props.profile.data.picture}
                  alt={props.profile.data.name}
                  className="single-profile-user-img"
                />
                {/* <div className="profile-btn">
                  <button>Follow</button>
                </div> */}
              </div>
              <div className="new-profile-content">
                <div className="new-profile-title">
                  <h3>{props.profile.data.name}
                      <small className="ml-2">
                        {props.profile.data.is_verified_badge == 1 ? (
                          <VerifiedBadgeNoShadow />
                        ) : null}
                      </small>
                  </h3>
                  <h4>@{props.profile.data.unique_id}</h4>
                  <h5>
                    {props.profile.data.about_formatted}
                  </h5>
                </div>

                <div className="new-profile-follower">
                  <Link to="/fans">
                    <h5>
                      <span>
                        {localStorage.getItem("total_followers")
                          ? localStorage.getItem("total_followers")
                        : 0}{" "}
                      </span>{t("fans")}
                    </h5>
                  </Link>
                  <Link to="/following">
                    <h5>
                      <span>
                        {localStorage.getItem("total_followings")
                          ? localStorage.getItem("total_followings")
                        : 0}{" "}
                      </span>{t("following")}
                    </h5>
                  </Link>
                </div>
                <div>
                  <Link to='/edit-profile'>
                    <h4>{t("edit_profile")}</h4>
                  </Link>
                </div>
                {/* <div className="new-profile-follower new-profile-rating">
                  <div className="rating-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                      <path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z" />
                    </svg>
                    <h5>
                      <span>4.82</span>Rating
                    </h5>
                  </div>

                  <h5>
                    <span>49,525</span>Sold
                  </h5>
                  <h5>
                    <span>7,363</span>Reviews
                  </h5>
                </div> */}
              </div>
            </div>
          </section>
          <section className="new-profile-card-sec">
            <Container>
              <div className="new-profile-card">
                {/* <div className="new-profile-card-heading">
                  <h3 className="text-center mb-3">
                    Follow {props.profile.data.name} to be alerted of upcoming shows!
                  </h3>
                </div> */}
                {props.liveVideosHistory.loading ? (
                  ''
                ) : (
                  props.liveVideosHistory.data.live_video_upcomings.length > 0 ?
                  <div className="profile-scroll-sec">
                    <div class="new-card-title pt-8">
                      <h4>{t("upcomming_live_shows")}</h4>
                      {/* <a href="">
                        Show All
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                          </svg>
                        </span>
                      </a> */}
                    </div>
                    <div className="profile-slider-sec scrollbar-profile">
                      {props.liveVideosHistory.data.live_video_upcomings.map((live_upcomings) => (
                        <Link to={`/live/${live_upcomings.live_video_unique_id}`}>
                          <div className="new-profile-scroll-card">
                            <div className="profile-slider-card">
                              <img src={live_upcomings.preview_file} alt={live_upcomings.title} />
                              <h5 className="live-title">{live_upcomings.title}</h5>
                            </div>
                            <div className="slider-top-content">{live_upcomings.status_formatted}</div>
                            <div className="upcomming-live-time">{live_upcomings. created_at_formatted}</div>
                           
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                : null)}
                {props.liveVideosHistory.loading ? (
                  ''
                ) : (
                  props.liveVideosHistory.data.live_videos.length > 0 ?
                  <div className="profile-scroll-sec">
                    <div class="new-card-title pt-8">
                      <h4>Live History</h4>
                      {/* <a href="">
                        Show All
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                          </svg>
                        </span>
                      </a> */}
                    </div>
                    <div className="profile-slider-sec scrollbar-profile">
                      {props.liveVideosHistory.data.live_videos.map((video) => (
                        <Link to={video.status === 0 ? `/live/${video.live_video_unique_id}` : null}>
                          <div className="new-profile-scroll-card">
                            <div className="profile-slider-card">
                              <img src={video.preview_file} alt={video.title} />
                              <h5 className="live-title">{video.title}</h5>
                              <h5>{video.category_name}</h5>
                            </div>
                            <div className="slider-top-content">{video.status_formatted}</div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                : '')}
              </div>
            </Container>
          </section>
        </section>
      )}
    </div>
  );
}

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  liveVideosHistory: state.liveVideo.liveVideosHistory,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NewProfile));
