import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import "./Verification.css";
import { Link } from "react-router-dom";
import NewSendTipModal from "./NewSendTipModal";
import CreateContentCreatorModal from "../helper/CreateContentCreatorModal";
import { translate, t } from "react-multi-lang";

const VerificationIndex = (props) => {

    const [newSendTipModal, setNewSendTipModal] = useState(false);

    const closeNewSendTipModal = () => {
        setNewSendTipModal(false);
    };

    const [createContentCreatorModal, setCreateContentCreatorModal] = useState(false);

    const closeCreateContentCreatorModal = () => {
        setCreateContentCreatorModal(false);
    };


    return (
        <>
            <div className="verification-sec">
                <Container>
                    <Link to="#" className="" onClick={() => setNewSendTipModal(true)} >{t("send_tip_model")}</Link><br></br>
                    <Link to="#" className="" onClick={() => setCreateContentCreatorModal(true)} >{t("create_content_creator_modal")}</Link>
                    <div className="c-email">
                        <div className="c-email-header">
                            <h1 className="c-email-header-title">{t("your_verification_code")}</h1>
                        </div>
                        <div className="c-email-content">
                            <Form>
                            <p className="c-email-content-text text-title">
                               {t("enter_this_verification_code_in_field")}
                            </p>
                            <div className="c-email-code">
                                <span className="c-email-code-text">
                                    <Form.Control type="number" placeholder="1" />
                                </span>
                                <span className="c-email-code-text">
                                    <Form.Control type="number" placeholder="2" />
                                </span>
                                <span className="c-email-code-text">
                                    <Form.Control type="number" placeholder="3" />
                                </span>
                                <span className="c-email-code-text">
                                    <Form.Control type="number" placeholder="4" />
                                </span>
                                <span className="c-email-code-text">
                                    <Form.Control type="number" placeholder="5" />
                                </span>
                                <span className="c-email-code-text">
                                    <Form.Control type="number" placeholder="6" />
                                </span>
                            </div>
                            <p className="c-email-content-text text-italic opacity-30 text-title mb-0">{t("varification_valid_time")}</p>
                            <div className="verify-btn-sec">
                                <Button className="verify-btn">{t("verify")}</Button>
                            </div>
                            </Form>
                        </div>
                        <div className="c-email-footer"></div>
                    </div>
                </Container>
            </div>

            <NewSendTipModal
                newSendTipModal={newSendTipModal}
                closeNewSendTipModal={closeNewSendTipModal}
            />
            <CreateContentCreatorModal
                createContentCreatorModal={createContentCreatorModal}
                closeCreateContentCreatorModal={closeCreateContentCreatorModal}
            />
        </>
    );
};

export default (translate(VerificationIndex));
