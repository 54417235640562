import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
    FETCH_HOME_FEATURED_SELLERS_START,
    FETCH_HOME_CATEGORIES_START,
    FETCH_CATEGORIES_START,
    FETCH_SUB_CATEGORIES_START,
    FETCH_CATEGORIES_LIST_START,
    FOLLOW_CATEGORIES_START,
} from "../actions/ActionConstant";
import {
    fetchHomeFeaturedSellersFailure,
    fetchHomeFeaturedSellersSuccess,
    fetchHomeCategoriesSuccess,
    fetchHomeCategoriesFailure,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    fetchSubCategoriesSuccess,
    fetchSubCategoriesFailure,
    fetchCategoriesListFailure,
    fetchCategoriesListSuccess,
    followCategoriesSuccess,
    followCategoriesFailure,
} from "../actions/LookUpAction";

function* fetchHomeFeaturedSellersAPI() {
    try {
        const response = yield api.postMethod("featured_sellers_home");
        if (response.data.success) {
            yield put(fetchHomeFeaturedSellersSuccess(response.data.data));
        } else {
            yield put(fetchHomeFeaturedSellersFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchHomeFeaturedSellersFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchHomeCategoriesAPI() {
    try {
        const response = yield api.postMethod("categories_home");
        if (response.data.success) {
            yield put(fetchHomeCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchHomeCategoriesFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchHomeCategoriesFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchCategoriesAPI() {
    try {
        const response = yield api.postMethod("categories");
        if (response.data.success) {
            yield put(fetchCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchCategoriesFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchCategoriesFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchSubCategoriesAPI(action) {
    try {
        const response = yield api.postMethod("sub_categories", action.data);
        if (response.data.success) {
            yield put(fetchSubCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchSubCategoriesFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchSubCategoriesFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchCategoriesListAPI() {
    try {
        const response = yield api.postMethod("categories_list");
        if (response.data.success) {
            yield put(fetchCategoriesListSuccess(response.data.data));
        } else {
            yield put(fetchCategoriesListFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchCategoriesListFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* followCategoriesAPI(action) {
    try {
        const response = yield api.postMethod("follow_category_sub_categories", action.data);
        if (response.data.success) {
            yield put(followCategoriesSuccess(response.data.data));
        } else {
            yield put(followCategoriesFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(followCategoriesFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

export default function* pageSaga() {
    yield all([
        yield takeLatest(FETCH_HOME_FEATURED_SELLERS_START, fetchHomeFeaturedSellersAPI),
        yield takeLatest(FETCH_HOME_CATEGORIES_START, fetchHomeCategoriesAPI),
        yield takeLatest(FETCH_CATEGORIES_START, fetchCategoriesAPI),
        yield takeLatest(FETCH_SUB_CATEGORIES_START, fetchSubCategoriesAPI),
        yield takeLatest(FETCH_CATEGORIES_LIST_START, fetchCategoriesListAPI),
        yield takeLatest(FOLLOW_CATEGORIES_START, followCategoriesAPI),
    ]);
}