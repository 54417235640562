import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Table, Form, Image } from "react-bootstrap";
import "./Ecom.css";
import { Link } from "react-router-dom";
import QuickViewModal from "./QuickViewModal";
import { translate, t } from "react-multi-lang";

const EcomCategoryIndex = (props) => {

    const [quickViewModal, setQuickViewModal] = useState(false);

    const closeQuickViewModal = () => {
      setQuickViewModal(false);
    };

    return (
        <>
            <div className="ecom-category-sec">
                <Container>
                    <h2>{t("ecom_category")}</h2>
                    <Row>
                        <Col md={3}>
                            <div className="ecom-category-card">
                                <Form.Group controlId="formBasicEmail" className="mb-4">
                                    <Form.Label>{t("search")}</Form.Label>
                                    <Form.Control type="email" placeholder="Search" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>{t("category")}</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="mr-sm-2"
                                        id="inlineFormCustomSelect"
                                        custom
                                    >
                                        <option value="0">{t("pant")}</option>
                                        <option value="1">{t("Shirt")}</option>
                                        <option value="2">{t("Jeans")}</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>{t("sub_category")}</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="mr-sm-2"
                                        id="inlineFormCustomSelect"
                                        custom
                                    >
                                        <option value="0">{t("pant")}</option>
                                        <option value="1">{t("Shirt")}</option>
                                        <option value="2">{t("Jeans")}</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <div className="">
                                        <Form.Check
                                            type="radio"
                                            id="customControlAutosizing-1"
                                            label="High to Low"
                                            custom
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <div className="">
                                        <Form.Check
                                            type="radio"
                                            id="customControlAutosizing-1"
                                            label="Low to High"
                                            custom
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <Button className="submit-btn" href="#">{t("submit")}</Button>
                                </Form.Group>
                            </div>
                        </Col>
                        <Col md={9}>
                            <Row>
                                <Col md={12}>
                                    <div className="ecom-featured-box ecom-category-box">
                                        <div className="ecom-featured-card">
                                            <div className="ecom-featured-product-item">
                                                <div className="ecom-featured-img-sec">
                                                    <Link to="#">
                                                        {/* <div className="ecom-featured-labels">
                                                    <div className="onsale">-17%</div>
                                                </div> */}
                                                        <Image
                                                            className="ecom-featured-img"
                                                            src={
                                                                window.location.origin + "/assets/images/ecom/ecom-1.jpg"
                                                            }
                                                        />
                                                    </Link>
                                                    <div className="links-on-image">
                                                        <div className="ecom-featured-cart-icon-sec">
                                                            <Link to="#">
                                                                <Image
                                                                    className="ecom-featured-cart-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/ecom/shopping-bag.svg"
                                                                    }
                                                                />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="links-on-action-btn-sec">
                                                        <Button className="quick-view-btn" onClick={() => setQuickViewModal(true)}>
                                                            {t("quick_view")}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="ecom-featured-info">
                                                    <div className="ecom-features-info-type">
                                                        <span className="category-list">
                                                            <Link to="#">{t("FASHION")}</Link>,&nbsp;
                                                            <Link to="#">{t("FEATURED_PRODUCTS")}</Link>
                                                        </span>
                                                    </div>
                                                    <Link href="#" className="product-title">
                                                        <h3>{t("yellow_men_shoes")}</h3>
                                                    </Link>
                                                    <div className="ecom-featured-price-sec">
                                                        <ins>
                                                            <span>$49.00</span>
                                                        </ins>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ecom-featured-card">
                                            <div className="ecom-featured-product-item">
                                                <div className="ecom-featured-img-sec">
                                                    <Link to="#">
                                                        {/* <div className="ecom-featured-labels">
                                                    <div className="onsale">-17%</div>
                                                </div> */}
                                                        <Image
                                                            className="ecom-featured-img"
                                                            src={
                                                                window.location.origin + "/assets/images/ecom/ecom-2.jpg"
                                                            }
                                                        />
                                                    </Link>
                                                    <div className="links-on-image">
                                                        <div className="ecom-featured-cart-icon-sec">
                                                            <Link to="#">
                                                                <Image
                                                                    className="ecom-featured-cart-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/ecom/shopping-bag.svg"
                                                                    }
                                                                />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="links-on-action-btn-sec">
                                                        <Button className="quick-view-btn" onClick={() => setQuickViewModal(true)}>
                                                            {t("quick_view")}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="ecom-featured-info">
                                                    <div className="ecom-features-info-type">
                                                        <span className="category-list">
                                                            <Link to="#">{t("FASHION")}</Link>,&nbsp;
                                                            <Link to="#">{t("FEATURED_PRODUCTS")}</Link>
                                                        </span>
                                                    </div>
                                                    <Link href="#" className="product-title">
                                                        <h3>{t("yellow_men_shoes")}</h3>
                                                    </Link>
                                                    <div className="ecom-featured-price-sec">
                                                        <ins>
                                                            <span>$49.00</span>
                                                        </ins>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ecom-featured-card">
                                            <div className="ecom-featured-product-item">
                                                <div className="ecom-featured-img-sec">
                                                    <Link to="#">
                                                        {/* <div className="ecom-featured-labels">
                                                    <div className="onsale">-17%</div>
                                                </div> */}
                                                        <Image
                                                            className="ecom-featured-img"
                                                            src={
                                                                window.location.origin + "/assets/images/ecom/ecom-3.jpg"
                                                            }
                                                        />
                                                    </Link>
                                                    <div className="links-on-image">
                                                        <div className="ecom-featured-cart-icon-sec">
                                                            <Link to="#">
                                                                <Image
                                                                    className="ecom-featured-cart-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/ecom/shopping-bag.svg"
                                                                    }
                                                                />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="links-on-action-btn-sec">
                                                        <Button className="quick-view-btn" onClick={() => setQuickViewModal(true)}>
                                                          {t("quick_view")}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="ecom-featured-info">
                                                    <div className="ecom-features-info-type">
                                                        <span className="category-list">
                                                            <Link to="#">{t("FASHION")}</Link>,&nbsp;
                                                            <Link to="#">{t("FEATURED_PRODUCTS")}</Link>
                                                        </span>
                                                    </div>
                                                    <Link href="#" className="product-title">
                                                        <h3>{t("yellow_men_shoes")}</h3>
                                                    </Link>
                                                    <div className="ecom-featured-price-sec">
                                                        <ins>
                                                            <span>$49.00</span>
                                                        </ins>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ecom-featured-card">
                                            <div className="ecom-featured-product-item">
                                                <div className="ecom-featured-img-sec">
                                                    <Link to="#">
                                                        {/* <div className="ecom-featured-labels">
                                                    <div className="onsale">-17%</div>
                                                </div> */}
                                                        <Image
                                                            className="ecom-featured-img"
                                                            src={
                                                                window.location.origin + "/assets/images/ecom/ecom-4.jpg"
                                                            }
                                                        />
                                                    </Link>
                                                    <div className="links-on-image">
                                                        <div className="ecom-featured-cart-icon-sec">
                                                            <Link to="#">
                                                                <Image
                                                                    className="ecom-featured-cart-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/ecom/shopping-bag.svg"
                                                                    }
                                                                />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="links-on-action-btn-sec">
                                                        <Button className="quick-view-btn" onClick={() => setQuickViewModal(true)}>
                                                           {t("quick_view")}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="ecom-featured-info">
                                                    <div className="ecom-features-info-type">
                                                        <span className="category-list">
                                                            <Link to="#">{t("FASHION")}</Link>,&nbsp;
                                                            <Link to="#">{t("FEATURED_PRODUCTS")}</Link>
                                                        </span>
                                                    </div>
                                                    <Link href="#" className="product-title">
                                                        <h3>{t("yellow_men_shoes")}</h3>
                                                    </Link>
                                                    <div className="ecom-featured-price-sec">
                                                        <ins>
                                                            <span>$49.00</span>
                                                        </ins>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <QuickViewModal
          quickViewModal={quickViewModal}
          closeQuickViewModal={closeQuickViewModal}
        />
        </>
    );
};

export default (translate(EcomCategoryIndex));
