import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Container,
  Form,
  Button,
  Collapse,
  Media,
} from "react-bootstrap";
import "./VideoCall.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { videoCallBroadcastStart } from "../../store/actions/LiveVideoAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import DateTimePicker from "react-datetime-picker";
import dayjs from "dayjs";
import { fetchUserProductsStart } from "../../store/actions/ProductsAction";
import {
  fetchCategoriesListStart,
  fetchSubCategoriesStart,
} from "../../store/actions/LookUpAction";
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc);
dayjs.extend(timezone);
const CreateVideoCallLatest = (props) => {
  const [startTime, setStartTime] = useState(new Date());

  const [previewImage, setPreviewImage] = useState("");

  const [previewVideo, setPreviewVideo] = useState("");

  const [userProducts, setUserProducts] = useState([]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
  };

  const [open, setOpen] = useState(false);

  const [inputData, setInputData] = useState({ live_schedule_type: 1 });
  const [paymentStatus, setPaymentStatus] = useState(0);

  useEffect(() => {
    props.dispatch(fetchUserProductsStart());
    props.dispatch(fetchCategoriesListStart());
  }, []);

  const handleSubmit = (event) => {
    let scheduleTime = dayjs(startTime).format("YYYY-MM-DD H:m:s");
    if (inputData.live_schedule_type === 1) {
      scheduleTime = dayjs.tz(new Date()).format("YYYY-MM-DD H:m:s",Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
    console.log(scheduleTime);
    let newInputData = {
      ...inputData,
      schedule_time: scheduleTime,
    };
    setInputData(newInputData);
    event.preventDefault();
    props.dispatch(videoCallBroadcastStart(newInputData));
  };

  const handleSelectProduct = (event) => {
    let input = event.currentTarget;
    if (input.type === "checkbox") {
      if (input.checked) {
        if (inputData["product_ids"] === undefined) {
          let array = [];
          const value = parseInt(input.value);
          array.push(value);
          setInputData({
            ...inputData,
            product_ids: array,
          });
        } else {
          const value = parseInt(input.value);
          inputData["product_ids"].push(value);
          setInputData(inputData);
        }
      } else {
        const value = parseInt(input.value);
        let index = inputData["product_ids"].indexOf(value);
        if (index !== -1) {
          inputData["product_ids"].splice(index, 1);
          setInputData(inputData);
        }
      }
    }
  };

  const handleChangeImage = (event) => {
    if (event.currentTarget.type === "file") {
      let reader = new FileReader();
      let file = event.currentTarget.files[0];
      let fileType = "";

      if (file.type.indexOf("image") == 0) {
        reader.onloadend = () => {
          setPreviewImage(reader.result);
          setPreviewVideo("");
        };
        fileType = "image";
      } else {
        reader.onloadend = () => {
          setPreviewVideo(reader.result);
          setPreviewImage("");
        };
        fileType = "video";
      }

      if (file) {
        reader.readAsDataURL(file);
      }

      setInputData({
        ...inputData,
        preview_file: event.currentTarget.files[0],
        preview_file_type: fileType,
      });
    }
  };

  return (
    <>
      <div className="create-video-call-latest-sec">
        {props.userProducts.loading ? null : (

          <Container>

            {props.userProducts.data.user_products.length > 0 ? (
              <div className="create-video-call-latest-box">
                <Row>
                  <Col md={12} xl={6} lg={6}>

                    <div className="create-video-call-left-form-sec">



                      <Form
                        className="create-video-call-form"
                        onSubmit={handleSubmit}
                      >
                        <h4>{t("lets_get_started")}</h4>
                        <p>{t("create_a_live_stream")}</p>



                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>{t("title")}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("title")}
                            value={inputData.title ? inputData.title : null}
                            onChange={(event) =>
                              setInputData({
                                ...inputData,
                                title: event.currentTarget.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>{t("category")}</Form.Label>
                          <div className="">
                            <select
                              className="form-control mw-200 mb-3"
                              onChange={(event) => {
                                setInputData({
                                  ...inputData,
                                  category_id: event.currentTarget.value,
                                });
                                props.dispatch(
                                  fetchSubCategoriesStart({
                                    category_id: event.currentTarget.value,
                                  })
                                );
                              }}
                              name="category_id"
                            >
                              <option value="">{t("select_category")}</option>

                              {!props.categoriesList.loading &&
                                props.categoriesList.data.categories.length > 0
                                ? props.categoriesList.data.categories.map(
                                  (category, i) => (
                                    <option value={category.category_id}>
                                      {category.name}
                                    </option>
                                  )
                                )
                                : ""}
                            </select>
                          </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>{t("sub_category")}</Form.Label>
                          <div className="">
                            <select
                              className="form-control mw-200 mb-3"
                              onChange={(event) => {
                                setInputData({
                                  ...inputData,
                                  sub_category_id: event.currentTarget.value,
                                });
                              }}
                              name="sub_category_id"
                            >
                              <option value="">{t("select_sub_category")}</option>

                              {!props.subCategories.loading &&
                                props.subCategories.data.sub_categories.length > 0
                                ? props.subCategories.data.sub_categories.map(
                                  (sub_category, i) => (
                                    <option value={sub_category.sub_category_id}>
                                      {sub_category.name}
                                    </option>
                                  )
                                )
                                : ""}
                            </select>
                          </div>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>{t("description")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            className="height-auto"
                            maxlength="256"
                            rows={3}
                            value={
                              inputData.description ? inputData.description : null
                            }
                            placeholder={t("go_live_description_placeholder")}
                            onChange={(event) =>
                              setInputData({
                                ...inputData,
                                description: event.currentTarget.value,
                              })
                            }
                          />
                          {t("description_note")}
                        </Form.Group>
                       

                        <Form.Group className="streaming-type-sec">
                          <h6 className="custom-radio-inline-title">
                            {t("choose_streaming_type")}
                          </h6>
                          <div className="two-radio-btn">
                            <label class="radio-inline custom-radio-inline">
                              <input
                                type="radio"
                                id="now"
                                value="1"
                                name="live_schedule_type"
                                defaultChecked={true}
                                onChange={(event) =>
                                  setInputData({
                                    ...inputData,
                                    live_schedule_type: 1,
                                  })
                                }
                              />{" "}
                              {t("now")}
                            </label>
                            <label class="radio-inline custom-radio-inline">
                              <input
                                type="radio"
                                id="later"
                                value="2"
                                name="live_schedule_type"
                                onChange={(event) =>
                                  setInputData({
                                    ...inputData,
                                    live_schedule_type: 2,
                                  })
                                }
                              />{" "}
                              {t("later")}
                            </label>
                          </div>
                        </Form.Group>

                        {inputData.live_schedule_type == 2 ? (
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <DateTimePicker
                              onChange={setStartTime}
                              className="floating-input"
                              name="start_time"
                              required={true}
                              value={startTime}
                              isClockOpen={false}
                              minDate={new Date()}
                              format={"dd-MM-y HH:mm:ss"}
                            />
                          </Form.Group>
                        ) : (
                          ""
                        )}
                        <Form.Group>
                          <div className="upload-btn-wrapper">
                            <button className="btn">
                              {t("upload_preview_image")}
                            </button>
                            <input
                              type="file"
                              name="preview_file"
                              accept="image/png, image/jpg, image/jpeg"
                              onChange={(event) => handleChangeImage(event)}
                            />
                          </div>
                        </Form.Group>
                        {previewImage !== "" ? (
                          <div className="upload-img-preview-sec">
                            <Image
                              className="upload-img-preview"
                              src={previewImage}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {previewVideo !== "" ? (
                          <Row>
                            <Col sm={12} md={12}>
                              <div className="post-img-preview-sec my-3 my-lg-4">
                                <video
                                  autoplay
                                  controls
                                  id="myVideo"
                                  className="user-profile1 w-100"
                                >
                                  <source src={previewVideo} type="video/mp4" />
                                </video>
                              </div>
                            </Col>
                          </Row>
                        ) : null}

                        <div classname="choose-multiple-product-sec">
                          <Button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                          >
                            {t("click_to_choose_products")}
                          </Button>
                          {props.userProducts.loading ? null : (
                            <Collapse in={open}>
                              <div id="example-collapse-text">
                                <div className="product-check-box-sec">
                                  <ul className="list-unstyled">
                                    {props.userProducts.data.user_products.length >
                                      0
                                      ? props.userProducts.data.user_products.map(
                                        (product, index) => (
                                          <Media
                                            as="li"
                                            key={`myCheckbox` + index}
                                          >
                                            <input
                                              type="checkbox"
                                              id={`myCheckbox` + index}
                                              value={product.user_product_id}
                                              onChange={(event) =>
                                                handleSelectProduct(event)
                                              }
                                            />
                                            <label for={`myCheckbox` + index}>
                                              <Image
                                                className=""
                                                src={product.picture}
                                              />
                                              <div className="products-info">
                                                <h5>{product.name}</h5>
                                                <p>{product.category_name}</p>
                                              </div>
                                            </label>
                                          </Media>
                                        )
                                      )
                                      : ""}
                                  </ul>
                                </div>
                              </div>
                            </Collapse>
                          )}
                        </div>
                        <div className="create-video-call-btn-sec">
                          <Link
                            to="#"
                            type="submit"
                            onClick={handleSubmit}
                            className="create-video-call-btn"
                            disabled={props.videocall.buttonDisable}
                          >
                            {t("go_live")}
                          </Link>
                        </div>
                      </Form>

                    </div>
                  </Col>
                  <Col md={12} xl={6} lg={6}>
                    <div className="create-video-call-right-sec">
                      <div className="create-video-img-sec">
                        <Slider {...settings}>
                          <div>
                            <Image
                              className="create-video-img"
                              src={
                                window.location.origin +
                                "/assets/images/video-call/create-meeting-1.svg"
                              }
                            />
                          </div>
                          <div>
                            <Image
                              className="create-video-img"
                              src={
                                window.location.origin +
                                "/assets/images/video-call/create-meeting-2.svg"
                              }
                            />
                          </div>
                          <div>
                            <Image
                              className="create-video-img"
                              src={
                                window.location.origin +
                                "/assets/images/video-call/create-meeting-3.svg"
                              }
                            />
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="no-product-found text-center m-5">

                <Image
                  className="no-product-found-img"
                  src={
                    window.location.origin +
                    "/assets/images/video-call/no-product-found.png"
                  }
                />
                <h3 className="text-center mt-5 mb-5">{t("no_product_found_desc")}</h3>
                <div className="add-product-btn"> 
                  <Link to="/add-product" className="create-video-call-btn">
                    <span>{t("add_product")}</span>
                  </Link>
                </div>
              </div>
            )}

          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  videocall: state.liveVideo.saveLiveVideo,
  userProducts: state.userProducts.products,
  categoriesList: state.lookUp.categoriesList,
  subCategories: state.lookUp.subCategories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(CreateVideoCallLatest));
