import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    FETCH_DELIVERY_ADDRESS_START,
    SAVE_DELIVERY_ADDRESS_START,
    DELETE_DELIVERY_ADDRESS_START,
    SET_DEFAULT_DELIVERY_ADDRESS_START,
} from '../actions/ActionConstant';
import { checkLogoutStatus } from "../actions/ErrorAction";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
    fetchDeliveryAddressSuccess,
    fetchDeliveryAddressFailure,
    saveDeliveryAddressSuccess,
    saveDeliveryAddressFailure,
    deleteDeliveryAddressSuccess,
    deleteDeliveryAddressFailure,
    setDefaultDeliveryAddressSuccess,
    setDefaultDeliveryAddressFailure,
    fetchDeliveryAddressStart,
} from '../actions/DeliveryAddressAction';

function* fetchDeliveryAddressAPI() {
    try {
        const response = yield api.postMethod("delivery_addresses_list");

        if (response.data.success) {
            yield put(fetchDeliveryAddressSuccess(response.data.data));
        } else {
            yield put(fetchDeliveryAddressFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(checkLogoutStatus(response.data));
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchDeliveryAddressFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* saveDeliveryAddressAPI(action) {
    try {
        const response = yield api.postMethod("delivery_addresses_save", action.data);

        if (response.data.success) {
            yield put(saveDeliveryAddressSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
            window.location.assign("/delivery-address");
        } else {
            yield put(saveDeliveryAddressFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(checkLogoutStatus(response.data));
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(saveDeliveryAddressFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* deleteDeliveryAddressAPI(action) {
    try {
        const response = yield api.postMethod("delivery_addresses_delete", action.data);

        if (response.data.success) {
            yield put(deleteDeliveryAddressSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
            yield put(fetchDeliveryAddressStart());
        } else {
            yield put(deleteDeliveryAddressFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(checkLogoutStatus(response.data));
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(deleteDeliveryAddressFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* setDefaultDeliveryAddressAPI(action) {
    try {
        const response = yield api.postMethod("delivery_addresses_default", action.data);

        if (response.data.success) {
            yield put(setDefaultDeliveryAddressSuccess(response.data.data));
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
            yield put(fetchDeliveryAddressStart());
        } else {
            yield put(setDefaultDeliveryAddressFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(checkLogoutStatus(response.data));
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(setDefaultDeliveryAddressFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}


export default function* pageSaga() {
    yield all([
        yield takeLatest(FETCH_DELIVERY_ADDRESS_START, fetchDeliveryAddressAPI),
        yield takeLatest(SAVE_DELIVERY_ADDRESS_START, saveDeliveryAddressAPI),
        yield takeLatest(DELETE_DELIVERY_ADDRESS_START, deleteDeliveryAddressAPI),
        yield takeLatest(SET_DEFAULT_DELIVERY_ADDRESS_START, setDefaultDeliveryAddressAPI),
    ]);
}