import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Image,
  Container,
  Media,
  Tab,
  Nav,
  Button,
  Modal,
} from "react-bootstrap";
import "./SingleProfile.css";
import { Link } from "react-router-dom";
import {
  fetchSingleUserProfileStart,
} from "../../../store/actions/OtherUserAction";
import { unFollowUserStart, followUserStart } from "../../../store/actions/FollowAction";
import { saveBlockUserStart } from "../../../store/actions/UserAction";
import { getSuccessNotificationMessage } from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ProfileLoader from "../../Loader/ProfileLoader";
import { translate, t } from "react-multi-lang";
import VerifiedBadgeNoShadow from "../../Handlers/VerifiedBadgeNoShadow";
import configuration from "react-global-configuration";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import {
  fetchLiveVideosHistoryStart,
} from "../../../store/actions/LiveVideoAction";
import { fetchOtherModelProductListStart } from "../../../store/actions/ProductsAction";
import SingleDisplayCard from "../../Ecom/Product/SingleDisplayCard";

const SingleProfile = (props) => {
  const toggleVisibility = () => { };
  const [followStatus, setFollowStatus] = useState("");

  useEffect(() => {
    props.dispatch(
      fetchSingleUserProfileStart({
        user_unique_id: props.match.params.username,
      })
    );
    props.dispatch(
      fetchOtherModelProductListStart({
        user_unique_id: props.match.params.username,
      })
    );
    props.dispatch(
      fetchLiveVideosHistoryStart({
        user_unique_id: props.match.params.username,
      })
    );
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  const [starStatus, setStarStatus] = useState("");
  const [showUnfollow, setShowUnfollow] = useState(false);
  const [blockUserStatus, setBlockUserStatus] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);


  const blockStatusUpdate = () => {
    if (props.loading == false) {
      setBlockUserStatus(
        props.data.is_block_user == 1 ? "blocked" : "unblocked"
      );
    }
  };

  const handleBlockUser = (event, status, user_id) => {
    event.preventDefault();
    setBlockUserStatus(status);
    props.dispatch(
      saveBlockUserStart({
        user_id: user_id,
        is_other_profile: 1,
      })
    );
  };

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const handleStar = (event, user_id, status) => {
    event.preventDefault();
    setStarStatus(status);
    // props.dispatch(
    //   saveFavStart({
    //     user_id: user_id,
    //   })
    // );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Link to profile was copied to clipboard!"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const { userDetails } = props;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <>
      <div>
        {userDetails.loading ? (
          <ProfileLoader></ProfileLoader>
        ) : (
          <section className="new-profile-page">
            <section className="new-profile-sec">
              <div className="new-profile-banner">
                <div className="new-profile-img">
                  <Image
                    src={userDetails.data.user.picture}
                    alt={userDetails.data.user.name}
                    className="single-profile-user-img"
                  />

                  <div className="profile-btn">
                    {followStatus !== "" ? (
                      <>
                        <>
                          {followStatus === "follow" ? (
                            <Button
                              onClick={(event) => {
                                setFollowStatus('unfollow');
                                props.dispatch(
                                  unFollowUserStart({
                                    user_id: userDetails.data.user.user_id,
                                  })
                                );
                              }}
                            >
                              {t("unfollow")}
                            </Button>
                          ) : null}
                        </>
                        <>
                          {followStatus === "unfollow" ? (
                            <Button
                              onClick={(event) => {
                                setFollowStatus('follow');
                                props.dispatch(
                                  followUserStart({
                                    user_id: userDetails.data.user.user_id,
                                  })
                                );
                              }}
                            >
                              {t("follow")}
                            </Button>
                          ) : null}
                        </>
                      </>
                    ) : userDetails.data.is_following == 1 ? (
                      <Button
                        onClick={(event) => {
                          setFollowStatus('unfollow');
                          props.dispatch(
                            unFollowUserStart({
                              user_id: userDetails.data.user.user_id,
                            })
                          );
                        }}
                      >
                        {t("unfollow")}
                      </Button>
                    ) : (
                      <Button
                        onClick={(event) => {
                          setFollowStatus('follow');
                          props.dispatch(
                            followUserStart({
                              user_id: userDetails.data.user.user_id,
                            })
                          );
                        }}
                      >
                        {t("follow")}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="new-profile-content">
                  <div className="new-profile-title">
                    <h3>{userDetails.data.user.name}
                      <small className="ml-2">
                        {userDetails.data.user.is_verified_badge == 1 ? (
                          <VerifiedBadgeNoShadow />
                        ) : null}
                      </small>
                    </h3>
                    <h5>
                      {userDetails.data.user.about_formatted}
                    </h5>
                  </div>

                  <div className="new-profile-follower">
                    <Link to="/fans">
                      <h5>
                        <span>
                          {/* {localStorage.getItem("total_followers")
                            ? localStorage.getItem("total_followers")
                          : 0}{" "} */}
                          {userDetails.data.total_followers}
                        </span>{t("fans")}
                      </h5>
                    </Link>
                    <Link to="/following">
                      <h5>
                        <span>
                          {/* {localStorage.getItem("total_followings")
                            ? localStorage.getItem("total_followings")
                          : 0}{" "} */}
                          {userDetails.data.total_followings}
                        </span>{t("following")}
                      </h5>
                    </Link>

                    {configuration.get('configData.is_offline_products_available') == 1 ?
                      <Link to={"/ecom/" + userDetails.data.user.user_id}>
                        <h5>
                          <span>
                            {userDetails.data.user.total_user_products}{" "}
                          </span>{t("products")}
                        </h5>
                      </Link>
                      : ''}
                  </div>
                  {/* <div className="new-profile-follower new-profile-rating">
                    <div className="rating-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z" />
                      </svg>
                      <h5>
                        <span>4.82</span>Rating
                      </h5>
                    </div>

                    <h5>
                      <span>49,525</span>Sold
                    </h5>
                    <h5>
                      <span>7,363</span>Reviews
                    </h5>
                  </div> */}
                </div>
              </div>
            </section>
            <section className="new-profile-card-sec">
              <Container>
                <div className="new-profile-card">
                  <div className="new-profile-card-heading">
                    {userDetails.data.is_following == 0 ? (
                      <h3 className="text-center mb-3">
                        Follow {userDetails.data.user.name} to be alerted of upcoming shows!
                      </h3>
                    ) : (
                      null
                    )}
                  </div>

                  {props.liveVideosHistory.loading ? (
                    ''
                  ) : (
                    props.liveVideosHistory.data.live_video_upcomings.length > 0 ?
                      <div className="profile-scroll-sec">
                        <div class="new-card-title pt-8">
                          <h4>{t("upcomming_live_shows")}</h4>
                          {/* <a href="">
                          Show All
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                            </svg>
                          </span>
                        </a> */}
                        </div>
                        <div className="profile-slider-sec">
                          {props.liveVideosHistory.data.live_video_upcomings.map((live_upcomings) => (
                            <Link to={`/live/${live_upcomings.live_video_unique_id}`}>
                              <div className="new-profile-scroll-card">
                                <div className="profile-slider-card">
                                  <img src={live_upcomings.preview_file} alt={live_upcomings.title} />
                                  <h5>{live_upcomings.title}</h5>
                                </div>
                                <div className="slider-top-content">{live_upcomings.status_formatted}</div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                      : '')}
                  {props.liveVideosHistory.loading ? (
                    ''
                  ) : (props.liveVideosHistory.data.live_videos.length > 0 ?
                    <div className="profile-scroll-sec">
                      <div class="new-card-title pt-8">
                        <h4>Live History</h4>
                        {/* <a href="">
                          Show All
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                            </svg>
                          </span>
                        </a> */}
                      </div>
                      <div className="profile-slider-sec">
                        {props.liveVideosHistory.data.live_videos.map((video) => (
                          <Link to={`/live/${video.live_video_unique_id}`}>
                            <div className="new-profile-scroll-card">
                              <div className="profile-slider-card">
                                <img src={video.preview_file} alt={video.title} />
                                <h5>{video.title}</h5>
                              </div>
                              <div className="slider-top-content">{video.status_formatted}</div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                    : '')}
                  {configuration.get('configData.is_offline_products_available') == 1 ?
                    <Row>
                      <div className="profile-scroll-sec">
                        <div class="new-card-title pt-8">
                          <h4>Products</h4>
                          <Link to={"/ecom/" + userDetails.data.user.user_id}>
                            Show All
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                      <Col md={12}>
                        <div className="ecom-featured-box">
                          {props.products.loading ? (
                            ''
                          ) : (props.products.data.user_products && props.products.data.user_products.length > 0 ?

                            props.products.data.user_products.map((product) =>
                              <SingleDisplayCard product={product}></SingleDisplayCard>
                            )
                            : '')}
                        </div>
                      </Col>
                    </Row>
                    : ''}
                </div>
              </Container>
            </section>
          </section>
        )}
      </div>
      {userDetails.loading ? (
        t("loading")
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  userDetails: state.otherUser.userDetails,
  liveVideosHistory: state.liveVideo.liveVideosHistory,
  products: state.userProducts.otherModelProducts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SingleProfile));
