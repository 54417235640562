import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  fetchSingleUserProfileFailure,
  fetchSingleUserProfileSuccess,
} from "../actions/OtherUserAction";
import { FETCH_SINGLE_USER_PROFILE_START } from "../actions/ActionConstant";

function* fetchOtherUserProfileAPI() {
  try {
    const inputData = yield select(
      (state) => state.otherUser.userDetails.inputData
    );
    if (inputData.user_unique_id == localStorage.getItem("user_unique_id")) {
      window.location.assign("/profile");
    }
    const response = yield api.postMethod("other_profile", inputData);
    if (response.data.success) {
      yield put(fetchSingleUserProfileSuccess(response.data.data));
    } else {
      yield put(fetchSingleUserProfileFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/");
    }
  } catch (error) {
    yield put(fetchSingleUserProfileFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_SINGLE_USER_PROFILE_START, fetchOtherUserProfileAPI),
  ]);
}
