import React, { useEffect } from "react";
import { Row, Col, Image, Container, Media } from "react-bootstrap";
import "./NewLandingPage.css";
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import Slider from "react-slick";
import { connect } from "react-redux";
import ThemeFooter from "../layouts/Footer/ThemeFooter";
import ThemeHeader from "../layouts/Header/ThemeHeader";
import { fetchLiveStreamingsOngoingStart, fetchUpcommingLiveStreamingsStart } from "../../store/actions/LiveVideoAction";
import { fetchHomeFeaturedSellersStart, fetchHomeCategoriesStart } from "../../store/actions/LookUpAction";
import LandingStreamingLoader from "../Loader/LandingStreamningLoader";
import { translate, t } from "react-multi-lang";

const NewLandingPage = (props) => {

    useEffect(() => {
        props.dispatch(fetchHomeCategoriesStart());
        props.dispatch(fetchLiveStreamingsOngoingStart());
        props.dispatch(fetchUpcommingLiveStreamingsStart());
        props.dispatch(fetchHomeFeaturedSellersStart());
    }, [])


    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
    };

    return (
        <>
            <div className="new-landing-wrapper">
                <section className="home-banner-sec">
                    <ThemeHeader />
                    <div className="banner-content">
                        <Container className="padding-bottom">
                            <Row className="align-items-center justify-content-center">
                                <Col md={12} xl={7} lg={7} className="second">
                                    <div className="banner-info-sec">
                                        <h2>{t("enter_world_livestream")}</h2>
                                        <p>
                                           {t("prepare_yourself_live_streaming")}
                                        </p>
                                    </div>
                                </Col>
                                {!props.featuredSellers.loading &&
                                    props.featuredSellers.data.users &&
                                    props.featuredSellers.data.users.length > 0 ?
                                    <Col md={12} lg={5} xl={5}>
                                        <Slider {...settings}>
                                            {props.featuredSellers.data.users.map((user, i) =>
                                                <div key={i}>
                                                    <Link to="#">
                                                        <div className="whatnot-livestreaming-card">
                                                            <div className="whatnot-livestreaming-img-sec">
                                                                <Image
                                                                    className="whatnot-livestreaming-img"
                                                                    src={user.cover} />
                                                            </div>
                                                            <div className="whatnot-livestreaming-info">
                                                                <div className="whatnot-live-user-card">
                                                                    <div className="whatnot-live-user-img-sec">
                                                                        <Image
                                                                            className="whatnot-livestreaming-icon"
                                                                            src={user.picture}
                                                                        />
                                                                    </div>
                                                                    <div className="whatnot-live-user-info">
                                                                        <h4>{user.name}</h4>
                                                                        <p>{user.about}</p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="whatnot-hash-tag">
                                                                    <Link to="#"> #FunkoPop shows</Link>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )}
                                        </Slider>
                                    </Col> : null
                                }
                            </Row>
                        </Container>
                    </div>
                </section>
                {props.categoryList && props.categoryList.loading ? (
                    'loading'
                ) : props.categoryList.data.categories && props.categoryList.data.categories.length > 0 ? (
                    <section className="sm-padding product-category-sec">
                        <Container>
                            <Row className="justify-content-md-center">
                                <Col md={9}>
                                    <div className="latest-section-title">
                                        <h3 className="txt-black">{t("categories_of_whatnot_clone")}</h3>
                                        <p>
                                           {t("the_whatnot_clone_note")}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="margin-top-lg">
                                <Col md={12}>
                                    <div className="product-category-box">
                                        {props.categoryList.data.categories.map((category, i) =>
                                            <Link to="#">
                                                <div className="product-category-card">
                                                    <div className="product-category-img-sec">
                                                        <Image
                                                            className="product-category-img"
                                                            src={category.picture}
                                                        />
                                                    </div>
                                                    <div className="product-category-info">
                                                        <h4>{category.name}</h4>
                                                        <p><strong>{category.total_users}</strong><span>{t("count")}</span></p>
                                                    </div>
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                ): ''}
                {props.upcommingLiveStreamings.loading && !props.upcommingLiveStreamings.error ?
                    <LandingStreamingLoader />
                    :
                    (props.upcommingLiveStreamings.data.live_streamings &&
                        props.upcommingLiveStreamings.data.live_streamings.length > 0 &&
                        <section className="category-based-live-streaming-sec sm-padding">
                            <Container>
                                <Row className="justify-content-md-center">
                                    <Col md={9}>
                                        <div className="latest-section-title">
                                            <h3 className="txt-black">{t("categories_based_live_streaming")}</h3>
                                            <p>
                                               {t("the_whatnot_clone_live_note")}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="show-all-sec">
                                            <Link
                                                to="#"
                                                className="show-all-btn">
                                               {t("show_all")}
                                                <small className="pl-2">
                                                    <i className="fa fa-long-arrow-alt-right"></i>
                                                </small>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="margin-top-lg">
                                    <Col md={12}>
                                        <div className="category-base-live-streaming-box">
                                            {props.upcommingLiveStreamings.data.live_streamings.slice(0, 4).map((stream, i) =>
                                                <Link to="#">
                                                    <div className="category-base-live-streaming-card">
                                                        <div className="category-base-live-streaming-img-sec">
                                                            <Image
                                                                className="category-base-live-streaming-img"
                                                                src={
                                                                    window.location.origin + "/assets/images/product/1.jpg"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="category-base-live-streaming-info">
                                                            <div className="whatnot-live-user-card">
                                                                <div className="whatnot-live-user-img-sec">
                                                                    <Image
                                                                        className="category-base-live-streaming-icon"
                                                                        src={stream.user_picture}
                                                                    />
                                                                </div>
                                                                <div className="whatnot-live-user-info">
                                                                    <h4>{stream.user_displayname}</h4>
                                                                    <p>{stream.description}</p>
                                                                </div>
                                                            </div>
                                                            {/* <div className="whatnot-hash-tag">
                                                            <Link to="#"> #FunkoPop shows</Link>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    )
                }
                <section className="sm-padding whatnot-featured-sec">
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md={12} xl={6} lg={6} className="resp-mrg-btn-xs">
                                <div className="latest-section-title text-left">
                                    <h3 className="txt-black">{t("whatnot_clone_featured")}</h3>
                                    <p>
                                       {t("dummy_text")}
                                    </p>
                                </div>
                                <div className="whatnot-featured-card">
                                    <div className="wahtnot-featured-icon-sec">
                                        <Image
                                            className="wahtnot-featured-icon"
                                            src={
                                                window.location.origin + "/assets/images/featured/featured-1.png"
                                            }
                                        />
                                    </div>
                                    <div className="whatnot-featured-info">
                                        <h4>Stream your heart out</h4>
                                        <p>{t("dummy_text")}</p>
                                    </div>
                                </div>
                                <div className="whatnot-featured-card">
                                    <div className="wahtnot-featured-icon-sec">
                                        <Image
                                            className="wahtnot-featured-icon"
                                            src={
                                                window.location.origin + "/assets/images/featured/featured-1.png"
                                            }
                                        />
                                    </div>
                                    <div className="whatnot-featured-info">
                                        <h4>Stream your heart out</h4>
                                        <p>{t("dummy_text")}</p>
                                    </div>
                                </div>
                                <div className="whatnot-featured-card">
                                    <div className="wahtnot-featured-icon-sec">
                                        <Image
                                            className="wahtnot-featured-icon"
                                            src={
                                                window.location.origin + "/assets/images/featured/featured-1.png"
                                            }
                                        />
                                    </div>
                                    <div className="whatnot-featured-info">
                                        <h4>Stream your heart out</h4>
                                        <p>{t("dummy_text")}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} xl={6} lg={6}>
                                <Image
                                    className="product-category-img-1"
                                    src={
                                        window.location.origin + "/assets/images/featured/featured-right-img.png"
                                    }
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
                {props.ongoingLiveStreamings.loading && !props.ongoingLiveStreamings.error ?
                    <LandingStreamingLoader />
                    : (props.ongoingLiveStreamings.data.live_streamings &&
                        props.ongoingLiveStreamings.data.live_streamings.length > 0 ?
                        <section className="ongoing-live-streaming-sec sm-padding">
                            <Container>
                                <Row className="justify-content-md-center">
                                    <Col md={9}>
                                        <div className="latest-section-title">
                                            <h3 className="txt-black">Live Streaming On Going</h3>
                                            <p>
                                                The Whatnot Clone is an efficient livestream shopping platform specifically designed and developed for collectors to list, buy, and sell rare collectibles like Pokémon cards, Funko Pops, vintage clothing, antiquities.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="show-all-sec">
                                            <Link
                                                to="#"
                                                className="show-all-btn"
                                            >
                                                Show All
                                                <small className="pl-2">
                                                    <i className="fa fa-long-arrow-alt-right"></i>
                                                </small>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="margin-top-lg">
                                    <Col md={12}>
                                        <div className="ongoing-live-streaming-box">
                                            {props.ongoingLiveStreamings.data.live_streamings.map((stream, i) =>
                                                <Link to="#">
                                                    <div className="ongoing-live-streaming-card">
                                                        <div className="ongoing-live-streaming-img-sec">
                                                            <Image
                                                                className="ongoing-live-streaming-img"
                                                                src={
                                                                    window.location.origin + "/assets/images/product/1.jpg"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="ongoing-live-streaming-info">
                                                            <div className="whatnot-live-user-card">
                                                                <div className="whatnot-live-user-img-sec">
                                                                    <Image
                                                                        className="ongoing-live-streaming-icon"
                                                                        src={stream.user_picture}
                                                                    />
                                                                </div>
                                                                <div className="whatnot-live-user-info">
                                                                    <h4>{stream.user_displayname}</h4>
                                                                    <p>{stream.description}</p>
                                                                </div>
                                                            </div>
                                                            {/* <div className="whatnot-hash-tag">
                                                            <Link to="#"> #FunkoPop shows</Link>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        : null
                    )
                }
                <section className="sm-padding whatnot-featured-sec">
                    <Container>
                        <Row className="justify-content-md-center align-items-center">
                            <Col md={6}>
                                <Image
                                    className="live-streaming-notify-img"
                                    src={
                                        window.location.origin + "/assets/images/featured/new-section.png"
                                    }
                                />
                            </Col>
                            <Col md={6}>
                                <div className="latest-section-title text-center">
                                    <h3 className="txt-black">Whatnot Clone Featured</h3>
                                    <p>
                                    {t("dummy_text")}
                                    </p>
                                    <div className="banner-btn-sec">
                                        <Link to="#" className="btn banner-theme-btn">
                                            Explore
                                        </Link>
                                        <Link
                                            to="#"
                                            className="btn banner-theme-outline-btn"
                                        >
                                            View More
                                            <small className="pl-2">
                                                <i className="fa fa-long-arrow-alt-right"></i>
                                            </small>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden bottom-footer-shape">
                        <svg viewBox="0 0 2880 48" fill="none" xmsns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    categoryList: state.lookUp.homeCategories,
    featuredSellers: state.lookUp.featuredSellers,
    upcommingLiveStreamings: state.liveVideo.upcommingLiveStreamings,
    ongoingLiveStreamings: state.liveVideo.liveStreamingsOngoing,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewLandingPage));