import React, { useEffect, useState } from "react";
import {
  NavDropdown,
  Nav,
  Navbar,
  Container,
  Card,
  Image,
} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { connect } from "react-redux";
import { fetchCategoriesListStart } from "../../store/actions/LookUpAction";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import "react-loading-skeleton/dist/skeleton.css";
import { t } from "react-multi-lang";

const SideMenu = (props) => {
  const [activeId, setActiveId] = useState(null);
  const { isActive, setActive } = props;
  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, []);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }
  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <div
      className={
        isActive
          ? "category-left-sec"
          : "category-left-sec category-left-sec-fixed"
      }
    >
      {/* <div className={isActive ? 'category-left-sec' : 'category-left-sec category-left-sec-fixed'} > */}

      {!props.categoriesList.loading &&
        props.categoriesList.data.categories &&
        props.categoriesList.data.categories.length > 0 ? (
        <>
          <h4 className="category-title">Categories</h4>
          <div className="category-accordion">
            <Accordion defaultActiveKey={activeId}>
              {props.categoriesList.data.categories.map((category, i) => (
                <Card className="rounded-lg mb-3 border-0" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${i}`}
                    onClick={() => toggleActive(`${i}`)}
                    className={activeId === `${i}` ? "active" : null}
                  >
                    {category.name}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${i}`}>
                    <Card.Body>
                      <ul>
                        <li key={i}>
                          <Link to={`/?category=${category.unique_id}`}
                            // onClick={e => {
                            //   e.preventDefault();
                            //   props.setSelectedCategory({
                            //     categoryId: category.unique_id,
                            //     subCategoryId: null,
                            //   });
                            // }}
                            className={`${props.selectedCategory.categoryId === category.unique_id ? "sidebar-link-active" : ""}`}
                          >
                            Shop all
                          </Link>
                        </li>
                        {category.sub_categories.map((subCategory, i) => (
                          <li key={i}>
                            <Link to={`/?subCategory=${subCategory.unique_id}`}
                            // onClick={e => {
                            //   e.preventDefault();
                            //   props.setSelectedCategory({
                            //     categoryId: null,
                            //     subCategoryId: subCategory.unique_id,
                            //   });
                            // }}
                            className={`${props.selectedCategory.subCategoryId === subCategory.unique_id ? "sidebar-link-active" : ""}`}
                            >
                              {subCategory.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </>
      ) : null
      }

      {configuration.get("configData.footer_pages1") &&
        <div className="side-footer-sec">
          <ul className="text-capitalize">
            {configuration.get("configData.footer_pages1").map((staticPage, i) =>
              <li>
                <a
                  className="hover-link"
                  href={`${window.location.origin}/page/${staticPage.static_page_unique_id}`}
                  target="_blank">
                  {staticPage.title}
                </a>
              </li>
            )}
          </ul>
        </div>
      }
      <div className="close-icon-sec" onClick={toggleClass}>
        <i class="fas fa-times"></i>
      </div>
    </div >
  );
};

const mapStateToPros = (state) => ({
  categoriesList: state.lookUp.categoriesList,
});
const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(mapStateToPros, mapDispatchToProps)(SideMenu);
