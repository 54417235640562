import {
    FETCH_HOME_FEATURED_SELLERS_FAILURE,
    FETCH_HOME_FEATURED_SELLERS_START,
    FETCH_HOME_FEATURED_SELLERS_SUCCESS,
    FETCH_CATEGORIES_START,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    FETCH_HOME_CATEGORIES_START,
    FETCH_HOME_CATEGORIES_SUCCESS,
    FETCH_HOME_CATEGORIES_FAILURE,
    FETCH_SUB_CATEGORIES_START,
    FETCH_SUB_CATEGORIES_SUCCESS,
    FETCH_SUB_CATEGORIES_FAILURE,
    FETCH_CATEGORIES_LIST_START,
    FETCH_CATEGORIES_LIST_SUCCESS,
    FETCH_CATEGORIES_LIST_FALURE,
    FOLLOW_CATEGORIES_START,
    FOLLOW_CATEGORIES_SUCCESS,
    FOLLOW_CATEGORIES_FAILURE,
} from "../actions/ActionConstant";


const initialState = {
    featuredSellers: {
        data: {},
        loading: true,
        error: false,
    },
    homeCategories: {
        data: {},
        loading: true,
        error: false,
    },
    categories: {
        data: {},
        loading: true,
        error: false,
    },
    subCategories: {
        data: {},
        loading: true,
        error: false,
    },
    categoriesList: {
        data: {},
        loading: true,
        error: false,
    },
    followCategories: {
        data: {},
        loading: true,
        error: false,
    }
}

const LookUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HOME_FEATURED_SELLERS_START:
            return {
                ...state,
                featuredSellers: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_HOME_FEATURED_SELLERS_SUCCESS:
            return {
                ...state,
                featuredSellers: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case FETCH_HOME_FEATURED_SELLERS_FAILURE:
            return {
                ...state,
                featuredSellers: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case FETCH_HOME_CATEGORIES_START:
            return {
                ...state,
                homeCategories: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case FETCH_HOME_CATEGORIES_SUCCESS:
            return {
                ...state,
                homeCategories: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case FETCH_HOME_CATEGORIES_FAILURE:
            return {
                ...state,
                homeCategories: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case FETCH_CATEGORIES_START:
            return {
                ...state,
                categories: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_CATEGORIES_FAILURE:
            return {
                ...state,
                categories: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_SUB_CATEGORIES_START:
            return {
                ...state,
                subCategories: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case FETCH_SUB_CATEGORIES_SUCCESS:
            return {
                ...state,
                subCategories: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case FETCH_SUB_CATEGORIES_FAILURE:
            return {
                ...state,
                subCategories: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case FETCH_CATEGORIES_LIST_START:
            return {
                ...state,
                categoriesList: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case FETCH_CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                categoriesList: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case FETCH_CATEGORIES_LIST_FALURE:
            return {
                ...state,
                categoriesList: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case FOLLOW_CATEGORIES_START:
            return {
                ...state,
                followCategories: {
                    data: {},
                    loading: true,
                    error: false,
                }
            };
        case FOLLOW_CATEGORIES_SUCCESS:
            return {
                ...state,
                followCategories: {
                    data: action.data,
                    loading: false,
                    error: false,
                }
            };
        case FOLLOW_CATEGORIES_FAILURE:
            return {
                ...state,
                followCategories: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        default:
            return state;
    }
}


export default LookUpReducer;