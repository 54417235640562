import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    CANCEL_ORDER_START,
    CREATE_ORDER_BY_STRIPE_START,
    CREATE_ORDER_BY_WALLET_START,
    FETCH_ORDER_DETAILS_START,
    FETCH_ORDER_LIST_START,
    ORDER_LIST_FOR_SELLER_START,
    UPDATE_ORDER_STATUS_START,
    FETCH_ORDER_PAYMENT_LIST_START,
    ORDERS_VIEW_FOR_OTHERS_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import config from "react-global-configuration";
import io from "socket.io-client";
import { checkLogoutStatus } from "../actions/ErrorAction";

import {
    fetchOrderListSuccess,
    fetchOrderListFailure,
    orderListForSellerSuccess,
    orderListForSellerFailure,
    fetchOrderDetailsSuccess,
    fetchOrderDetailsFailure,
    createOrderByWalletSuccess,
    createOrderByWalletFailure,
    createOrderByStripeSuccess,
    createOrderByStripeFailure,
    updateOrderStatusSuccess,
    updateOrderStatusFailure,
    cancelOrderSuccess,
    cancelOrderFailure,
    fetchOrderPaymentListSuccess,
    fetchOrderPaymentListFailure,
    ordersViewForOthersSuccess,
    ordersViewForOthersFailure,
    orderListForSellerStart,
} from "../actions/OrderAction";

import {
    fetchLiveVideosProductsStart
} from "../actions/LiveVideoAction";

function* fetchOrderListAPI() {
    try {
        const response = yield api.postMethod("orders_list");
        if (response.data.success) {
            yield put(fetchOrderListSuccess(response.data.data));
        } else {
            yield put(fetchOrderListSuccess(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchOrderListFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* orderListForSellerAPI() {
    try {
        const response = yield api.postMethod("orders_list_for_seller");
        if (response.data.success) {
            yield put(orderListForSellerSuccess(response.data.data));
        } else {
            yield put(orderListForSellerFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(orderListForSellerFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchOrderDetailsAPI(action) {
    try {
        const response = yield api.postMethod("orders_view", action.data);
        if (response.data.success) {
            yield put(fetchOrderDetailsSuccess(response.data.data));
        } else {
            yield put(fetchOrderDetailsFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchOrderDetailsFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* createOrderByWalletAPI(action) {
    try {
        const response = yield api.postMethod("orders_create_by_wallet", action.data);
        if (response.data.success) {
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
            yield put(createOrderByWalletSuccess(response.data.data));
            yield put(fetchLiveVideosProductsStart({
                live_video_id: action.data.live_video_id,
            }));
            let chatSocket;
            let chatSocketUrl = config.get("configData.chat_socket_url");
            chatSocket = io(chatSocketUrl, {
                query: `room: '`+response.data.data.live_video.virtual_id+`'`,
            });

            chatSocket.emit("livevideo buynow", response.data.data.live_video.virtual_id);

        } else {
            yield put(createOrderByWalletFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(createOrderByWalletFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* createOrderByStripeAPI(action) {
    try {
        const response = yield api.postMethod("orders_create_by_stripe", action.data);
        if (response.data.success) {
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
            yield put(createOrderByStripeSuccess(response.data.data));
            yield put(fetchLiveVideosProductsStart({
                live_video_id: action.data.live_video_id,
            }));
            let chatSocket;
            let chatSocketUrl = config.get("configData.chat_socket_url");
            chatSocket = io(chatSocketUrl, {
                query: `room: '`+response.data.data.live_video.virtual_id+`'`,
            });

            chatSocket.emit("livevideo buynow", response.data.data.live_video.virtual_id);
        } else {
            yield put(createOrderByStripeFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(createOrderByStripeFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* updateOrderStatusAPI(action) {
    try {
        const response = yield api.postMethod("orders_status_update", action.data);
        if (response.data.success) {
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
            yield put(updateOrderStatusSuccess(response.data.data));
            yield put(orderListForSellerStart());
        } else {
            yield put(updateOrderStatusFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(updateOrderStatusFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* cancelOrderAPI(action) {
    try {
        const response = yield api.postMethod("orders_cancel", action.data);
        if (response.data.success) {
            const notificationMessage = getSuccessNotificationMessage(
                response.data.message
            );
            yield put(createNotification(notificationMessage));
            yield put(cancelOrderSuccess(response.data.data));
            window.location.reload();
        } else {
            yield put(cancelOrderFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(cancelOrderFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* fetchOrderPaymentListAPI() {
    try {
        const response = yield api.postMethod("order_payments_list");
        if (response.data.success) {
            yield put(fetchOrderPaymentListSuccess(response.data.data));
        } else {
            yield put(fetchOrderPaymentListFailure(response.data.error));
            const notificationMessage = getErrorNotificationMessage(
                response.data.error
            );
            yield put(createNotification(notificationMessage));
        }
    } catch (error) {
        yield put(fetchOrderPaymentListFailure(error));
        const notificationMessage = getErrorNotificationMessage(error.message);
        yield put(createNotification(notificationMessage));
    }
}

function* ordersViewForOthersAPI(action) {
    try {
      const response = yield api.postMethod("orders_view_for_others" , action.data);
  
      if (response.data.success) {
        yield put(ordersViewForOthersSuccess(response.data.data));
      } else {
        yield put(ordersViewForOthersFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
      }
    } catch (error) {
      yield put(ordersViewForOthersFailure(error));
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
    }
}

export default function* pageSaga() {
    yield all([
        yield takeLatest(FETCH_ORDER_LIST_START, fetchOrderListAPI),
        yield takeLatest(ORDER_LIST_FOR_SELLER_START, orderListForSellerAPI),
        yield takeLatest(FETCH_ORDER_DETAILS_START, fetchOrderDetailsAPI),
        yield takeLatest(CREATE_ORDER_BY_WALLET_START, createOrderByWalletAPI),
        yield takeLatest(CREATE_ORDER_BY_STRIPE_START, createOrderByStripeAPI),
        yield takeLatest(UPDATE_ORDER_STATUS_START, updateOrderStatusAPI),
        yield takeLatest(CANCEL_ORDER_START, cancelOrderAPI),
        yield takeLatest(FETCH_ORDER_PAYMENT_LIST_START, fetchOrderPaymentListAPI),
        yield takeLatest(ORDERS_VIEW_FOR_OTHERS_START, ordersViewForOthersAPI),
    ]);
}