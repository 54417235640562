import React, { useState, useEffect } from "react";
import {
  NavDropdown,
  Nav,
  Navbar,
  Container,
  Card,
  Image,
} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useParams, useHistory } from "react-router-dom";
import {
  fetchCategoryVideosStart,
  fetchSubCategoryVideosStart,
} from "../../store/actions/HomeAction";
import {
  fetchCategoriesListStart,
  followCategoriesStart,
  fetchSubCategoriesStart,
} from "../../store/actions/LookUpAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import SingleVideoCard from "./SingleVideoCard";
import { t } from "react-multi-lang";

const CategoryCard = (props) => {
  const [activeId, setActiveId] = useState(null);
  const [videos, setVideos] = useState({ loading: true });
  const [followStatus, setFollowStatus] = useState(false);

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, []);

  useEffect(() => {
    if (params.categoryId) {
      if (params.categoryId === "sub") {
        if (params.subcategoryId) {
          //Sub Category API Call
          props.dispatch(
            fetchSubCategoryVideosStart({
              sub_category_id: params.subcategoryId,
            })
          );
        } else {
          history.push("/");
        }
      } else {
        // Category API Call
        props.dispatch(
          fetchCategoryVideosStart({ category_id: params.categoryId })
        );
        props.dispatch(
          fetchSubCategoriesStart({ category_unique_id: params.categoryId })
        );
      }
    } else {
      history.push("/");
    }
  }, [params.categoryId, params.subcategoryId]);

  useEffect(() => {
    if (params.categoryId) {
      if (params.categoryId === "sub") {
        setVideos(props.subCategoryVideos);
        setFollowStatus(props.subCategoryVideos.data.is_following);
      } else {
        setVideos(props.categoryVideos);
        setFollowStatus(props.categoryVideos.data.is_following);
      }
    }
  }, [props.categoryVideos, props.subCategoryVideos]);

  useEffect(() => {
    if (!props.followCategories.loading && !props.followCategories.error) {
      setFollowStatus(!followStatus);
    }
  }, [props.followCategories]);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const followUnfollow = (e) => {
    e.preventDefault();
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      if (params.categoryId === "sub") {
        props.dispatch(
          followCategoriesStart({ sub_category_id: params.subcategoryId })
        );
      } else {
        props.dispatch(
          followCategoriesStart({ category_id: params.categoryId })
        );
      }
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Please Login to Follow"
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  return (
    <div>
      <div className="new-category-sec">
        <SideMenu></SideMenu>

        <div className="category-right-sec">
          <div className="mobile-nav-filter-sec">
            <a href="#" onClick={toggleClass}>
              Categories
            </a>
          </div>
          <div className="category-card-head">
            {!videos.loading ? (
              <>
                <h2>{videos.data.name}</h2>
                <a
                  href="#"
                  className={`${followStatus ? "active" : ""}`}
                  onClick={(e) => followUnfollow(e)}
                >
                  {followStatus ? "Following" : "Follow"}
                </a>
              </>
            ) : null}
          </div>
          {params.categoryId !== "sub" &&
            !props.subCategories.loading &&
            props.subCategories.data.sub_categories &&
            props.subCategories.data.sub_categories.length > 0 && (
              <div className="new-card-head pt-8">
                <h3>{t("sub_categories")}</h3>
                <div className="category-tab-card">
                  <ul>
                    {props.subCategories.data.sub_categories.map(
                      (subCategory, i) => (
                        <li key={i} className="category-thumb">
                          <Link to={`/category/sub/${subCategory.unique_id}`}>
                            {subCategory.name}
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            )}
          {!videos.loading &&
            videos.data.featured_videos &&
            videos.data.live_videos && (
              <>
                {videos.data.featured_videos.length > 0 && (
                  <div className="new-category-card-sec">
                    <div className="new-card-title">
                      <h4 className="mtb-1">{t("featured_videos")}</h4>
                    </div>
                    <div className="category-total-card">
                      {videos.data.featured_videos.map((video, i) => (
                        <SingleVideoCard live={video} key={i} />
                      ))}
                    </div>
                  </div>
                )}
                {videos.data.live_videos.length > 0 && (
                  <div className="new-category-card-sec">
                    <div className="new-card-title">
                      <h4 className="mtb-1">
                        {videos.data.live_videos.length} {t("shows")}
                      </h4>
                    </div>
                    <div className="category-total-card">
                      {videos.data.live_videos.map((video, i) => (
                        <SingleVideoCard live={video} key={i} />
                      ))}
                    </div>
                  </div>
                )}
                {videos.data.featured_videos.length === 0 &&
                  videos.data.live_videos.length === 0 && (
                    <div className="text-center pt-8 h4">
                      {t("no_data_found")}
                    </div>
                  )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  categoriesList: state.lookUp.categoriesList,
  categoryVideos: state.home.categoryVideos,
  subCategoryVideos: state.home.subCategoryVideos,
  followCategories: state.lookUp.followCategories,
  subCategories: state.lookUp.subCategories,
});

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(mapStateToPros, mapDispatchToProps)(CategoryCard);
