import React, { useEffect } from "react";
import api from "../../Environment";

const Logout = (props) => {
  useEffect(() => {
    api.postMethod("logout").then((response) => {
      if (response.data.success) {
        console.log("success");
      } else {
      }
    });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userLoginStatus");
    localStorage.removeItem("user_picture");
    localStorage.removeItem("username");
    localStorage.removeItem("socket");
    localStorage.removeItem("user_cover");
    localStorage.removeItem("name");
    localStorage.removeItem("is_document_verified");
    localStorage.removeItem("is_verified_badge");
    localStorage.removeItem("total_followers");
    localStorage.removeItem("total_followings");
    localStorage.removeItem("user_unique_id");
    localStorage.removeItem("is_subscription_enabled");
    localStorage.removeItem("is_content_creator");
    localStorage.removeItem("default_payment_method");
    props.history.push("/");
  }, []);
  return "";
};

export default Logout;
