import React, { useState } from "react";
import { Dropdown, Image, Media, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { saveBookmarkStart } from "../../store/actions/BookmarkAction";
import { translate, t } from "react-multi-lang";
import PaymentModal from "./PaymentModal";
import configuration from "react-global-configuration";

const ListDataCard = (props) => {
    const { video } = props;
    const [paymentModal, setPaymentModal] = useState(false);
    const openPaymentModal = (event) => {
        event.preventDefault();
        setPaymentModal(true);
    };

    const closePaymentModal = () => {
        setPaymentModal(false);
    };

    return (
        <>
            <div className="video-list-card">
                <Link
                    to={`/live/${video.live_video_unique_id}`}
                    className="btn"
                    style={{ marginBottom: "1rem" }}
                >
                    <div className="video-list-img-sec">
                        <Image
                            // src={configuration.get("configData.live_streaming_placeholder_img")}
                            src={video.preview_file}
                            className="video-list-img"
                        />
                    </div>
                </Link>
                <div className="video-list-user-details">
                    <div className="user-img-sec">
                        <Image src={video.user_picture} className="user-img" />
                    </div>
                    <div className="video-list-user-info">
                        <div className="video-list-user-card">
                            <div className="video-list-data-type">
                                <h4 className="title">{video.title}</h4>
                                <h4 className="username">
                                    @{video.user_displayname}
                                </h4>
                                <h4 className="description">
                                    {video.description}
                                </h4>
                                <h4 className="date">
                                    {video.formatted_schedule_time}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

const mapStateToPros = (state) => ({
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(ListDataCard));
