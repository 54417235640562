import React from 'react';
import { Row, Col, Image, Container, Media } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const LandingStreamingLoader = (props) => {

    return (
        <section className="category-based-live-streaming-sec sm-padding">
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={9}>
                        <div className="latest-section-title">
                            <h3 className="txt-black"><Skeleton /></h3>
                            <p><Skeleton count={2} /></p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="show-all-sec">
                            <Skeleton className="show-all-btn btn-skeliton" />
                        </div>
                    </Col>
                </Row>
                <Row className="margin-top-lg">
                    <Col md={12}>
                        <div className="category-base-live-streaming-box">
                            {[...Array(4)].map((e, i) =>
                                <div>
                                    <div className="category-base-live-streaming-card">
                                        <div className="category-base-live-streaming-img-sec no-banner">
                                            <Skeleton className="category-base-live-streaming-img" />
                                        </div>
                                        <div className="category-base-live-streaming-info">
                                            <div className="whatnot-live-user-card">
                                                <Col md={3}>
                                                    <div className="whatnot-live-user-img-sec">
                                                        <Skeleton className="category-base-live-streaming-icon" />
                                                    </div>
                                                </Col>
                                                <Col md={9}>
                                                    <Skeleton count={2} />
                                                </Col>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default LandingStreamingLoader;