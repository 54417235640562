import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import "./Ecom.css";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

const EcomPaymentMethod = (props) => {

    return (
        <>
            <div className="ecom-payment-method-sec">
                <Container>
                    <h2>{t("ecom_payment_method")}</h2>
                    <Form className="add-product-form">
                        <Row>
                            <Col md={6}>
                                <div className="border-right-divider add-product-form-sec">
                                    <Row className="payment-method">
                                        <Col md={6} className="resp-mrg-btn-xs">
                                            <div className="radiobtn payment-method-card-1">
                                                <Form.Control type="radio" id="inline-radio-1" className="form-check-input" checked="checked" />
                                                <label type="radio" for="inline-radio-1" className="form-check-label card-label">
                                                    <p className="no-margin"><span className="card-option">{t("card")}</span></p>
                                                </label>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="radiobtn payment-method-card-1">
                                                <Form.Control type="radio" id="inline-radio-2" className="form-check-input" />
                                                <label type="radio" for="inline-radio-2" className="form-check-label">{t("paypal")}</label>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mt-4">
                                            <div className="radiobtn payment-method-card-1">
                                                <Form.Control type="radio" id="inline-radio-3" className="form-check-input" />
                                                <label type="radio" for="inline-radio-3" className="form-check-label">{t("wallet")}</label>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="radiobtn mb-4 payment-method-card">
                                                <div>
                                                    <h3 className="payment-head-tit">{t("beno_darry")}</h3>
                                                    <p className="desc">XXXX XXXX XXXX X567</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="ecom-payment-product-details-sec mt-4">
                                    <div className="product-details-header-sec">
                                        <div className="product-details-header-card">
                                            <div className="product-details-info">
                                                <Image
                                                    className="product-thumbnail-img"
                                                    src={
                                                        window.location.origin + "/assets/images/ecom/ecom-1.jpg"
                                                    }
                                                />
                                                <h6>{t("light_brown_shoes")}</h6>
                                            </div>
                                            <div className="ecom-payment-product-amount">
                                                <p>$49.00</p>
                                            </div>
                                        </div>
                                        <div className="product-details-header-card">
                                            <div className="product-details-info">
                                                <Image
                                                    className="product-thumbnail-img"
                                                    src={
                                                        window.location.origin + "/assets/images/ecom/ecom-1.jpg"
                                                    }
                                                />
                                                <h6>{t("light_brown_shoes")}</h6>
                                            </div>
                                            <div className="ecom-payment-product-amount">
                                                <p>$49.00</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-details-body-sec">
                                        <div className="product-details-card">
                                            <h5>{t("subtotal")}</h5>
                                            <p className="product-amount">$49.00</p>
                                        </div>
                                        <div className="product-details-card">
                                            <h5>{t("shipping")}</h5>
                                            <p>{t("calculated_at_next_step")}</p>
                                        </div>
                                    </div>
                                    <div className="product-details-footer-sec">
                                        <h5>{t("total")}</h5>
                                        <div className="product-details-final-amount">
                                            <span>{t("USD")}</span>
                                            <p>$49.00</p>
                                        </div>
                                    </div>
                                    <div className="ecom-payment-method-btn-sec">
                                        <Link to="#" className="continue-shipping-btn">{t("pay_now")}</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </>
    );
};

export default (translate(EcomPaymentMethod));
