import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Table, Badge } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { fetchLiveVideosHistoryStart, fetchMoreLiveVideosHistoryStart } from "../../store/actions/LiveVideoAction";
import { Link } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import BillingAccountLoader from "../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";
import GoLiveModal from "../helper/GoLiveModal";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import InfiniteScroll from "react-infinite-scroll-component";
const LiveVideosIndex = (props) => {
  const [goLive, setGoLive] = useState(false);

  useEffect(() => {
    if (props.userDetails.loading) props.dispatch(fetchUserDetailsStart());
  //  props.dispatch(fetchLiveVideosHistoryStart());
  }, []);


  useEffect(() => {
    props.dispatch(
      fetchLiveVideosHistoryStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreLiveVideosHistoryStart({
        skip: props.liveVideos.data.live_videos.length,
        take: 12,
      })
    );
  };


  const closeGoLiveModal = () => {
    setGoLive(false);
  };

  return (
    <>
      <div className="wallet-sec live-video-list-header-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="wallet-header-sec">
                <Row className="align-items-center">
                  <Col sm={12} md={12} xl={8}>
                    <h3>{t("live_history")}</h3>
                  </Col>
                  <Col sm={12} md={12} xl={4} className="text-right">
                    <div>
                      <Link className="live-history-btn mr-2" to="/go-live">
                        {t("go_live")}
                      </Link>
                      <Link className="live-history-btn mr-2" to="/live-videos">
                        {t("scheduled_live_videos")}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="trans-table-sec">
        <Container>
          {props.liveVideos.loading ? (
            <BillingAccountLoader />
          ) : props.liveVideos.data.live_videos.length > 0 ? (
            <Row>
              <Col sm={12} md={12}>
                <div className="trans-table">
                <InfiniteScroll
                  dataLength={props.liveVideos.data.live_videos.length}
                  next={fetchMoreData}
                  hasMore={
                    props.liveVideos.data.live_videos.length <
                      props.liveVideos.data.total &&
                    props.liveVideos.errorCount < 2
                  }
                  loader={<h4>Loading...</h4>}
                >

                  <Table borderedless responsive>
                    <thead>
                      <tr className="bg-white text-muted text-center">
                        <th>{t("title")}</th>
                        <th>{t("username")}</th>
                        <th>{t("streamed_date")}</th>
                        <th>{t("view_count")}</th>
                        <th>{t("revenue")}</th>
                        <th>{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.liveVideos.data.live_videos.map(
                        (video) => (
                          <tr key={video.user_billing_account_id}>
                            <td>{video.title}</td>
                            <td>
                              {video.user_displayname}
                            </td>
                            <td>
                              {video.created_at_formatted}
                            </td>
                            <td>
                              {video.viewer_cnt}
                            </td>
                            <td>
                              {video.user_amount_formatted}
                            </td>
                            <td>
                              
                                <Button
                                  className="order-view-btn"
                                  type="button"
                                  href={"/live-orders/" + video.live_video_id}
                                  style={{ marginBottom: "1rem" }}
                                >
                                  {t("orders")}
                                </Button>
                              
                            </td>
                          
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
          ) : (
            <NoDataFound />
          )}
        </Container>
        {props.userDetails.loading ? (
          t("loading")
        ) : (
          <GoLiveModal
            goLive={goLive}
            closeGoLiveModal={closeGoLiveModal}
            username={props.userDetails.data.username}
            userPicture={props.userDetails.data.picture}
            name={props.userDetails.data.name}
            user_id={props.userDetails.data.user_id}
          />
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideos: state.liveVideo.liveVideosHistory,
  userDetails: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LiveVideosIndex));
