import React, { Component } from "react";
import { Notify } from "react-redux-notify";
import HeaderIndex from "./Header/HeaderIndex";

class NewLandingLayout extends Component {
  state = {};
  render() {
    return (
      <body>
        <Notify position="TopRight" />
        <div className="videocall-wrapper">
          <HeaderIndex />
          {React.cloneElement(this.props.children)}
        </div>
      </body>
    );
  }
}

export default NewLandingLayout;